import { Button, Card, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

import Page from "../components/Page";

export const AccountNotFound = () => {

    const navigate = useNavigate();

    return (
        <Page title='Not Found'>
            <Grid container justifyContent={'center'} alignItems={'center'} padding={'10px'} sx={{width:'100%'}}>
                <Card className={'glass-box'} container justifyContent={'center'}  alignItems={'center'} sx={{width:'100%', maxWidth:'500px', minHeight:'300px',marginTop:4, padding:2}}>
                    <Typography variant="h4" paddingY={4}>You do not have a registered account.</Typography>
                    <Typography variant="h6" paddingY={4}>Please contact admin or reach us at support@the-bytes.com</Typography>
                    <Button variant={'contained'} onClick={() => {navigate('/login')}} >
                        <Typography variant="body1" sx={{color:'#fff'}}>Go Back</Typography>
                    </Button>
                </Card>
            </Grid>
        </Page>
    );
}