import { Navigate, useLocation, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout'; 
import Blog from './pages/Blog';
import User from './pages/User';
import Login from './pages/Login';
import NotFound from './pages/Page404';
import Register from './pages/Register';
import Products from './pages/Products';
import DashboardApp from './pages/DashboardApp';
import Profile from './pages/Profile'; 
import Menu from './pages/Menu';
import Payment from './pages/Payment';
import OrderDetails from './pages/OrderDetails';
import MyCorporateOrders from './pages/MyCorporateOrders';
import MyCorporateMeals from './pages/MyCorporateMeals';
import MyCalendar from './pages/MyCalendar';
import MyPlan from './pages/MyPlan';
import Nutritionist from './pages/Nutritionist';
import Workout from './pages/Workout';
import Support from './pages/Support';
import Settings from './pages/Settings';
import Receipt from './pages/Receipt';
import MyCorporateMealDetail from './pages/MyCorporateMealDetail';
import Cart from './pages/Cart';
import NoLogoLayout from './layouts/NoLogoLayout';
import AuthLayout from './layouts/auth/AuthLayout';
import TermsAndConditions from './pages/TermsAndConditions';
import Layout1 from './layouts/Layout1';
import QuickCheckout from './pages/QuickCheckout';
import {Feedback} from './pages/Feedback';
import { AccountNotFound } from './pages/AccountNotFound';
import MyAccount from './pages/MyAccount';
import OrderSummary from './pages/OrderSummary';

// ----------------------------------------------------------------------

export default function Router() {

  // const location = useLocation();

  // return <Routes location={location} key={location.pathname}>
  //   <Route index element={<Login />} />
  //   <Route path="menu" element={<Menu />} />
  //   <Route path="cart" element={<Cart />} />
  // </Routes>;

  return useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { path: ' ', element: <DashboardApp /> },
        { path: 'app', element: <DashboardApp /> },
        { path: 'client', element: <User /> },
        { path: 'products', element: <Products /> },
        { path: 'blog', element: <Blog /> },
      ],
    },
    {
      path: '/menu',
      element: <Layout1 showHeader  showFooter={false} />,
      children: [
        { path: '', element: <Menu /> }, 
      ],
    }, 
    {
      path: '/cart',
      element: <Layout1 showHeader showFooter={false} />,
      children: [
        { path: '', element: <Cart /> }, 
      ],
    }, 
    {
      path: '/payment',
      element: <LogoOnlyLayout />,
      children: [
        { path: '', element: <Payment /> }, 
      ],
    },
    {
      path: '/order/:order_id',
      element: <DashboardLayout />,
      children: [
        { path: '', element: <OrderDetails /> }, 
        // { path: 'receipt/:order_id', element: <Receipt /> }, 
      ],
    },
    {
      path: '/order/receipt/:order_id',
      element: <Layout1 showHeader showFooter={false} />,
      children: [
        { path: '', element: <Receipt /> }, 
      ],
    },
    {
      path: '/order/summary/:order_id',
      element: <Layout1 showHeader showFooter={false}/>,
      children: [
        { path: '', element: <OrderSummary /> }, 
      ],
    },
    {
      path: '/my-orders',
      element: <DashboardLayout />,
      children: [
        { path: '', element: <MyCorporateOrders /> }, 
      ],
    },
    {
      path: '/my-meals',
      element: <Layout1 />,
      children: [
        { path: '', element: <MyCorporateMeals /> }, 
      ],
    },
    {
      path: '/meal/detail',
      element: <DashboardLayout />,
      children: [
        { path: '', element: <MyCorporateMealDetail /> }, 
      ],
    },
    {
      path: '/my-calendar',
      element: <DashboardLayout />,
      children: [
        { path: '', element: <MyCalendar /> }, 
      ],
    }, 
    {
      path: '/my-plan',
      element: <DashboardLayout />,
      children: [
        { path: '', element: <MyPlan /> }, 
      ],
    }, 
    {
      path: '/nutritionist',
      element: <DashboardLayout />,
      children: [
        { path: '', element: <Nutritionist /> }, 
      ],
    }, 
    {
      path: '/workout',
      element: <DashboardLayout />,
      children: [
        { path: '', element: <Workout /> }, 
      ],
    }, 
    {
      path: '/support',
      element: <Layout1 />,
      children: [
        { path: '', element: <Support /> }, 
      ],
    }, 
    {
      path: '/quickcheckout',
      element: <Layout1 />,
      children: [
        { path: '', element: <QuickCheckout /> }, 
      ],
    }, 
    {
      path: '/settings',
      element: <DashboardLayout />,
      children: [
        { path: '', element: <Settings /> }, 
      ],
    }, 
    {
      path: '/',
      element: <AuthLayout />,
      children: [
        { path: '/', element: <Navigate to="/login" /> },
        { path: 'login', element: <Login /> },
        { path: 'register', element: <Register /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '/profile',
      element: <Layout1 />,
      children: [
        { path: '', element: <Profile /> }, 
      ],
    }, 
    {
      path: '/terms-and-conditions',
      element: <LogoOnlyLayout />,
      children: [
        { path: '', element: <TermsAndConditions /> }, 
      ],
    },
    {
      path: '/feedback',
      element: <Layout1 />,
      children: [
        { path: '', element: <Feedback /> }, 
      ],
    }, 
    {
      path: '/my-account',
      element: <Layout1 showHeader showFooter={false}/>,
      children: [
        { path: '', element: <MyAccount /> }, 
      ],
    }, 
    {
      path: '/account-not-found',
      element : <LogoOnlyLayout />,
      children: [
        { path: '', element: <AccountNotFound />}
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
