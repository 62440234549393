import { createSlice } from '@reduxjs/toolkit'
import { USER_PAYMENT_TYPE } from '../../constants/app_constants';

const userSlice = createSlice({
  name: 'user',
  initialState: {
    accessToken: null,
    session: null,
    isLoggedIn: false,
    userDetails: null,
    userId:null,
    userName: null,
    activeCorporateClient: null,
    corporateOrderList: [],
    corporateMealList: []
  },
  reducers: {
    setUser(state,action) {
      state.userDetails = action.payload.data; 
      state.userId = action.payload.data.id;
    },
    setUserId(state,action) {
      state.userId = action.payload.data; 
    },
    setUserName(state,action) {
      state.userName = action.payload.data; 
    },
    setAccessToken(state, action) {
      state.accessToken = action.payload.data;
    },
    setSessionDetails(state, action) {
      state.session = action.payload.data;
    },
    setLoginStatus(state,action) {
      state.isLoggedIn = action.payload.data; 
    },
    setUserCorporateOrderList(state,action) {
      state.corporateOrderList = action.payload.data; 
    },
    setUserCorporateMeal(state,action) {
      state.corporateMealList = action.payload.data; 
    },
    removeCorporateNpsItem(state, action) {
      try{
        const data = action.payload.data;
        const corporateNpsList = [];
        if(state.userDetails && state.userDetails !== null && state.userDetails.corporate_nps && state.userDetails.corporate_nps !== null){
          
          // return state.user.userDetails.corporate_nps;
          state.userDetails.corporate_nps.forEach(element => {
            if(element.order_id === data.order_id && element.order_item_id === data.order_item_id){
              // skip
            }else{
              corporateNpsList.push(element);
            }        
          });
          state.userDetails.corporate_nps = [];
          state.userDetails.corporate_nps = corporateNpsList;
        }
        
      }catch(e){
        console.log(e);
      }
    },
    logoutUser(state, action){
      state.accessToken = "";
      state.session = "";
      state.isLoggedIn = false;
      state.userId = "";
      state.userName = "";
      state.userDetails = {};
      state.corporateMealList = [];
    },
    setActiveCorporateClient(state, action){
      state.activeCorporateClient = action.payload.client; 
    }
  }
})

export const getAccessToken = (state) => {
  if(state.user != null && state.user !== undefined){
    return state.user.accessToken;
  }
  return null;
}

export const getSessionDetails = (state) => {

  if(state.user != null && state.user !== undefined){
    return state.user.session;
  }
  return null;
}

export const getUser = (state) => {
  if(state.user != null && state.user !== undefined){
    return state.user.userDetails;
  }
  return null;
}

export const getUserId = (state) => {
  if(state.user != null && state.user !== undefined){
    return state.user.userId;
  }
  return null;
}

export const getUserName = (state) => {
  if(state.user != null && state.user !== undefined &&  state.user.userDetails !== null){
    return state.user.userDetails.user_name;
  }
  return null;
}

export const getLoginStatus = (state) => {

  if(state.user != null && state.user !== undefined){
      return state.user.isLoggedIn;
    // return (state.user.session != null && state.user.session!==undefined);
  }
  return false;
}

export const getActiveCorporateClient = (state) => {

  if(state.user != null && state.user !== undefined){ 
      return state.user.activeCorporateClient;
    // return (state.user.session != null && state.user.session!==undefined);
  }
  return false;
}

export const getUserCorporateOrderList = (state) => {

  if(state.user != null && state.user !== undefined){
      return state.user.corporateOrderList;
    // return (state.user.session != null && state.user.session!==undefined);
  }
  return [ ];
}

export const getUserCorporateMeal = (state) => {

  if(state.user != null && state.user !== undefined){
      return state.user.corporateMealList;
    // return (state.user.session != null && state.user.session!==undefined);
  }
  return [];
}

export const getUserCorporatePaymentType = (state) => {
  try{
    const corporates = state.user.userDetails.corporates;
    for(let i=0; i<corporates.length; i+=1) {
      if (corporates[i].corporate_client_id === state.otp.selectedCorporate.id){
        return corporates[i].payment_type;
      }
    }
  }catch(e){
    //
  }
  return USER_PAYMENT_TYPE.USER_PAID;
}

export const getUserCorporateNps = (state) => {
  try{
    if(state.user && state.user != null && state.user.userDetails && state.user.userDetails !== null && state.user.userDetails.corporate_nps && state.user.userDetails.corporate_nps !== null){
      if (state.user.userDetails.corporate_nps.length > 0){
        console.log(state.user.userDetails.corporate_nps.length);
        return state.user.userDetails.corporate_nps;
      }
      return [];
    }
  }catch(e){
    console.log(e);
  }
  return [];
}

export const { setAccessToken, setSessionDetails, setLoginStatus, 
  setUser, setUserName, setUserId, logoutUser,setActiveCorporateClient,
  setUserCorporateOrderList, setUserMealHistory, setUserCorporateMeal, removeCorporateNpsItem
} = userSlice.actions
export default userSlice.reducer