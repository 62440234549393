import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useCookies } from 'react-cookie';
// material
import { Grid, Button, Container, Stack, Typography, Card, Divider, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { BrowserView, MobileView } from 'react-device-detect';

import QRCode from "react-qr-code";

// components
import Page from '../components/Page';
import Iconify from '../components/Iconify';  
import { fetchUserCorporateMeal  } from '../data/network/user/api';
import { fetchCorporateOrderDetails  } from '../data/network/order/api';
import { getActiveOrderDetails } from '../store/order/reducer';
import { getLoadingStatus } from '../store/layout/reducer';
import { getLoginStatus,getUserCorporateMeal } from '../store/user/reducer';
import {formatDate} from '../utils/date';
import OrderSummaryHeaderMobile from '../components/header/OrderSummaryHeaderMobile';
 // ----------------------------------------------------------------------

export default function Receipt() {
    const theme = useTheme();
    const dispatch = useDispatch(); 
    const navigate = useNavigate(); 
    const [cookies, setCookie, removeCookie] = useCookies(['mobile','session', 'access_token']);
    const orderDetails = useSelector(state => getActiveOrderDetails(state) );
    const [QRUrl, setQRUrl] = useState(null);
    const isLoading = useSelector(state => getLoadingStatus(state) );
    const isLoggedIn = useSelector(state => getLoginStatus(state));
    const corporateMealList = useSelector(state => getUserCorporateMeal(state) );

    const [grandTotal, setGrandTotal] = useState(0);

    useEffect(() => { 
      if(!isLoggedIn){ 
        navigate('/');
      }
    },[isLoggedIn])
  

    useEffect(() => {
        const loc = window.location.href;
        const org = loc.split('/');
        const orderId = org[5]; 
        fetchCorporateOrderDetails(dispatch, cookies, orderId );
        // fetchUserCorporateMeal(dispatch, cookies, {
        //     order_id: orderId
        // });
    },[])

    useEffect(() => {
        if(orderDetails != null){
           if(orderDetails.internal_order_id != null && orderDetails.internal_order_id !== undefined){ 
                setQRUrl(`${window.location.origin  }/order/receipt/${  orderDetails.internal_order_id}`);
          } 


          orderDetails.price_breakup.forEach(e => {
            if(e.name === "grand_total"){
                setGrandTotal(e.value);
            }
          });
        }


    }, [orderDetails]);
    
    const renderGoBackBtn = () => {
        if(orderDetails!= null && orderDetails.order_id != null){
            return (
                <div>
                <span>{<Button  variant="contained" id="go-back" onClick={(e) => navigate("/menu" )} aria-label="go-back"  >
                    <Typography  >Place another order</Typography>
                    </Button>}</span> 
                </div>
            );
        }
        return (
            <div>
                <span>{<Button   variant="contained" id="go-back" onClick={(e) => navigate("/")} aria-label="go-back"  >
                    <Typography  >Go Back</Typography>
                    </Button>}
                </span>
            </div>
        ); 
    }

    return (
        <Page title="Receipt">
            
                <Grid container>
                    {/* <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5} pt={2}>
                        <Typography variant="h4" gutterBottom>
                            Order Receipt
                        </Typography>
    
                    </Stack> */}

                    <OrderSummaryHeaderMobile />

                    <Grid container  direction="column" justifyContent="start" alignItems="center" mt={8} >
                        <Grid className= "order_details_container glass-box" padding={2} sx={{minWidth:"40vw", width:'100%'}} >
                            {
                                orderDetails != null && orderDetails.order_id != null && 
                                <div style={{width:'100%'}}>
                                    {/* <Grid container  direction={{xs: "column", lg:"row"  }} justifyContent="flex-start" alignItems="center">
                                            <img src='../../static/success.png' alt="success" width="60"/>
                                            <Typography variant="body1" sx={{fontSize:'1rem'}} px={4}>Congratulations, your order has been placed successfully</Typography>               
                                    </Grid>
                                     */}
                                    <Grid container  direction="column" justifyContent="flex-start" alignItems="flex-start" py={2}>
                                        <Typography variant="h4">Order ID : {orderDetails.order_id}</Typography>              
                                        <Typography variant="h4">Order Date : {formatDate(orderDetails.order_date, "DD MMM YYYY HH:mm A")}</Typography>
                                        <Typography variant="h3">Payment Method : {orderDetails?.payment_mode_name}</Typography>

                                    </Grid>
                                    {/* <Grid className="price_breakup_single w-100 grand_total">
                                                <h3 className="grand_total_label">
                                                Payment Method
                                                </h3>

                                                <h3 className="grand_total_value">
                                                {orderDetails?.payment_mode_name}
                                                </h3>
                                        </Grid> */}
                                    <Divider />    

                                    <Grid container  direction="column" justifyContent="space-around" alignItems="flex-start" sx={{marginTop:1}} className="pad-y-8">
                                        {/* <Grid item sx={{padding:0}}>
                                            <Typography variant="body1" sx={{fontSize:'1rem'}}>Meals included in this order : </Typography>              
                                        </Grid > */}
                                    
                                        <Grid item  sx={{width:'100%'}}>
                                            {
                                                orderDetails.order_items && orderDetails.order_items.map((v, j) => (
                                                    <Grid key={j} className={'glass-box'} flexDirection={'column'} padding={2} marginY={4}>
                                                        <Typography variant='h3'>{v.store?.display_name}</Typography>
                                                        
                                                        {
                                                            v.items?.map((obj, i) => (
                                                                <Grid key={obj.corporate_menu_id} item  container mt={2} mb={2}  
                                                                    xs={12} sm={6} md={6} lg={4} xl={4}>

                                                                    
                                                                    {/* <Grid item container direction="row" justifyContent="flex-start" alignItems="flex-start">
                                                                        <Typography color="#000" sx={{fontSize:14, padding: 0.5}}>{obj.menu_name} </Typography> 
                                                                    </Grid> */}
                                                                    <Grid item container  direction="column" justifyContent="space-around" alignItems="flex-start"
                                                                    
                                                                    >

                                                                        <Grid container flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} sx={{width:'100%'}}>

                                                                            <Grid container flexDirection={'column'} sx={{width:'80%'}}>

                                                                                <Grid container item flexDirection={'row'} justifyContent={'flex-start'} alignItems={'center'} columnGap={0.5} >
                                                                                    {
                                                                                        obj.is_non_veg 
                                                                                        ? <img height={16} width={16} src='/static/nonveg.png' alt='non_veg' />
                                                                                        : <img height={16} width={16} src='/static/veg.png' alt='veg' />
                                                                                    }
                                                                                    <Typography variant="h4">{obj.quantity} x</Typography>
                                                                                    <Typography variant="h4">{obj.menu_name}</Typography>
                                                                                </Grid>

                                                                                {
                                                                                    obj.addons && obj.addons.length > 0 &&
                                                                                    <Grid item sx={{padding:0 }}>
                                                                                        <Typography variant="body1">{`Addons : ${obj.addons.map(item => {return  `${item.menu_name} (x${item.quantity})`}).join(',')}`} </Typography>
                                                                                    </Grid>
                                                                                }
                                                                            </Grid>

                                                                            <Grid container item sx={{width:'20%'}} justifyContent={'flex-end'}>
                                                                                <Typography variant="h4">{`₹ ${obj.total_effective_price}`}</Typography>
                                                                            </Grid>

                                                                        </Grid>

                                                                        {
                                                                            obj.addons && obj.addons.length > 0 &&
                                                                            <Grid item sx={{padding:0 }}>
                                                                                <Typography color="#000" sx={{fontSize:14 }}>{`Addons : ${obj.addons.map(item => {return  `${item.menu_name} (x${item.quantity})`}).join(',')}`} </Typography>
                                                                            </Grid>
                                                                        }

                                                                    </Grid>
                                                                </Grid>
                                                            ))
                                                        }
                                                    </Grid>
                                                ))
                                            }
                                        </Grid>
                                    </Grid>
                                    <Divider />    

                                    <Grid container flexDirection={'column'} padding={1}>
                                        {
                                            orderDetails?.price_breakup?.map((e) => 
                                                (e.value !== 0) && <Grid key={e.name} className="price_breakup_single w-100">
                                                    <Typography variant="h4">
                                                    {e.display_name}
                                                    </Typography>

                                                    <Typography variant="h4" className={(e.name === 'discount' || e.name === 'credits')? 'price-breakdown-discount' : ''}>
                                                        {(e.name === 'discount' || e.name === 'credits') ? '-' : ''} ₹ {e.value}
                                                    </Typography>
                                                </Grid>
                                            )
                                        }

                                        <Box margin={2} />
                                        <Divider spacing={1} sx={{ borderStyle: 'dashed' }}/>
                                        
                                        <Box margin={2} />
                                    </Grid>

                                    <Grid className="price_breakup_single w-100 grand_total">
                                        <Typography variant="h3" className="grand_total_label">
                                        Grand Total
                                        </Typography>

                                        <Typography variant="h3"  className="grand_total_value">
                                        ₹ {grandTotal}
                                        </Typography>
                                    </Grid>
                                    
                                    {/* <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start">
                                        <Typography color="#000" sx={{fontSize:13 }} padding={1}> Please show this receipt at the service counter.</Typography>
                                    </Grid> */}
                                </div>
                            }

                            { !isLoading && !orderDetails &&
                                orderDetails == null && 
                                <div style={{width:'100%'}}>
                                    <Grid container  direction="column" justifyContent="space-around" alignItems="center">
                                        <Typography variant='h5'>Order not Found</Typography>
                                    </Grid>
                                    
                                </div>
                            }
                            
                            <Grid container direction="row" py={2} justifyContent="center" alignItems="center">
                                    {renderGoBackBtn()}
                            </Grid>
                        </Grid>
                    </Grid>
                    
                </Grid>
        </Page>
    );
}
