import { useEffect, useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useCookies } from "react-cookie";

import Iconify from "../Iconify";
import { HOME } from "../../constants/screen_constants";
import { getLoginStatus, logoutUser } from "../../store/user/reducer";

export default function MyProfileHeaderMobile() {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);

    const [cookies, setCookie, removeCookie] = useCookies(['mobile','session', 'access_token']);
    
    const isLoggedIn = useSelector(state => getLoginStatus(state));

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
      setOpen(false);
    };

    const handleLogout = () => {
        setOpen(false);
        removeCookie('mobile', { path: '/' });
        removeCookie('session', { path: '/' });
        removeCookie('access_token', { path: '/' });
        dispatch(logoutUser());
        navigate(HOME);
    }

    useEffect(() => {
        if (!isLoggedIn) navigate(HOME);
    },[isLoggedIn]);

    return (
        <Grid container className="header" flexDirection={'row'} justifyContent={'flex-start'} sx={{height:'8vh'}}>
            <Grid container item sm={3} md={3} xs={3} lg={3} xl={3}
                padding={1.5} 
                flexDirection={'row'} 
                alignItems={'center'} 
                justifyContent={'flex-start'} 
                onClick={() => {
                    navigate(HOME);
                }}>
                    <Iconify icon={'eva:chevron-left-outline'} sx={{width: 32, height: 32, color: '#fff'}} />
            </Grid>

            <Grid container item justifyContent={'center'} alignItems={'center'}  sm={6} md={6} xs={6} lg={6} xl={6}>
                <Typography variant="h4" sx={{color:'#fff'}}>
                    {'My Profile'}
                </Typography>
            </Grid>

            <Grid  container item sm={3} md={3} xs={3} lg={3} xl={3}
                padding={1.5} 
                flexDirection={'row'} 
                alignItems={'center'} 
                justifyContent={'flex-end'} 
                onClick={handleOpen}>
                    <Iconify icon={'eva:power-outline'} sx={{width: 32, height: 32, color: '#fff'}} />
            </Grid>

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="logout-dialog-title"
                aria-describedby="logout-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="logout-dialog-description">
                        <Typography variant="h4">Are you sure you want to logout?</Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleLogout}>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        </Grid>
    );
}