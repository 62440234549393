
export const APP_VERSION = '1.1.0';
export const DEVICE_TYPE = 'WEB';

export const ENCRYPTION_KEY = "WCBoaDQIQAgceGg8dFAkMDBEOECEZCxg";
export const ENCRYPTION_IV = "bytes@password20";

// export const RAZORPAY_KEY = "rzp_test_LFH0TySRmo8gc4";
export const RAZORPAY_KEY = "rzp_live_n3Zm83U5PMJTVN ";

export const BASIC_AUTH_USERNAME = "bytes_open_User";
export const BASIC_AUTH_PASSWORD = "BOU_923IOWkjre87839843jdf3290843#&32";

export const STATUS = {
    LOADING : 'loading',
    SUCCESS: 'success',
    ERROR : 'error',
};

export const PLAN_SELECTION_STATUS = {
    INITIAL : 'status_initial',
    SELECTED : 'status_selected',
    CONFIRMED: 'status_confirmed',
    SKIPPED : 'status_skipped',
};

export const CLIENT_MEAL_SCHEDULE_EDIT_STATUS = {
    INITIAL : 'status_initial',
    EDITING : 'status_editing',
};

export const CLIENT_ACTIVITY_SCHEDULE_EDIT_STATUS = {
    INITIAL : 'status_initial',
    EDITING : 'status_editing',
};

export const PREFERENCE_STATUS = {
    INITIAL : 'initial',
    SELECTED : 'selected',
};

export const CART_STATUS = {
    INITIAL : 'initial',
    CONFIRMED : 'comfirmed',
};

export const PAYMENT_STATUS = {
    INITIAL : 'initial',
    PENDING: 'pending',
    CONFIRMED : 'comfirmed',
    CANCELLED: 'cancelled',
    FAILED: 'failed' 
};

export const MEAL_PREFERENCE = {
    VEGETARIAN : 'veg',
    NONVEGETARIAN: 'non-veg',
    EGGETARIAN : 'egg'
};

export const ORDER_TYPE = {
    SUBSCRIPTION : 'SUBSCRIPTION',
    ZERO_DELIVERY: 'ZERO_DELIVERY' 
};

export const MEAL_TYPE = {
    BREAKFAST : 'Breakfast',
    LUNCH: 'Lunch',
    SNACKS : 'Snacks',
    DINNER: 'Dinner'
};

export const ONBOARDING_STATUS = {
    INITIAL: 'initial',
    SUCCESS: 'success',
    FAIL : 'fail',
};

export const ONBOARDING_FORM_STEPS = {
    OTP_VERIFICATION : {
        'step' : 'otp_verification',
    },
    PERSONAL_INFO: {
        'step' : 'personal_info',
    },
    PLAN_LIST: {
        'step' : 'plan_list',
    },
    PREFERENCE_SELECTION: {
        'step' : 'preference_selection',
    },
    SUBSCRIPTION_DETAILS: {
        'step' : 'subscription_details',
    },
    PAYMENT_DETAILS: {
        'step' : 'payment_details',
    },
    ONBOARDING_CONFIRMATION: {
        'step' : 'onboarding_confirmation',
    }
}


export const PAYMENT_MODE = {
    OFFLINE : 'CASH',
    ONLINE: 'online'
}

export const PAYMENT_GATEWAY = {
    CASH : {
        'name':'cash',
        'enabled' : false,
        'label': 'Cash'
    },
    PAYTM: {
        'name':'paytm',
        'enabled' : true,
        'label': 'Paytm'
    },
    RAZORPAY : {
        'name':'RAZOR_PAY',
        'enabled' : true,
        'label': 'Razorpay'
    },
    PHONEPE: {
        'name':'PHONEPE',
        'enabled' : true,
        'label': 'PhonePe'
    }
}

export const calendarMealType = [
    {
      text: 'Breakfast',
      id: 1,
      color: '#FF7043',
    }, {
      text: 'Lunch',
      id: 2,
      color: '#7E57C2',
    }, {
      text: 'Snacks',
      id: 3,
      color: '#FFA726',
    }, {
      text: 'Dinner',
      id: 4,
      color: '#E91E63',
    } 
];


export const ROLES = {
    MASTER_ADMIN : 'master_admin',
    NUTRITIONIST : 'nutritionist',
    CORPORATE_MANAGER : 'corporate_manager',
    KITCHEN_MANAGER : 'kitchen_manager',
    COACH : 'coach',
    INVOICE_AUDIT : 'invoice_audit',
    CONTENT_MANAGER : 'content_manager'
};


export const ACCOUNT_TYPE = {
    PERSONAL : 'personal',
    CORPORATE: 'corporate' 
};

export const SNACKBAR_SEVERITY = {
    ERROR : 'error',
    INFO: 'info',
    WARNING: 'warning', 
    SUCCESS: 'success', 
};
 

export const FOOTER_LINKS = {
    ABOUT_US : 'https://www.bytes.express/about-us/',
    FAQS: 'https://www.bytes.express/#faq/',
    PRIVACY_POLICY : './terms-and-conditions/',
    FACEBOOK_URL : 'https://www.facebook.com/bytesthedeliciousproject/',
    INSTAGRAM_URL : 'https://www.instagram.com/bytes_thedeliciousproject/',
    TWITTER_URL : 'https://twitter.com/bytes_foodstory',
    LINKEDIN_URL : 'https://www.linkedin.com/company/the-bytes/',
    YOUTUBE_URL : 'https://www.youtube.com/channel/UCnEMaOim8duaTOUYvcUF4CQ',
}

export const USER_PAYMENT_TYPE = {
    CORPORATE_PAID : 'CORPORATE_PAID',
    USER_PAID : 'USER_PAID',
}

export const CHECKOUT_TYPE = {
    STANDARD : 'STANDARD',
    QUICK_CHECKOUT : 'QUICK_CHECKOUT'
}

export const FEEDBACK_ITEMS = [
    {
        'key': 'food_taste_nps',
        'title': 'Food Taste',
        'rating': 3,
    },
    {
        'key': 'food_quality_nps',
        'title': 'Food Quality',
        'rating': 3,
    },
    {
        'key': 'food_quantity_nps',
        'title': 'Quantity',
        'rating': 4,
    },
    {
        'key': 'service_nps',
        'title': 'Service',
        'rating': 4,
    },
    {
        'key': 'variety_nps',
        'title': 'Variety',
        'rating': 4,
    },
    {
        'key': 'punctuality_nps',
        'title': 'Punctuality',
        'rating': 4,
    },
    {
        'key': 'accuracy_nps',
        'title': 'Accuracy',
        'rating': 4,
    },
    {
        'key': 'hygiene_nps',
        'title': 'Hygiene',
        'rating': 4,
    },
]

export const FEEDBACK_ITEM_OPTIONS = [
    {
        'key' : 1,
        'label' : 'Bad',
        'value': 1,
        'icon': 'fa-frown-o',
        'category': 'bad',
    },
    {
        'key' : 2,
        'label' : 'Fair',
        'value': 2,
        'icon': 'fa-frown-o',
        'category': 'bad',
    },
    {
        'key' : 3,
        'label' : 'Average',
        'value': 3,
        'icon': 'fa-meh-o',
        'category': '',
    },
    {
        'key' : 4,
        'label' : 'Good',
        'value': 4,
        'icon': 'fa-smile-o',
        'category': 'good',
    },
    {
        'key' : 5,
        'label' : 'Excellent',
        'value': 5,
        'icon': 'fa-smile-o',
        'category': 'love',
    }
];
