import { Copyright, CopyrightRounded } from "@mui/icons-material";
import { Grid, Typography } from "@mui/material";
import React from "react";
import { FOOTER_LINKS } from "../constants/app_constants";
import {
  Box,
  Column,
  FooterLink,
  Links,
} from "./FooterStyles";
import Iconify from "./Iconify";

const Footer = () => (
    <Box className="footer">
      <Grid item container direction={{ "sm" : "row", "xs" : "column" }} justifyContent="space-between" 
        xs={12} sm={12} md={12} lg={12} xl={12}>
        <Links item container mt={2}
          xs={12} sm={6} md={4} lg={4} xl={4} >
          {/* <Column> 
            <FooterLink href={FOOTER_LINKS.ABOUT_US} target="_blank">About Us</FooterLink>
          </Column> */}
          <Column> 
            <FooterLink href={FOOTER_LINKS.PRIVACY_POLICY}>Privacy Policy</FooterLink>
          </Column>
          <Column> 
            <FooterLink href={FOOTER_LINKS.FAQS} target="_blank">FAQs </FooterLink>
          </Column>            
        </Links>
        <Grid item container mt={2} justifyContent="space-around" 
          xs={12} sm={6} md={3} lg={3} xl={3}>
          <Typography style={{fontSize: '0.8rem'}}><CopyrightRounded style={{fontSize: '0.8rem'}} /> 2022 ByteRight Technologies Pvt Ltd. </Typography>
        </Grid>
        <Grid item container mt={2} justifyContent="space-around" 
          xs={6} sm={6} md={3} lg={3} xl={3}>
          <Column> 
            <FooterLink href={FOOTER_LINKS.FACEBOOK_URL} target="_blank">
              <Iconify icon="fa:facebook" />
            </FooterLink>
          </Column>
          <Column> 
            <FooterLink href={FOOTER_LINKS.INSTAGRAM_URL} target="_blank">
              <Iconify icon="fa:instagram" />
            </FooterLink>
          </Column>
          <Column> 
            <FooterLink href={FOOTER_LINKS.TWITTER_URL} target="_blank">
              <Iconify icon="fa:twitter" />
            </FooterLink>
          </Column>            
          <Column> 
            <FooterLink href={FOOTER_LINKS.LINKEDIN_URL} target="_blank">
              <Iconify icon="fa:linkedin" />
            </FooterLink>
          </Column>            
          <Column> 
            <FooterLink href={FOOTER_LINKS.YOUTUBE_URL} target="_blank">
              <Iconify icon="fa:youtube" />
            </FooterLink>
          </Column>            
        </Grid>
      </Grid>
    </Box>
  );
export default Footer;