import { createSlice } from '@reduxjs/toolkit'
 import { PAYMENT_MODE, PAYMENT_STATUS, PAYMENT_GATEWAY } from '../../constants/app_constants';

const paymentSlice = createSlice({
  name: 'payment',
  initialState: {
      orderDetails: null,
      addTransactionDetails : null, 
      paymentId: null,
      paymentAck : null,
      paymentStatus: PAYMENT_STATUS.INITIAL,
      paymentMode: null,
      paymentGateway : null,
      paymentStatusCheckCount: 0,
  },
  reducers: {
    setOrderDetails(state, action) {
        state.orderDetails = action.payload.data;
    },
    setAddTransactionDetails(state, action){
        state.addTransactionDetails = action.payload.data;
    },
    setPaymentId(state, action) {
        state.paymentId = action.payload.data;
    },
    setPaymentAck(state, action) {
        state.paymentAck = action.payload.data;
    },
    setPaymentStatus(state, action) {
        state.paymentStatus = action.payload.status;
    },
    setPaymentMode(state, action) {
        state.paymentMode = action.payload.mode;
        console.log(state.paymentMode);
        console.log(action.payload.mode);
    },
    setPaymentGateway(state, action) {
        state.paymentGateway = action.payload.gateway;
    },
    increasePhonepeStatusCheckCount(state, action) {
        state.paymentStatusCheckCount += 1;
    }, 
    resetPaymentState(state, action) {
        state.orderDetails= null;
        state.addTransactionDetails = null;
        state.paymentId= null;
        state.paymentAck = null;
        state.paymentStatus= PAYMENT_STATUS.INITIAL;
        state.paymentMode= null ;
        state.paymentGateway= null;
        state.paymentStatusCheckCount = 0;
    },
  }
})

export const getOrderDetails = (state) => state.payment.orderDetails;
 

export const getPaymentId = (state) => {
    try{
         return state.payment.paymentId;
    }catch(err){
         return null;
    } 
}

export const getPaymentAck = (state) => {
    try{
         return state.payment.paymentAck;
    }catch(err){
         return null;
    } 
}

export const getPaymentStatus = (state) => state.payment.paymentStatus;

export const getPaymentMode = (state) => state.payment.paymentMode; 

export const getSelectedPaymentMode = (state) => {
    try{
        if (state.payment.paymentMode === PAYMENT_MODE.OFFLINE){
            return PAYMENT_GATEWAY.CASH;
        }

    }catch(e){
        console.log(e);
    }
    
    return state.payment.paymentGateway;
        
}

export const getPaymentGateway = (state) => state.payment.paymentGateway; 

export const getAddTransactionDetails = (state) => state.payment.addTransactionDetails; 

export const getPaymentStatusCheckCount = (state) => state.payment.paymentStatusCheckCount;  

export const { setOrderDetails, setPaymentId, setPaymentAck, setAddTransactionDetails, 
    setPaymentStatus, setPaymentMode, resetPaymentState, setPaymentGateway, increasePhonepeStatusCheckCount } = paymentSlice.actions
export default paymentSlice.reducer