import { setLoadingStatus, setSnackbarMessage, setSnackbarVisibilityStatus } from '../../../store/layout/reducer';
import { removeCorporateNpsItem, setUser, setUserCorporateMeal, setUserCorporateOrderList, setUserMealHistory } from '../../../store/user/reducer';
import axios from '../../../utils/axios-configure';
import { API_ADD_BULK_NPS_PROMPT, API_GET_USER_CORPORATE_MEAL, API_GET_USER_CORPORATE_ORDERS, API_GET_USER_DETAILS, API_GET_USER_MEAL_HISTORY, API_STORE_FEEDBACK_MEAL, API_UPDATE_USER_DETAILS } from "../endpoints";
import {getAccessTokenFromCookie} from '../session/api'
import { SNACKBAR_SEVERITY } from '../../../constants/app_constants';
import { PROFILE_UPDATE } from '../../../constants/message_constants';

export const fetchUserDetails = (dispatch, cookies) => {
    dispatch(setLoadingStatus({status: true}));  
    const  accessToken = getAccessTokenFromCookie(dispatch, cookies); 
    if(accessToken != null){
        axios({
            method: "get",
            url: `${API_GET_USER_DETAILS}`,
            headers: { "Content-Type": "application/json",'Authorization': `Bearer ${ accessToken}` },
          })
          .then((response) => {
            // handle success
            if(response.status === 200){
              dispatch(setUser({data: response.data.data}));
            }else{
              dispatch(setUser({data: {}}));
            } 
            dispatch(setLoadingStatus({status: false}));  
            return true;
          })
          .catch((err) => {
            dispatch(setUser({data: {}}));
            dispatch(setLoadingStatus({status: false}));  
            // console.log(response);
          });
    }else{
      dispatch(setUser({data: {}}));
      dispatch(setLoadingStatus({status: false}));  
    }
}

export const updateUserDetails = (dispatch, cookies, data) => {
  dispatch(setLoadingStatus({status: true}));  
  const  accessToken = getAccessTokenFromCookie(dispatch, cookies); 
  if(accessToken != null){
      axios({
          method: "post",
          url: `${API_UPDATE_USER_DETAILS}`,
          data,
          headers: { "Content-Type": "application/json",'Authorization': `Bearer ${ accessToken}` },
        })
        .then((response) => {
          // handle success
          if(response.status === 200){
            dispatch(setUser({data: response.data.data}));
            dispatch(setSnackbarMessage({message: PROFILE_UPDATE.SUCCESS ,severity:SNACKBAR_SEVERITY.SUCCESS}))
            dispatch(setSnackbarVisibilityStatus({status: true}));
          }else{
            dispatch(setUser({data: {}}));
            dispatch(setSnackbarMessage({message: PROFILE_UPDATE.ERROR ,severity:SNACKBAR_SEVERITY.ERROR}))
            dispatch(setSnackbarVisibilityStatus({status: true}));
          } 
          dispatch(setLoadingStatus({status: false}));  
          return true;
        })
        .catch((err) => {
          dispatch(setUser({data: {}}));
          dispatch(setLoadingStatus({status: false}));  
          dispatch(setSnackbarMessage({message: PROFILE_UPDATE.ERROR ,severity:SNACKBAR_SEVERITY.ERROR}))
          dispatch(setSnackbarVisibilityStatus({status: true}));
        // console.log(response);
        });
  }else{
    dispatch(setUser({data: {}}));
    dispatch(setLoadingStatus({status: false}));  
    dispatch(setSnackbarMessage({message: PROFILE_UPDATE.ERROR ,severity:SNACKBAR_SEVERITY.ERROR}))
    dispatch(setSnackbarVisibilityStatus({status: true}));
}
}

export const fetchUserCorporateOrders = (dispatch, cookies, data) => {
  dispatch(setLoadingStatus({status: true}));  
  const  accessToken = getAccessTokenFromCookie(dispatch, cookies); 
  if(accessToken != null){
      axios({
          method: "get",
          url: `${API_GET_USER_CORPORATE_ORDERS}?order_type=corporate&start_date=${data.start_date}&end_date=${data.end_date}`,
          headers: { "Content-Type": "application/json",'Authorization': `Bearer ${ accessToken}` },
        })
        .then((response) => {
          // handle success
          if(response.status === 200){
            dispatch(setUserCorporateOrderList({data: response.data.data}));
          }else{
            dispatch(setUserCorporateOrderList({data: {}}));
          } 
          dispatch(setLoadingStatus({status: false}));  
          return true;
        })
        .catch((err) => {
          dispatch(setUserCorporateOrderList({data: {}}));
          dispatch(setLoadingStatus({status: false}));  
          // console.log(response);
        });
  }else{
      dispatch(setUserCorporateOrderList({data: {}}));
      dispatch(setLoadingStatus({status: false}));  
    }
}

 
export const fetchUserCorporateMeal = (dispatch, cookies, data) => {
  dispatch(setLoadingStatus({status: true}));  
  const  accessToken = getAccessTokenFromCookie(dispatch, cookies); 

  if(accessToken != null){
      axios({
          method: "get",
          url: `${API_GET_USER_CORPORATE_MEAL}?start_date=${data.start_date}&end_date=${data.end_date}`, 
          headers: { "Content-Type": "application/json",'Authorization': `Bearer ${ accessToken}` },
        })
        .then((response) => {
          // handle success
          if(response.status === 200){
            dispatch(setUserCorporateMeal({data: response.data.data}));
          }else{
            dispatch(setUserCorporateMeal({data: {}}));
          } 
          dispatch(setLoadingStatus({status: false}));  
          return true;
        })
        .catch((err) => {
          dispatch(setUserCorporateMeal({data: {}}));
          dispatch(setLoadingStatus({status: false}));  
          // console.log(response);
        });
  }else{
      dispatch(setUserCorporateMeal({data: {}}));
      dispatch(setLoadingStatus({status: false}));  
    }
}


export const storeFeedbackMeal = (dispatch, cookies, data) => {
  dispatch(setLoadingStatus({status: true}));  
  const  accessToken = getAccessTokenFromCookie(dispatch, cookies); 
  if(accessToken != null){
      axios({
          method: "post",
          url: `${API_STORE_FEEDBACK_MEAL}`,
          data,
          headers: { "Content-Type": "application/json",'Authorization': `Bearer ${ accessToken}` },
        })
        .then((response) => {
          // handle success
          if(response.status === 200){
              // dispatch(removeCorporateNpsItem({data}));
          }
          dispatch(setLoadingStatus({status: false}));  
          return true;
        })
        .catch((err) => {
          dispatch(setLoadingStatus({status: false}));  
        });
  }else{
    dispatch(setLoadingStatus({status: false}));  
  }
}

export const addBulkNpsPrompt = (dispatch, cookies, data) => {
  dispatch(setLoadingStatus({status: true}));  
  const  accessToken = getAccessTokenFromCookie(dispatch, cookies); 
  if(accessToken != null){
      axios({
          method: "post",
          url: `${API_ADD_BULK_NPS_PROMPT}`,
          data,
          headers: { "Content-Type": "application/json",'Authorization': `Bearer ${ accessToken}` },
        })
        .then((response) => {
          // handle success
          if(response.status === 200){
              // dispatch(removeCorporateNpsItem({data}));
          }
          dispatch(setLoadingStatus({status: false}));  
          return true;
        })
        .catch((err) => {
          dispatch(setLoadingStatus({status: false}));  
        });
  }else{
    dispatch(setLoadingStatus({status: false}));  
  }
}