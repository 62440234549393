// import {ENCRYPTION_KEY, ENCRYPTION_IV} from '../constants/app_constants';

import CryptoES from 'crypto-es';
import { ENCRYPTION_IV, ENCRYPTION_KEY } from "../constants/app_constants";


export function encrypt(valueToEncrypt) {
    
    const key = CryptoES.enc.Utf8.parse(ENCRYPTION_KEY);
    const iv  = CryptoES.enc.Utf8.parse(ENCRYPTION_IV);

    const encryptedValue = CryptoES.AES.encrypt(valueToEncrypt, key, {iv});
    const textString = CryptoES.enc.Base64.stringify(encryptedValue.ciphertext);

    return textString;
  }