import { createSlice } from '@reduxjs/toolkit';
import { formatDate } from '../../utils/date';
import {STATUS, PAYMENT_STATUS, PLAN_SELECTION_STATUS, PAYMENT_MODE, MEAL_TYPE,
  MEAL_PREFERENCE, ORDER_TYPE, CLIENT_MEAL_SCHEDULE_EDIT_STATUS,
  CLIENT_ACTIVITY_SCHEDULE_EDIT_STATUS
} from '../../constants/app_constants';

const aboutSlice = createSlice({
  name: 'about',
  initialState: {
    termsAndConditions: null,
  },
  reducers: {
    setTermsAndConditions(state, action) {
      state.termsAndConditions = action.payload.data;
    },
    resetCorporateState(state, action){
      state.termsAndConditions = null; 
    }, 
  }
})

export const getTermsAndConditions = (state) => {

  if(state.about != null && state.about !== undefined){
    return state.about.termsAndConditions;
  }
  return [];
}


 
export const { setTermsAndConditions  } = aboutSlice.actions

export default aboutSlice.reducer