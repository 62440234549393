import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useCookies } from "react-cookie";
import { Grid } from "@mui/material";


import OrderSummaryHeaderMobile from "../../components/header/OrderSummaryHeaderMobile";
import { fetchCorporateOrderDetails } from "../../data/network/order/api";
import { getActiveOrderDetails } from "../../store/order/reducer";
import OrderSummaryItemCard from "./OrderSummaryItemCard";

export default function OrderSummaryMobile () {

    const dispatch = useDispatch();
    const [cookies, setCookie, removeCookie] = useCookies(['mobile','session', 'access_token']);

    const params = useParams();
    const orderDetails = useSelector(state => getActiveOrderDetails(state) );

    useEffect(() => {
        console.log(orderDetails);
    },[orderDetails]);

    useEffect(() => {
        const orderId = params.order_id;
        fetchCorporateOrderDetails(dispatch, cookies, orderId );
    },[])
    

    return (
        <Grid container sx={{background:'red'}}>
            <OrderSummaryHeaderMobile />

            <Grid container flexDirection={'column'} justifyContent={'flex-start'} alignItems={'center'}>
                {
                    orderDetails?.order_items?.map((e) => {
                        return <OrderSummaryItemCard orderItem={e} />
                    })
                }
            </Grid>

        </Grid>
    );

}