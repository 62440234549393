import { useState, useEffect } from "react";
import { Dialog, DialogTitle, List, ListItem, ListItemAvatar, ListItemButton, ListItemText } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { CheckCircleOutline } from "@mui/icons-material";
import { getSelectedCorporate, setSelectedCorporate } from "../../../store/otp/reducer";
import { getUser } from "../../../store/user/reducer";

export default function SelectCorporateModalWeb({open, onClose}) {

    const dispatch = useDispatch();
    console.log("Xxxxxxxxxx");
    const [corporateClients, setCorporateClients] = useState([]);

    const userDetails = useSelector(state => getUser(state));
    const selectedCorporate = useSelector(state => getSelectedCorporate(state));  

    const handleCorporateSelect = (corporate) => {
        dispatch(setSelectedCorporate({'corporate': corporate}));
        onClose();
    }

    useEffect(() => {
        setCorporateClients(userDetails?.corporate_clients ?? []);
    },[userDetails]);

    return (
        <Dialog onClose={onClose} open={open}>
            <DialogTitle>Select Corporate</DialogTitle>
            <List sx={{ pt: 0 }}>
                {corporateClients.map((corporate) => {
                    const isSelected = (corporate.id === selectedCorporate?.id);

                    return <ListItem disableGutters key={corporate.id}>
                        <ListItemButton onClick={() => handleCorporateSelect(corporate)}>
                            <ListItemText primary={corporate.display_name} />
                            {
                                isSelected && <ListItemAvatar sx={{marginLeft:'20px', display:'flex', justifyContent:'flex-end'}}>
                                    <CheckCircleOutline sx={{color:'green'}} />
                                </ListItemAvatar>
                            }

                        </ListItemButton>
                    </ListItem>
                })}

            </List>
        </Dialog>
    );
}

