import { useEffect, useState } from 'react';
 // @mui
 import PropTypes from 'prop-types';
 import { alpha, styled } from '@mui/material/styles';
 import { Box, Card, Grid, Switch, Typography } from '@mui/material'; 
 import { useDispatch, useSelector } from 'react-redux';
 
 import { getCategoryList,filterAdded, getSelectedFilter, getMenuList, filterRemoved, getSelectedFilterKeyword, getVegPreferenceFilter } from '../../store/menu/reducer';
import Iconify from '../../components/Iconify';

 export default function SearchMenu() {
    const dispatch = useDispatch();
    const [isFilterVisible, setIsFilterVisible] = useState(true);

    const [isSearchVisible, setIsSearchVisible] = useState(false); 

    const searchTerm = useSelector(state => getSelectedFilterKeyword(state));
    const isVegPreferred = useSelector(state => getVegPreferenceFilter(state));

 
    const toggleVegPreference = () => {
        if(!isVegPreferred) {
            dispatch(filterAdded({type: 'preference', value: 'veg'}));
        }else{
            dispatch(filterRemoved({type: 'preference', value: 'veg'}))
        }
    }

    return (
        <Grid sx={{width:'100%'}}> 
            <Box margin={1} /> 
            <Grid container flexDirection={'row'} justifyContent={'flex-start'} alignItems={'center'}>
                {isSearchVisible && <Grid container className={'search-menu-container'} flexWrap={'nowrap'} overflow={'scroll'} spacing={0} sm={12} xs={12} lg={12} md={12} xl={12}> 
                        <form className="search-menu-form "> 
                            <input type="search" className='input-transparent glass-box' placeholder='Search for dish' defaultValue={searchTerm} onChange={(e) => {
                                dispatch(filterAdded({type: 'keyword', value: e.target.value}));
                            }} style={{background:'#fff!important'}} />
                        </form>
                    </Grid>
                }
                {/* { isFilterVisible && <Grid container className={'menu-search-filter-toggle'} padding={1} sx={{backgroundColor: '#fff',  borderRadius:'4px', width:'24px'}} sm={1} xs={1} lg={1} md={1} xl={1}
                        onClick={(e) => {
                            setIsFilterVisible(!isFilterVisible);
                        }}
                    > 
                        <Iconify icon={'eva:funnel-outline'} sx={{color:'#e86826', fontSize:'16px'}} />
                    </Grid>
                }
                
                { !isFilterVisible && <Grid container className={'menu-search-filter-toggle'} padding={1} sx={{backgroundColor: '#e86826',  borderRadius:'4px', border:'1px solid #fff', width:'24px'}} sm={1} xs={1} lg={1} md={1} xl={1}
                    onClick={(e) => {
                        setIsFilterVisible(!isFilterVisible);
                    }}
                >
                    <Iconify icon={'eva:funnel-outline'} sx={{color:'#fff', fontSize:'16px'}} />
                </Grid>
                } */}
            </Grid>

            {/* {
                isFilterVisible && <Grid item container xs={5} lg={0} sm={5} md={5} xl={0} paddingLeft={2} flexDirection={'row'} justifyContent={'flex-start'} alignItems={'center'}>
                    <Typography variant="subtitle1" sx={{color: '#fff'}}>Veg only</Typography>
                    <Switch color={'success'}
                        checked={checked} onChange={(e) => {
                        if(e.target.checked) {
                            dispatch(filterAdded({type: 'preference', value: 'veg'}));
                        }else{
                            dispatch(filterRemoved({type: 'preference', value: 'veg'}))
                        }
                    }} />
                </Grid>
            }   */}

            
        </Grid>
    );
 }
 