import { useEffect, useState } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Link, Container, Typography, Card, Stack, Box, Button, Badge } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useCookies } from 'react-cookie';

import {useNavigate } from 'react-router-dom';
import { DateRangePicker } from 'react-date-range';
import { addDays, subDays } from 'date-fns';
import $ from 'jquery';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file

// components
import Page from '../components/Page'; 
import { formatDate } from '../utils/date';
import { MyMealList } from '../sections/mymeals';
// sections

import { fetchUserCorporateMeal } from '../data/network/user/api';
import { getLoginStatus } from '../store/user/reducer';
import Navbar from '../components/Navbar';
import Iconify from '../components/Iconify';
import { getCartItems } from '../store/cart/reducer';


// ----------------------------------------------------------------------

export default function MyCorporateMeals() {
  const theme = useTheme();

  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const dispatch = useDispatch();
  const [cookies, setCookie, removeCookie] = useCookies(['mobile','session', 'access_token']);
  const isLoggedIn = useSelector(state => getLoginStatus(state));
  const [futureFilterApplied, setFutureFilterApplied] = useState(false);
  const cartItems = useSelector(state => getCartItems(state));

  useEffect(() => {
    if(!isLoggedIn){
      navigate('/');
    }
  },[isLoggedIn])

  const [filterSelection, setFilterSelection] = useState([{
    startDate: subDays(new Date(), 7),
    endDate: new Date(),
    key: 'selection'
  }]);

  const handleDateFilterSelection = (selectedDateRange) => {

    if(new Date(selectedDateRange.startDate).setHours(0,0,0,0) !== new Date(selectedDateRange.endDate).setHours(0,0,0,0)){
      setOpen(false);
    }
    setFilterSelection([selectedDateRange]);  
  }

  useEffect(() => {
    fetchUserCorporateMeal(dispatch, cookies, {
      'start_date' : formatDate(filterSelection[0].startDate),
      'end_date' : formatDate(filterSelection[0].endDate)
    });
  },[filterSelection])


  return (
    <Page title="My Orders" sx={{minHeight:'100vh'}}>
      <Grid container 
        padding={1.5} 
        flexDirection={'row'} 
        alignItems={'center'} 
        justifyContent={'flex-start'} 
        onClick={() => {
            navigate('/menu');
        }}>
            <Iconify icon={'eva:chevron-left-outline'} sx={{width: 24, height: 24}} />
            <Typography variant="body1"> Go Back </Typography>    
    </Grid>
    <Grid container justifyContent={'center'} alignItems={"center"} item >
            <Typography variant={'h3'}>
              {
                "Meal History"
              }
            </Typography>
          </Grid> 
      <Grid container justifyContent={'space-evenly'} columnGap={0.5} flexDirection={'row'} alignItems={'flex-start'}>
        
        <Grid item lg={10} sm={12} md={9} xl={10} borderRadius={1} sx={{overflowY:'scroll'}}>
                 

            {/* <Container maxWidth="xl"> */}
              <Container sx={{padding: '0!important' }}  >
                {/* <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
                    <Typography variant="h5" gutterBottom>
                        Filters
                    </Typography>
                    
                    <Link href="#" underline="none">
                      RESET
                    </Link> 
                </Stack> */}
                <Grid className="user-my-orders-filter-container" container direction="row" rowGap={1} justifyContent="space-around" alignItems="center" padding={2} >
                  {/* <FormGroup>
                    <FormLabel >Meal Types</FormLabel>
                    <Box padding={1} />
                    <FormControlLabel control={<Checkbox defaultChecked/>} label="Breakfast" />
                    <FormControlLabel control={<Checkbox defaultChecked/>} label="Lunch" />
                    <FormControlLabel control={<Checkbox defaultChecked/>} label="Snacks" />
                    <FormControlLabel control={<Checkbox defaultChecked/>} label="Dinner" />
                  </FormGroup> */}
                {  
                  <Grid item container direction="row" alignItems="center" lg={6} xl={6} xs={12} md={6} sm={12}>
                    <Grid>
                      { 
                        open 
                        ? null
                        : <Typography variant="body1">Showing results for </Typography>
                      }
                    </Grid>   
                    <Grid item container justifyContent={'flex-start'} alignItems={'center'}>
                      <Grid>    
                        { open 
                          ? <Card className="glass-box">

                            <DateRangePicker 
                                onChange={item => handleDateFilterSelection(item.selection)}
                                showSelectionPreview 
                                defined
                                moveRangeOnFirstSelection={false}
                                months={1}
                                ranges={filterSelection}
                                direction="vertical"
                            />
                            </Card>
                          : <Box>
                              <Typography variant="body1">
                              {`  
                                ${formatDate(filterSelection[0].startDate, "DD MMM YYYY")}  
                                to  
                                ${formatDate(filterSelection[0].endDate, "DD MMM YYYY")}
                              `
                              } 
                              </Typography>
                              
                          </Box>
                        }
                      </Grid>  
                      <Box margin={1} />
                      <Grid>
                        {
                          (!open)
                          ? <Button variant='outlined' margin={2} onClick={() =>{
                              setOpen(true);
                            }}>Change
                          </Button>
                          : <></>
                        }
                      </Grid>
                    </Grid>
                  </Grid> 
                  }

                  <Grid item container lg={6} xl={6} xs={12} md={6} sm={12} className={'upcoming-meal-btn'}>
                      {(!futureFilterApplied)
                      ? <Button variant="outlined"  onClick={() => {

                          setFutureFilterApplied(true);
                          setFilterSelection([{
                            startDate: new Date(),
                            endDate: addDays(new Date(), 15),
                            key: 'selection'
                          }])
                        }}>
                            Show upcoming meals
                        </Button>

                      : <Button variant="outlined" gutterBottom onClick={() => {
                          setFutureFilterApplied(false);
                          setFilterSelection([{
                            startDate: subDays(new Date(), 7),
                            endDate: new Date(),
                            key: 'selection'
                          }])
                        }}>
                            Reset
                        </Button>
                      }
                      </Grid>
                </Grid>
                <Box margin={2} />
              </Container>
      
              <Grid sx={{padding: 0, marginTop: 4}} >
                  
                  <MyMealList />

              </Grid>
              <Box margin={4} />

            </Grid>
          </Grid>
    </Page>
  );
}
