import {navigationConfig} from '../constants/navigation_menu';

export function getSideNavigationMenuConfig( ) {
    const navs = [];
    navigationConfig.forEach(val => {
        if(val.visibility.includes("all")){
            navs.push(val); 
        }
    });

    return navs;
}
