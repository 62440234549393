/* eslint-disable camelcase */
import { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// material
import {
  Table,
  Stack,
  Button,
  Container,
  Typography,
  Modal,
  Box
} from '@mui/material';
import { useCookies } from 'react-cookie';
import parse from 'html-react-parser';

import { useDispatch, useSelector } from 'react-redux';
// components
import Page from '../components/Page';
import Iconify from '../components/Iconify';
import { fetchTermsAndConditions } from '../data/network/about/api';
import { getTermsAndConditions } from '../store/about/reducer';


export default function TermsAndConditions() {

    const dispatch = useDispatch();

    const data = useSelector(state => getTermsAndConditions(state));

    useEffect(()=>{
        init();
    },[]);

    const init = () => {
        try{
            dispatch(state => fetchTermsAndConditions(state));
        }catch(e){
            console.log(e);
        }
    }

    return (
        <Page title="Terms and Conditions">
        <Container>
            {
                data != null 
                ? parse(`<style>.cl1{width:'70px!important'}</style>${data}`)  
                : <></>
            }
            
        </Container>
        </Page>
    );
}
