import { createSlice } from '@reduxjs/toolkit'
import moment from 'moment';

const otpSlice = createSlice({
  name: 'otp',
  initialState: { 
    sendOTPResponse: null,
    verifyOTPResponse: null,
    selectedAccountType: null,
    selectedCorporate: null,
    selectedSubCorporate: null,
    selectedTableId: null,
  },
  reducers: {
    setSendOTPResponse(state, action) {
      state.sendOTPResponse = action.payload.data;
    },
    setVerifyOTPResponse(state, action) {
      state.verifyOTPResponse = action.payload.data;
    },
    setSelectedAccountType(state, action) {
      state.selectedAccountType = action.payload.type;
    },
    setSelectedCorporate(state, action) {
      state.selectedCorporate = action.payload.corporate;
    },
    setSelectedSubCorporate(state, action) {
      console.log(action);
      console.log(action.payload);
      state.selectedSubCorporate = action.payload.subCorporate;
    },
    setSelectedTableId(state, action) {
      state.selectedTableId = action.payload.tableId;
    },
     
    clearOTPState(state,action){
      state.sendOTPResponse = null;
      state.verifyOTPResponse = null;
      // state.selectedAccountType=  null;
      // state.selectedCorporate= null;
    }
  }
})
  
  
export const getClientId = (state) => {
  try {
    return state.otp.verifyOTPResponse.client_id;
  } catch (error) {
    return null;
  }
}

export const getClientSecret = (state) => {
  try {
    return state.otp.verifyOTPResponse.client_secret;    
  } catch (error) {
    return null;
  }
}

export const getLoggedinUserId = (state) => {
  try {
    return state.otp.verifyOTPResponse.user.id;
  } catch (error) {
    return null;
  }
}


export const getSendOTPResponse = (state) => {
  if(state.otp != null && state.otp !== undefined){
    return state.otp.sendOTPResponse;
  }
  return null;
}

export const getVerifyOTPResponse = (state) => {

  if(state.otp != null && state.otp !== undefined){
    return state.otp.verifyOTPResponse;
  }
  return null;
} 

export const getOTPSentStatus = (state) => {

    try {
        return state.otp.sendOTPResponse.success;        
    } catch (error) {
        return false;        
    }
}

export const getOTPVerificationStatus = (state) => {
    if(state.otp != null && state.otp !== undefined && state.otp.verifyOTPResponse !== null){
      return state.otp.verifyOTPResponse.success;
    }
    return false;        
}

export const getSelectedAccountType = (state) => {

  try {
      return state.otp.selectedAccountType;        
  } catch (error) {
      return null;        
  }
}

export const getSelectedCorporate = (state) => {

  try { 
    return state.otp.selectedCorporate;        
  } catch (error) {
    console.log(error);
      return null;        
  }
}

export const getSubCorporateList = (state) => {
  try{
    const x = state.corporate.corporateList.find(corporate => corporate.id === state.otp.selectedCorporate.id);
    return x.sub_corporates;
    // state.corporate.corporateList.forEach(corporate => {
    //   if (corporate.id === state.otp.selectedCorporate.id){
    //     return corporate.sub_corporates;
    //   }
    // });
  }catch (error) {
    return [];
  }
}

export const getSelectedSubCorporate = (state) => {

  try { 
    return state.otp.selectedSubCorporate;        
  } catch (error) {
    console.log(error);
      return null;        
  }
}


export const getSelectedTableId = (state) => {

  try { 
    return state.otp.selectedTableId;        
  } catch (error) {
    console.log(error);
      return null;        
  }
}


export const getCorporateServiceList = (state) => {
  const serviceList = [];
  const todayDate = moment().format("YYYY-MM-DD");
  const selectedDate = moment(state.menu.selectedDate).format("YYYY-MM-DD");
  const today = moment().format("HH:mm:ss"); 
  try{
    const _serviceList = state.otp.selectedCorporate.services;
    for(let i=0; i<_serviceList.length; i+=1){
      const cutoffTime = _serviceList[i].cutoff_time;
      
      if (selectedDate === todayDate) {
        // console.log(cutoffTime);
        // console.log(today);
        if (cutoffTime > today){ 
          serviceList.push(_serviceList[i]);
        }
      }else{
        serviceList.push(_serviceList[i]);
      }
    }
    // console.log(serviceList.length);
    return serviceList;
  }catch(e){
    //
    console.log(e);
  }
  return [];
}

export const {  setSelectedAccountType, setSelectedCorporate, 
   setSendOTPResponse, setVerifyOTPResponse, setSelectedSubCorporate, setSelectedTableId,
  clearOTPState 
} = otpSlice.actions
export default otpSlice.reducer
