
export const HOME = '/menu';

export const LOGIN = '/login';

export const MENU = '/menu';
export const CART = '/cart';

export const PAYMENT = '/payment';

export const MY_ACCOUNT = '/my-account';

