import { createSlice } from '@reduxjs/toolkit';
import { formatDate } from '../../utils/date';
import {STATUS, PAYMENT_STATUS} from '../../constants/app_constants';

const feedbackSlice = createSlice({
  name: 'feedback',
  initialState: {
    feedbackfeedback: [],
    isFeedbackComponentVisible: false,
    corporateFeedbackList : null,
    selectedCorporateFeedback : null,
    feedbackDate : formatDate(Date()),
  },
  reducers: {
    setReviewItemList(state, action) {
      state.reviewItemList = action.payload.data;
    },
    setFeedbackComponentVisibility(state, action) {
        state.isFeedbackComponentVisible = action.payload.isVisible;
    },
    setCorporateFeedbackList(state, action) {
      state.corporateFeedbackList = action.payload.data;
    },
    setSelectedCorporateFeedback(state, action) {
      state.selectedCorporateFeedback = action.payload.data;
    },
    setFeedbackDate(state, action) {
      state.feedbackDate = action.payload.data;
    },
    resetCorporateState(state, action){
      state.reviewItemList = []; 
      state.isFeedbackComponentVisible = false;
      state.corporateFeedbackList = null;
      state.selectedCorporateFeedback = null;
      state.feedbackDate = formatDate(Date());
    }, 
  }
})

export const getReviewItemList = (state) => {

  if(state.feedback != null && state.feedback !== undefined){
    return state.feedback.feedback;
  }
  return [];
}

export const getFeedbackComponentVisibility = (state) => {

    if(state.feedback != null && state.feedback !== undefined){
      return state.feedback.isFeedbackComponentVisible;
    }
    return false;
}

export const getFeedbackDate = (state) => {

  if(state.feedback != null && state.feedback !== undefined){
    return state.feedback.feedbackDate;
  }
  return formatDate(Date());
}
 
export const getCorporateFeedbackList = (state) => {

  if(state.feedback != null && state.feedback !== undefined){
    return state.feedback.corporateFeedbackList;
  }
  return false;
}

export const getSelectedCorporateFeedback = (state) => {

  if(state.feedback != null && state.feedback !== undefined){
    return state.feedback.selectedCorporateFeedback;
  }
  return false;
}

export const getCorporateFeedbackServiceList = (state) => {

  if(state.feedback != null && state.feedback !== undefined 
    && state.feedback.corporateFeedbackList != null && state.feedback.corporateFeedbackList.data){
    const serviceList = [];
    state.feedback.corporateFeedbackList.data.forEach(element => {
        serviceList.push({
          'label': element.service_label,
          'value': element.service_id
        })
    });
    return serviceList;

  }
  return [];
}


export const { setFeedbackComponentVisibility, setFeedbackDate, feedbackfeedback, setCorporateFeedbackList, setSelectedCorporateFeedback } = feedbackSlice.actions

export default feedbackSlice.reducer
