import styled from 'styled-components';

// export const Box = styled('div')(({theme}) => ({
//   padding: '10px',
//   bottom: 0,
//   width: '100%',
//   borderRadius: '0!important',
// }));

// export const Container = styled('div')(({theme}) => ({
//   display: flex;
//   flexDirection: 'column';
//   justifyContent: 'center';
//   maxWidth: '1000px';
//   margin: '0 auto';
// }));

// export const Column = styled('div')(({theme}) => ({
//   display: flex;
//   flex-direction: column;
//   text-align: left;
//   margin-left: 20px;
// }));

// export const Row = styled('div')(({theme}) => ({
  
// }));

// export const FooterLink = styled('div')(({theme}) => ({
  
// }));

// export const Heading = styled('div')(({theme}) => ({
  
// }));   

export const Box = styled.div`
  padding: 10px; 
  bottom: 0;
  width: 100%; 
  border-radius: 0!important;
  @media (max-width: 900px) {
    // padding-bottom: 70px;
  }
`;
   
export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 1000px;
    margin: 0 auto;
    /* background: red; */
`
   
export const Column = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-left: 20px;
`;
   
export const Row = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, 
                         minmax(185px, 1fr));
  grid-gap: 20px;
   
  @media (max-width: 1000px) {
    grid-template-columns: repeat(auto-fill, 
                           minmax(200px, 1fr));
  }
`;
   
export const FooterLink = styled.a`
  color: #637381;
  font-size: 0.8rem ;
  text-decoration: none;
   
  &:hover {
      color: #141124;
      transition: 200ms ease-in;
  }
`;
   
export const Heading = styled.p`
  font-size: 24px;
  color: #fff;
  margin-bottom: 40px;
  font-weight: bold;
`;

export const Links = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  @media (max-width: 900px) {
    justify-content: center;
  }
`;
