import { createSlice } from '@reduxjs/toolkit'
import { forEach } from 'lodash';

const menuSlice = createSlice({
  name: 'menu',
  initialState: {
    menuList : [],
    filterList : [],
    selectedService : null,
    selectedDate : Date(),
    customizeItem: null,
    editingCartItemId : null,
  },
  reducers: {
    filterAdded(state, action) {
      const filter = action.payload;
      filter.id = `${filter.type  }_${  filter.value}`;

      // remove previous same type filter
      const filterTypeSearch = state.filterList.filter(item => item.type !== filter.type);
      state.filterList = filterTypeSearch; 
      state.filterList.push(filter);
    },
    filterRemoved(state, action) {
      const filter = action.payload;
      filter.id = `${filter.type}_${  filter.value}`;
      try{
        const filterList = [];
        state.filterList.forEach(item => { 
          if(item.id !== filter.id){ 
            filterList.push(item); 
          }
        });
        state.filterList = [];
        state.filterList = filterList;
      }catch(e){
        console.log(e);
      }
    },
    setMenu(state, action) {
      state.menuList = action.payload.data;
    },
    serviceSelected(state, action) {
      const service = action.payload;
      state.selectedService = service;
    },
    setSelectedDate(state, action) {
      state.selectedDate = action.payload;
    },
    setCustomizeItem(state,action) {
      try{
        let qty = 1;
        let addons = [];

        console.log(action.payload.item);

        try{
          if (action.payload.quantity && action.payload.quantity !== null) {
            qty = action.payload.quantity
          }
        }catch(e){
          console.log(e);
        }

        console.log(qty);

        try{
          if (action.payload.addons && action.payload.addons !== null) {
            addons = action.payload.addons
          }
        }catch(e){
          console.log(e);
        }

        state.customizeItem = {
          'item' : action.payload.item,
          'addon' : addons,
          'qty': qty,
        };
      }catch(e){
        console.log(e);
      };

      console.log("-----");
    },
    setCustomizeAddon(state,action) {
      try{
        let addons = [];

        try{
          if (action.payload.addons && action.payload.addons !== null) {
            addons = action.payload.addons
          }
        }catch(e){
          console.log(e);
        }

        state.customizeItem = {
          // 'item' : action.payload.item,
          'addon' : addons,
          // 'qty': qty,
        };
      }catch(e){
        console.log(e);
      };

      console.log("-----");
    },
    updateCustomizeItem(state, action){
      try{
        const addonList = []
        if (action.payload.addon !== undefined){
          if(state.customizeItem.addon !== undefined && state.customizeItem.addon.length > 0){ 
             state.customizeItem.addon.forEach(e => {
              if (e.addon.schedule_id === action.payload.addon.schedule_id) {
                if (action.payload.quantity > 0){
                  e.quantity = action.payload.quantity;
                  addonList.push(e)
                }else{
                  // remove the addon from the customize item
                  // state.customizeItem.addon.remove(e);
                  // skip
                }
              }else{
                addonList.push(e)
              }
            })

            state.customizeItem.addon = []
            state.customizeItem.addon = addonList
          }else if(action.payload.quantity > 0){
            state.customizeItem.addon.push({
              'addon' : action.payload.addon,
              'quantity' : action.payload.quantity
            })
          } 
        }
      }catch(e){
        console.log(e);
      }
    },
    setCustomizeCartItemId(state, action) {
      try{
        state.editingCartItemId = action.payload.cartItemId;
      }catch(e){
        console.log(e);        
      }
    },
    // addCustomizeItemAddon(state, action) {
    //   try{
    //     state.customizeItem.addon.push(action.payload.addon);
    //   }catch(e){
    //     console.log(e);
    //   }
    // },
    // removeCustomizeItemAddon(state, action) {
    //   try{
    //     console.log(action.payload.addon);
    //     const addonList = [];
    //     state.customizeItem.addon.forEach(e => { 
          
    //       if(e.schedule_id === action.payload.addon.schedule_id) {
    //         // state.customizeItem.addon.(e);
    //       }else{
    //         addonList.push(e);
    //       }
    //     });

    //     state.customizeItem.addon = [];
    //     state.customizeItem.addon = addonList;
    //   }catch(e){
    //     console.log(e);
    //   }
    // },
    increaseCustomizeItemQty(state, action) {
      try{
        state.customizeItem.qty += 1;
      }catch(e){
        console.log(e);
      }
    },
    decreaseCustomizeItemQty(state, action) {
      try{
        state.customizeItem.qty -= 1;
      }catch(e){
        console.log(e);
      }
    },
    resetCustomizeItem(state, action) {
      state.customizeItem = null;
      console.log("-reset--------------------------------");
    },
    resetEditingCartItemId(state, action) {
      state.editingCartItemId = null;     
    }
  }
})

export const getSelectedFilter = (state) => {
  const filters = {};
  if(state.menu.filterList && state.menu.filterList.length > 0){
    state.menu.filterList.forEach(e => {
      if(filters[e.type] === undefined){
        filters[e.type] = [];
      }
      filters[e.type].push(e.value); 
    });
//    return state.menu.filterList;
  }
  return filters;
}

export const getVegPreferenceFilter = (state) => {
  const veg = state.menu.filterList.find(e => e.id === 'preference_veg');
  if (veg !== undefined){
    return true;
  }
  // if(state.menu.filterList && state.menu.filterList.length > 0){
  //   state.menu.filterList.forEach(e => {
  //     if (e.type === 'preference'){
  //       if (e.value === 'veg'){
  //         console.log("veg selected");
  //         return true;
  //       }
  //     }
  //   });
  // }
  return false;
}

export const getCategoryList = (state) => {
  const categoryList = [];
  try{
    state.menu.menuList.forEach(element => {
        categoryList.push(element);
      });     
  }catch( exc){
    //        
  }
  return categoryList;
}

export const getSelectedService = (state) => {
  return state.menu.selectedService;
}

export const getSelectedDate = (state) => {
  return state.menu.selectedDate;
}

export const getItemList = (state) => {

  const itemList = [];

  const filterCategory = state.menu.filterList.filter(item => item.type === "category");
  const filterPreference = state.menu.filterList.filter(item => item.type === "preference");
    try{
        state.menu.menuList.forEach(category => {
            filterCategory.forEach(filter => {
                category.items.forEach(item => {
                  // item['category_name'] = category.category_name;
                  // item['category_display_name'] = category.category_display_name;
        
                  if(filter.type === "category" && category.category_name === filter.value){
                    if(filterPreference.length>0){
                        filterPreference.forEach(preference => {
                        if(preference.value === item.preference){
                          itemList.push({
                            ...item,
                            category_name: category.category_name,      
                            category_display_name: category.category_display_name       
                          });
                        }
                      })
                    }else{
                      itemList.push({
                        ...item,
                        category_name: category.category_name,      
                        category_display_name: category.category_display_name       
                      });
                    }
                  }else if(filter.type === "category" && filter.value === "all"){
                    itemList.push({
                      ...item,
                      category_name: category.category_name,      
                      category_display_name: category.category_display_name       
                    });
                  }
                });
            });
          });
          const uniqueItemList = [...new Map(itemList.map((item) => [item.schedule_id, item])).values()]; 
          return uniqueItemList;
    }catch(e){
      return itemList;
    }

 
}

export const getMenuList = (state) => {

  const dataList = [];

  // const filterCategory = state.menu.filterList.filter(item => item.type === "category");
  const filterPreference = state.menu.filterList.filter(item => item.type === "preference");
  const keywordPreference = state.menu.filterList.filter(item => item.type === "keyword");
    try{

      state.menu.menuList.forEach(vendor => {  
        const itemList = [];    
        
        vendor.menu.forEach(category => {

          let filteredItems = [];
        
          if(filterPreference.length > 0){
            filterPreference.forEach(preference => {
              if (preference.value === 'veg'){
                filteredItems = category.items.filter(x => x.is_non_veg === false);
              }else{
                filteredItems = category.items;
              }
            });
          }else{
            filteredItems = category.items;
          }

          if (keywordPreference && keywordPreference !== null && keywordPreference.length > 0){
            filteredItems = filteredItems.filter(x => (x.name.toString().toLowerCase().indexOf(keywordPreference[0].value.toString().toLowerCase()) !== -1));
          }
          itemList.push({
            'category' : category,
            // 'category_name' : category.category_name,
            // 'category_display_name' : category.category_display_name,
            'items' : filteredItems
          });

        });

        dataList.push({
          'vendor' : vendor.vendor,
          'menu': itemList
        })
      });

      return dataList;

    }catch(e){
      return dataList;
    } 
}

export const getMenuItem = (state, scheduleId) => {
  const menuList = [];
  try{
      state.menu.menuList.forEach(vendor => {  
        const itemList = [];    
        
        vendor.menu.forEach(category => {
          menuList.push(...category.items);
        });
      });
      const item = menuList.find(x => x.schedule_id === scheduleId);
      return item;
    }catch(e){
      console.log(e);
    }
    return null;
}

export const getCustomizationItem = (state) => {  
  try{
    return state.menu.customizeItem;
  }catch(e){
    console.log(e);
    return null;
  }
}

export const getCustomizationItemPrice = (state) => {
  let price = 0;
  try{
    if (state.menu.customizeItem != null && state.menu.customizeItem.addon != null && state.menu.customizeItem.item != null){
      price = state.menu.customizeItem.item.effective_price;
      state.menu.customizeItem.addon.forEach((item) => {
        price += item.addon.effective_price*item.quantity;
      })
      price *= state.menu.customizeItem.qty;
    }

  }catch(e){
    console.log(e);
  }
  return price;
}

export const getCustomizationItemAddonQty = (state) => {
  let qty = 0;
  try{
    if (state.menu.customizeItem != null && state.menu.customizeItem.addon != null){
      qty = state.menu.customizeItem.addon.length;
    }
  }catch(e){
    console.log(e);
  }
  return qty;
}

export const getCustomizationItemQty = (state) => {
  let qty = 1;
  try{
    if (state.menu.customizeItem != null){
      qty = state.menu.customizeItem.qty;
    }
  }catch(e){
    console.log(e);
  }
  return qty;
}

export const getEditingCartItemId = (state) => {
  return state.menu.editingCartItemId;
}

export const getNonCustomizationItemQty = (state) => {
  let qty = 0;

  try{
    if (state.menu.customizeItem && state.menu.customizeItem !== null && state.menu.customizeItem.item !== null){
      state.cart.cartItems.forEach(cartItem => {
        if(cartItem.schedule_id === state.menu.customizeItem.item.schedule_id) {
          // let matchedAddon = 0;
          // state.menu.customizeItem.addon.forEach(customizedAddon => {
          //     cartItem.addons.forEach(addon => {
          //         if (addon.schedule_id === customizedAddon.addon.schedule_id) {
          //           if(addon.quantity === customizedAddon.qty){
          //             matchedAddon += 1;
          //           }
          //         }
          //     });
          // });

          // console.log(matchedAddon);

          // if (matchedAddon !== state.menu.customizeItem.addon.length) {
            if (cartItem.cart_item_id !== state.menu.editingCartItemId){
              qty += cartItem.quantity;
            }
          // }
        }
      });
    }
  }catch(e){
    console.log(e);
  }
  

  return qty;
}

export const getSelectedFilterKeyword = (state) => {
  try{
    let value = '';
    state.menu.filterList.forEach((item) => {
      if (item.type === 'keyword') {
        value = item.value;
      }
    });

    return value;

  }catch(e) {
    console.log(e);

    return '';
  } 
}

export const { filterAdded, filterRemoved, setMenu, serviceSelected, setSelectedDate,
   setCustomizeItem,setCustomizeAddon, updateCustomizeItem, resetCustomizeItem, setCustomizeCartItemId,resetEditingCartItemId, 
   increaseCustomizeItemQty, decreaseCustomizeItemQty  } = menuSlice.actions
export default menuSlice.reducer