import {useState, useEffect} from 'react';
import { Box, Button, Card, Checkbox, Divider, Grid, MenuItem, Select, TextField, Typography, styled } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useCookies } from 'react-cookie';
import { useSearchParams } from 'react-router-dom';
import { addDays, subDays } from 'date-fns';


import TagFacesIcon from '@mui/icons-material/TagFaces';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import Page from "../components/Page";
import '../assets/css/Feedback1.css';
import { fetchCorporateFeedbackList, updateCorporateFeedback } from "../data/network/feedback/api";
import { getCorporateFeedbackList, getSelectedCorporateFeedback,getCorporateFeedbackServiceList, setSelectedCorporateFeedback } from "../store/feedback/reducer";
import Iconify from '../components/Iconify';
import {FEEDBACK_ITEMS, FEEDBACK_ITEM_OPTIONS} from "../constants/app_constants";
import { formatDate } from '../utils/date';

export const Feedback = () => {

    const SelectStyle = styled(Select)(() => ({
        // background: 'rgba(255, 255, 255, 0.5)',
        border: '1px solid #000',
        '& .MuiSelect-select': {
            padding: '8px 14px',
        }
    }));

    const [searchParams, setSearchParams] = useSearchParams();

    const dispatch = useDispatch();
    const [cookies, setCookie] = useCookies(['mobile','session', 'access_token']);

    const [corporateId, setCorporateId] = useState(null);
    const [corporateDetails, setCorporateDetails] = useState(null);
    const [feedbackData, setFeedbackData] = useState(null);
    const [selectedService, setSelectedService] = useState(null);
    const [userName, setUserName] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [selectedMeal, setSelectedMeal] = useState([]);
    const [selectedDate, setSelectedDate] = useState(Date());

    const feedbackList = useSelector(state => getCorporateFeedbackList(state));
    const selectedCorporateFeedback = useSelector(state => getSelectedCorporateFeedback(state));

    const serviceList = useSelector(state => getCorporateFeedbackServiceList(state));

    const handleSubmit = (title, rating) => {
        
        if (feedbackData != null){
            setFeedbackData({...feedbackData, [title]: rating});
        }else{
            setFeedbackData({[title]: rating});
        }
    }

    const handleFeedbackSubmit = () => {
        console.log('-------');

        if (selectedMeal.length === 0){
            const _feedbackData = ({
                ...feedbackData,
                'corporate_id': corporateId ,
                'user_details': {
                    'name' : userName,
                    'email' : userEmail,
                }
            })
            console.log(_feedbackData);
            updateCorporateFeedback(dispatch, cookies, _feedbackData);
        }else{
        
            selectedMeal.forEach(m => {
                const _feedbackData = ({
                    ...feedbackData,
                    'corporate_id': corporateId ,
                    'meal_id': m.meal_id,
                    'schedule_id': m.meal_schedule_id,
                    'user_details': {
                        'name' : userName,
                        'email' : userEmail,
                    }
                })
                console.log(_feedbackData);
                updateCorporateFeedback(dispatch, cookies, _feedbackData);
            })
        }

    }


    const handleServiceChange = (event) => {
        // console.log(event.target.value);
        setSelectedService(event.target.value);
        setSelectedMeal([]);

        if (feedbackList && feedbackList.data && feedbackList.data.length > 0) {
            // dispatch(setSelectedCorporateFeedback({data: feedbackList[0]}))
            feedbackList.data.forEach(element => {
                if(element.service_id === event.target.value) {
                    dispatch(setSelectedCorporateFeedback({data: element}))
                }
            });
        }

    }

    const handleMealChange = (event, _meal) => {

        const _selectedMeal = selectedMeal;

        if(event.target.checked){
            _selectedMeal.push(_meal);
            setSelectedMeal(_selectedMeal)
        }else{
            const newList = _selectedMeal.filter((item) => item.meal_id !== _meal.meal_id);
            setSelectedMeal(newList)
        }
    }

    useEffect(() => {
        if (feedbackList && feedbackList.data && feedbackList.data.length > 0) {
            setCorporateDetails(feedbackList.data[0].client_details);
            setSelectedService(feedbackList.data[0].service_id);
            dispatch(setSelectedCorporateFeedback({data: feedbackList.data[0]}));
        }
    },[feedbackList])

    useEffect(() => {
        if (selectedCorporateFeedback && selectedCorporateFeedback.menu_options && selectedCorporateFeedback.menu_options.length>0 ){
            setSelectedMeal(selectedCorporateFeedback.menu_options);
        }
    },[selectedCorporateFeedback])

    useEffect(() => {
        const _corporateId = searchParams.get("corporate_id");
        if (_corporateId != null) {
            setCorporateId(_corporateId);
            const _selectedDate = formatDate(selectedDate);
            fetchCorporateFeedbackList(dispatch, cookies, _corporateId, _selectedDate, _selectedDate);
        }
    },[])

    useEffect(() => {
        const _corporateId = searchParams.get("corporate_id");
        dispatch(setSelectedCorporateFeedback({'data': null}));
        setSelectedMeal([]);
        if (_corporateId != null) {
            setCorporateId(_corporateId);
            const _selectedDate = formatDate(selectedDate);
            fetchCorporateFeedbackList(dispatch, cookies, _corporateId, _selectedDate, _selectedDate);
        }
    },[selectedDate])

    return (
        <Page title={'Feedback'} sx={{minHeight: '100vh', display:'flex', justifyContent:'center'}}>
            <Grid className={'feedback-container'} container direction={'column'} justifyContent="center" alignItems={'center'} pt={4}>
                <Grid container direction={'column'} justifyContent={'center'} alignItems={'center'}>
                    <Typography variant="h3">{'Feedback'}</Typography>
                    <Typography variant="h5">{corporateDetails &&  corporateDetails.display_name}</Typography>
                    {/* <Typography variant="subtitle1">{corporateDetails &&  corporateDetails.city}</Typography> */}

                </Grid>

                <Grid container className="form-group">
                    <Grid container direction={'row'} justifyContent={'flex-end'}  alignItems={'center'} p={1} mt={2} sx={{borderRadius:'0!important'}}>
                        <ChevronLeftIcon onClick={() => setSelectedDate(subDays(new Date(selectedDate),1))} />
                        {/* <Iconify icon={'fa:'} sx={{fontSize: 24}} /> */}
                        <Typography variant="body1">{formatDate(selectedDate, "DD MMM YYYY")}</Typography>
                        {/* <Iconify icon={'fa:'} sx={{fontSize: 24}} /> */}
                        <ChevronRightIcon onClick={() => setSelectedDate(addDays(new Date(selectedDate),1))}/>
                        {/* <Typography variant="p">Please rate your experience:</Typography> */}
                    </Grid>                    

                    {
                        selectedCorporateFeedback && <Grid className={'glass-box'} container direction={'column'} p={1} mt={2} sx={{borderRadius:'0!important'}}>
                        
                        <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Typography variant="body1">Select service</Typography>
                            <SelectStyle
                                value={selectedService}
                                onChange={handleServiceChange}
                                inputProps={{ 'aria-label': 'Without label' }}
                                sx={{ width: 205 }}
                                name="service"
                            >
                                {
                                    serviceList && serviceList.map((e, i) => {
                                        return <MenuItem key={i} value={e.value}>{e.label}</MenuItem>
                                    })
                                }
                            </SelectStyle>
                        </Box>
                        </Grid>
                    }

                    {/* {
                        selectedCorporateFeedback === null && 
                        <Grid container justifyContent={'center'} alignItems={'center'}>
                            <Typography variant="body1">No service found for the date</Typography>
                        </Grid>
                    } */}

                    <Box margin={1} />
                    
                    {
                        selectedCorporateFeedback && selectedCorporateFeedback.menu_options && <Grid className={'glass-box'} p={2} sx={{borderRadius:'0!important'}}>
                            
                        {
                            selectedCorporateFeedback.menu_options.length>0 && selectedCorporateFeedback.menu_options.map((_meal,idx) => {
                                return <Grid container direction={'row'}>
                                    <Grid item>
                                        <Checkbox defaultChecked onChange={(event) => handleMealChange(event, _meal)} sx={{
                                            color: '#e86826',
                                            '&.Mui-checked': {
                                                color: '#e86826',
                                            },
                                        }} />
                                    </Grid>
                                    <Grid container item direction={'column'} maxWidth={'80%'}>
                                        <Typography variant="h5">{_meal.meal_details.display_name}</Typography>
                                        <Typography variant="subtitle2">{_meal.meal_details.description}</Typography>
                                    </Grid>
                                </Grid>
                            })
                        }
                    </Grid>
                    }
                    
                    {
                        <Grid>
                    
                        {
                            FEEDBACK_ITEMS && FEEDBACK_ITEMS.map((feedback, j) => {
                                return <Grid key={j} container p={1} mt={2}>
                                    <Typography variant="h6">{feedback.title}</Typography>

                                    <Grid container justifyContent={'space-around'} alignItems={'center'} className="button-group" mt={1}>
                                        {
                                            FEEDBACK_ITEM_OPTIONS && FEEDBACK_ITEM_OPTIONS.map(option => {
                                                let isSelected = false;
                                                if (feedbackData && feedbackData[feedback.key] && feedbackData[feedback.key] === option.value){
                                                    isSelected = true;
                                                }

                                                return <Button 
                                                key={option.key}
                                                className={`${option.category} glass-box ${isSelected && 'selected'}`} title={option.label}
                                                    onClick={(event) => handleSubmit(feedback.key, option.value)}>
                                                        <Grid container direction={'column'} justifyContent={'center'} alignItems={'center'}>
                                                            <Iconify icon={option.icon} size={'small'} sx={{fontSize: 18, color: 'grey', my:0.5}} />
                                                            <Typography variant="subtitle2">{option.label ?? ''}</Typography>
                                                        </Grid>
                                                </Button>
                                            })
                                        }
                                    </Grid>

                                    
                                </Grid>
                            })
                        }
                        {
                            <Grid container p={1} mt={2}>
                                <Typography variant="h6">{'What are the main reasons for your rating?'}</Typography>
                                <TextField className={'feedback-input'} multiline rows={3} name='feedback_description' id='feedback_description' 
                                defaultValue={feedbackData?.resolution} onChange={(event) => handleSubmit('feedback_description', event.target.value)} 
                                />
                            </Grid>
                        }
                        {
                            <Grid container p={1} mt={2}>
                                <Typography variant="h6">{'Your name'}</Typography>
                                <TextField className={'feedback-input'} name='name' id='name' 
                                defaultValue={userName} 
                                onChange={(event) => setUserName(event.target.value)} 
                                />
                            </Grid>
                        }
                        {
                            <Grid container p={1} mt={2}>
                                <Typography variant="h6">{'Your email address'}</Typography>
                                <TextField className={'feedback-input'} name='email' id='email'
                                defaultValue={`${userEmail}`}
                                onChange={(event) => setUserEmail(event.target.value)}
                                />
                            </Grid>
                        }
                        <Grid container justifyContent={'center'} alignItems={'center'} my={4} onClick={handleFeedbackSubmit}>
                            <Button className={'glass-box'} size={'large'} sx={{minWidth:'50vw'}} type="submit" variant="contained">
                                <Typography variant="h6" sx={{color:'#fff'}}>Save</Typography>
                            </Button>
                        </Grid>
                    </Grid>

                    }
                </Grid>
            </Grid>

        </Page>
    );
}
