import { faker } from '@faker-js/faker';
import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useCookies } from 'react-cookie';
import { io } from 'socket.io-client';

// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography, Box, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import useRazorpay from 'react-razorpay';
import QRCode from "react-qr-code";
import {isMobile} from 'react-device-detect';
// components
import Page from '../components/Page';
import Iconify from '../components/Iconify';

import  '../assets/css/Menu.css';

// sections  
import { getSelectedAccountType, getSelectedCorporate, getSelectedSubCorporate, getSelectedTableId } from '../store/otp/reducer';
import { ACCOUNT_TYPE, PAYMENT_GATEWAY, PAYMENT_MODE, PAYMENT_STATUS, RAZORPAY_KEY, USER_PAYMENT_TYPE } from '../constants/app_constants';
import { getDeliveryInstruction, getDeliveryPreference, getGrandTotal, getSocketUrl } from '../store/cart/reducer';
import { addCorporateTransaction, checkPhonepePaymentStatus, confirmCorporateOrder, generateCorporateOrderId } from '../data/network/order/api';
import { getPaymentId, setPaymentId, setPaymentAck, getPaymentStatus, 
    getPaymentMode, getOrderDetails, setPaymentStatus, getAddTransactionDetails , resetPaymentState, getPaymentGateway, getPaymentStatusCheckCount, setPaymentGateway
} from '../store/payment/reducer'; 
 
import { ReactComponent as Loader } from "../assets/img/loader.svg";
import FailedIcon from "../assets/img/fail.png";
import SuccessIcon from "../assets/img/success.png";
import { getActiveCorporateClient, getLoginStatus, getUser, getUserCorporatePaymentType } from '../store/user/reducer';
import { getClientPaymentMode } from '../store/client/reducer';
import { SSE_URL } from '../data/network/endpoints';

// ----------------------------------------------------------------------

export default function Payment() {
    const theme = useTheme();

    const navigate = useNavigate();

    const dispatch = useDispatch();
    const [cookies, setCookie] = useCookies(['mobile','session', 'access_token']);
    const selectedAccountType = useSelector(state => getSelectedAccountType(state));
    const selectedCorporate = useSelector(state => getSelectedCorporate(state));
    const grandTotal = useSelector(state => getGrandTotal(state));  
    const paymentMode = useSelector(state => getPaymentMode(state));
    const userDetails = useSelector(state => getUser(state));
    const userPaymentType = useSelector(state => getUserCorporatePaymentType(state));

    const paymentStatus = useSelector(state => getPaymentStatus(state));

    const orderDetails = useSelector(state => getOrderDetails(state)); 
    const [orderIdGenerated, setOrderIDGenerated] = useState(false);
    const addTransactionDetails = useSelector(state => getAddTransactionDetails(state))
    const paymentId = useSelector(state => getPaymentId(state));  
    const isLoggedIn = useSelector(state => getLoginStatus(state));
    const paymentGateway = useSelector(state => getPaymentGateway(state));

    const tableId = useSelector(state => getSelectedTableId(state));
    const subCorporate = useSelector(state => getSelectedSubCorporate(state));
    const checkAttempt = useSelector(state => getPaymentStatusCheckCount(state));

    const selectedDeliveryPreference = useSelector(state => getDeliveryPreference(state));
    const selectedDeliveryInstruction = useSelector(state => getDeliveryInstruction(state));

    const socketUrl = useSelector(state => getSocketUrl(state));

    const Razorpay = useRazorpay();

    const upiTriggerRef = useRef(null);

    useEffect(() => {
        if(!isLoggedIn){        
            dispatch(resetPaymentState());
            navigate('/');
        }
    },[isLoggedIn])
  
    useEffect(() => {
        if(!orderIdGenerated){ 
            // dispatch(resetPaymentState());
            setOrderIDGenerated(true);

            if(selectedAccountType === ACCOUNT_TYPE.CORPORATE){
                let selectedPaymentMode = "PRE_PAID";
                if(paymentMode === PAYMENT_MODE.OFFLINE){
                    selectedPaymentMode = "POST_PAID";
                }

                if (userPaymentType === USER_PAYMENT_TYPE.CORPORATE_PAID){
                    selectedPaymentMode = USER_PAYMENT_TYPE.CORPORATE_PAID;
                }

                const paymentRequestData = {
                    'payment_mode': selectedPaymentMode,
                    'amount': grandTotal
                }

                if (selectedPaymentMode === 'PRE_PAID'){
                    // TODO : Update in future in case of multiple payment gateways
                    // Currently defaulted to Razorpay

                    
                    // if(paymentGateway === PAYMENT_GATEWAY.PHONEPE.name){
                    //     paymentRequestData.payment_gateway = PAYMENT_GATEWAY.PHONEPE.name;
                    // }else{
                    //     paymentRequestData.payment_gateway = PAYMENT_GATEWAY.RAZORPAY.name.toUpperCase();
                    // }

                    paymentRequestData.payment_gateway = PAYMENT_GATEWAY.RAZORPAY.name.toUpperCase();

                }

                console.log(paymentRequestData);

                generateCorporateOrderId(dispatch, cookies, paymentRequestData);
            }
        }
    },[orderIdGenerated]) 

    useEffect(() => {
        if(paymentStatus === PAYMENT_STATUS.CONFIRMED){ 
            const internalOrderId = orderDetails.order_id;
            dispatch(resetPaymentState());
            
            setTimeout(
                navigate(`/order/receipt/${internalOrderId}`), 1500
            );
        }
    },[paymentStatus])

    // useEffect(() => {
    //     try{
    //         console.log("---------");
    //         if (socketUrl !== null ){
    //             console.log(socketUrl);
    //             console.log('ok');
    //             const socket1 = io(socketUrl, {
    //                 autoConnect: true
    //             });

    //             console.log(socket1);
    //         }
    //     }catch(e){
    //         console.log(e);
    //     }
    // },[socketUrl])

    useEffect(() => {
        if(orderIdGenerated && orderDetails != null && orderDetails.order_id != null){
            dispatch(setPaymentStatus({status: PAYMENT_STATUS.PENDING}));
            if (userPaymentType?.toUpperCase() === USER_PAYMENT_TYPE.USER_PAID.toUpperCase()){
                if(paymentMode?.toUpperCase() === PAYMENT_MODE.ONLINE.toUpperCase()){
                    if(paymentGateway === PAYMENT_GATEWAY.PHONEPE.name){
                        if(socketUrl !== null){
                            try{
                                // const url = `${socketUrl}${orderDetails.transaction_id}_${orderDetails.order_id}`;
                                // const namespace = `/${orderDetails.transaction_id}_${orderDetails.order_id}`;
                                const url = `${socketUrl}`;

                                const socket = io(url, {
                                    autoConnect: true
                                });

                                // io.of(namespace).on("connection", (socket) => {
                                //     console.log(socket);

                                //     socket.on("test", (x) => {console.log(x);});
                                //     socket.on("order:create", () => {});
                                // });

                                const eventName = `payment_status_update_${orderDetails.transaction_id}_${orderDetails.order_id}`;
                                // const eventName = `payment_status_update`;

                                socket.on(eventName, (data) => {
                                    console.log('status update');
                                    console.log(data);
                                    const status = data.status;
                                    console.log(socket.id); // x8WIv7-mJelg7on_ALbx

                                    if (status === "SUCCESS"){
                                        socket.disconnect();
                                        callConfirmCorporateOrder();
                                    }else if (status === "FAILED") {
                                        socket.disconnect();
                                        dispatch(setPaymentStatus({status: PAYMENT_STATUS.FAILED}))
                                    }
                                });

                                socket.on('test' , (x) => {
                                    console.log('test event received');
                                    console.log(x);
                                })

                            }catch(e){
                                console.log(e);
                            }
                        }
                    }
                    handleOnlinePayment();
                }else{
                    handleCashPayment();
                }
            }else if(userPaymentType?.toUpperCase() === USER_PAYMENT_TYPE.CORPORATE_PAID.toUpperCase()){
                handleCompanyPayment();
            }
        }else{
            // 
        }
    },[orderDetails]);


    // useEffect(() => {
    //     if(orderIdGenerated && paymentStatus === PAYMENT_STATUS.PENDING && paymentMode === PAYMENT_MODE.ONLINE){
    //         if (checkAttempt < 15) {
    //             let paymentModeId = 2;
    //             if (userPaymentType === USER_PAYMENT_TYPE.CORPORATE_PAID) {
    //                 paymentModeId = 1;
    //             }

    //             const postData = {
    //                 "payment_mode_id": paymentModeId,
    //                 "corporate_id": selectedCorporate ? selectedCorporate.id : "", 
    //                 "order_id": orderDetails.order_id, 
    //                 "transaction_id": orderDetails.transaction_id,
    //                 "order_amount": grandTotal,
    //                 "grand_total": grandTotal,
    //                 "sub_corporate_id": selectedCorporate ? selectedCorporate.id : "", 
    //             }

    //             if(tableId !== null){
    //                 postData.table_no = tableId;            
    //             }

    //             if (subCorporate && subCorporate !== null){
    //                 postData.sub_corporate_id = subCorporate.id;
    //             }else{
    //                 postData.sub_corporate_id = selectedCorporate.id
    //             }
    //         }else{
    //             dispatch(setPaymentStatus({status: PAYMENT_STATUS.FAILED}));
    //         }
    //     }else{
    //         console.log("Pay by cash");
    //     }
    // },[paymentStatus, checkAttempt]);

    const handleCompanyPayment = () => {
        addCorporateTransaction(dispatch, cookies, {
            "type": "DEBIT",
            "order_id": orderDetails.order_id,
            "transaction_id": `${orderDetails.order_id}_CORPORATE`,
            "order_type": "corporate",
            "status": "SUCCESS",
            "mode_of_transaction": "POST_PAID",
            "amount": grandTotal,
        });
    }

    const handleOnlinePayment = ( ) => {
        // alert(paymentGateway);
        if(paymentGateway === PAYMENT_GATEWAY.RAZORPAY.name){
            openRazorpay();
        }else if(paymentGateway === PAYMENT_GATEWAY.PAYTM.name){
            // openPaytm();
        }else if(paymentGateway === PAYMENT_GATEWAY.PHONEPE.name){
            openPhonepe();
        }
    }

    const handleCashPayment = () => {
        addCorporateTransaction(dispatch, cookies, {
            "type": "DEBIT",
            "order_id": orderDetails.order_id,
            "transaction_id": orderDetails.transaction_id,
            "order_type": "corporate",
            "payment_id": null,
            "transaction_signature": null,
            "status": "SUCCESS",
            "mode_of_transaction": "POST_PAID",
            "amount": grandTotal,
        });
    }

    const openRazorpay  = ( ) => {

        const options = {
          key: {RAZORPAY_KEY},
          amount: grandTotal*100,
          currency: "INR",
          name: "The Bytes",
          description: "",
          image: "",
          order_id: orderDetails.transaction_id,
          // order_id: orderId,
          handler: (res) => { 
            console.log(res);
    
            if(res.razorpay_payment_id != null){
              dispatch(setPaymentId({data: res.razorpay_payment_id}));
              // setPaymentId(res.razorpay_payment_id);
              processOnlineTxn(orderDetails.order_id, orderDetails.transaction_id, res.razorpay_payment_id, res.razorpay_signature);
            } 
          },
          prefill: {
            name: userDetails.first_name != null ? userDetails.first_name : "User",
            email: userDetails.email != null ? userDetails.email : "support@the-bytes.com",
            contact: cookies.mobile,
          },
          notes: {
            address: "",
          },
          theme: {
            color: "#3399cc",
          },
        }; 
    
        const rzpay = new Razorpay(options);
        rzpay.on("payment.failed", (response) => { 

            dispatch(setPaymentStatus({status: PAYMENT_STATUS.FAILED}));

            dispatch(setPaymentAck({data: {'status' : "error", 'order_id' : orderDetails.order_id, 'transaction_id' : orderDetails.transaction_id, 'amount': grandTotal}}));
    
        });
    
       rzpay.open();
    };
    
    const openPaytm = () => {

    };

    const openPhonepe = () => {
        // handle action uri
        const actionUri = orderDetails.action_urls.action_url;
        console.log(actionUri);
        console.log(isMobile);
        if (isMobile){
            window.open(actionUri);
        // }else{
        //     window.open(actionUri);
        }
    };

    const processOnlineTxn = async (orderId, transactionId, paymentId, paymentSignature) => {
        // console.log(paymentSignature);
        addCorporateTransaction(dispatch, cookies, {
            "type": "DEBIT",
            "order_id": orderId,
            "transaction_id": transactionId,
            "order_type": "corporate",
            "payment_id": paymentId,
            "transaction_signature": paymentSignature,
            "status": "SUCCESS",
            "mode_of_transaction": "PRE_PAID",
            "amount": grandTotal,
        });

        // dispatch(setPaymentStatus({status: PAYMENT_STATUS.SUCCESS}));
    };

    const callConfirmCorporateOrder = ( ) => {
        let paymentModeId = 2;
        if (userPaymentType === USER_PAYMENT_TYPE.CORPORATE_PAID) {
            paymentModeId = 1;
        }

        const postData = {
            "payment_mode_id": paymentModeId,
            "corporate_id": selectedCorporate ? selectedCorporate.id : "", 
            "sub_corporate_id": subCorporate ? subCorporate.id : "", 
            "order_id": orderDetails.order_id, 
            "transaction_id": orderDetails.transaction_id,
            "order_amount": grandTotal,
            "grand_total": grandTotal,
            "delivery_preference" : selectedDeliveryPreference,
            "delivery_instruction" : selectedDeliveryInstruction
        }

        if(tableId !== null){
            postData.table_no = tableId;            
        }

        postData.sub_corporate_id = subCorporate?.id;

        confirmCorporateOrder(dispatch, cookies, postData); 
    };

    const handleRetryPayment = () => {
        setOrderIDGenerated(false);
        dispatch(setPaymentStatus({status: PAYMENT_STATUS.PENDING}));
    }

    const handleCancelPayment = () => { 
        dispatch(setPaymentStatus({status: PAYMENT_STATUS.FAILED}));
    }

    useEffect(() => {
        if(addTransactionDetails != null){
            console.log(paymentMode);
            if(paymentMode === PAYMENT_MODE.OFFLINE){
                callConfirmCorporateOrder( );
            }else{ 
                callConfirmCorporateOrder()
                // listen to sse 

// SSE Frontend:
// ---------------
// - Page and different icon loader (In the kitchen -> Requesting Chef - > Confirming Order ) Time Buy Strategy in case any delay in API


//                 const source = new EventSource(SSE_URL);
//                 const eventId = `${orderDetails.transaction_id}_${orderDetails.order_id}_sse`;
//                 console.log(eventId);
//                 source.addEventListener(eventId, (event) => {
//                     const data = JSON.parse(event.data);
//                     console.log(data.status);
//                     if (data.status === 'SUCCESS') {
// //                         Call Confirm Corporate Order
//                         callConfirmCorporateOrder()
//                     }else if(data.status === 'FAILURE') {
//                         dispatch(setPaymentStatus({'status': PAYMENT_STATUS.FAILED}));                           
//                     }
//                 }, false);
//                 source.addEventListener('error', (event) => {
//                     console.log(event);
// //                    Recursive try to connect
//                 }, false);

            }
        }
    },[addTransactionDetails]);
    

    return (
        <Page title="Payment">
            <div>
                {
                    paymentStatus === PAYMENT_STATUS.INITIAL && 
                    <div>
                        <Box padding={1} />
                        <Loader />
                        <Box padding={1} />
                        <Grid container justifyContent="center" alignItems="center" direction="column">
                            <div>We are awaiting confirmation of your payment.</div>
                            <Box padding={1} />
                            <div>Please do not go back or refresh the page</div>
                        </Grid>
                        <Grid  justifyContent="center" alignItems="center" container>
                            <Box padding={2} style={{width:'30%'}}>
                                <Button fullWidth size="large" onClick={() => handleCancelPayment()} variant="contained" >
                                    Cancel
                                </Button> 
                            </Box>
                        </Grid>
                    </div>
                }
                {
                    paymentStatus?.toUpperCase() === PAYMENT_STATUS.PENDING.toUpperCase() && paymentMode?.toUpperCase() === PAYMENT_MODE.ONLINE.toUpperCase() &&

                        <div>
                            <Box padding={1} />
                            <Loader />
                            <Box padding={1} />
                            
                            {orderDetails && paymentGateway === PAYMENT_GATEWAY.PHONEPE.name && 
                            <Grid container justifyContent="center" alignItems="center" direction="column" style={{marginBottom: '40px'}}>
                                {!isMobile && <span style={{marginLeft: '20%', marginRight: '20%', textAlign:'center'}}>Scan the QR code using any UPI app to make payment</span>}
                                <div style={{ height: "auto", margin: "50 auto", maxWidth:'200px', width: "50%" }}>
                                    <QRCode
                                        size={512}
                                        style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                        value={orderDetails.action_urls.action_url}
                                        viewBox={`0 0 512 512`}
                                    />
                                </div>
                                {isMobile && <a style={{marginLeft: '20%', marginRight: '20%', textAlign:'center'}} 
                                    href={orderDetails.action_urls.action_url}
                                    ref={upiTriggerRef}  
                                >Click here if UPI payment is not visible in a few moments</a>
                                }
                            </Grid>}

                            <Grid container justifyContent="center" alignItems="center" direction="column">
                                <div>We are awaiting confirmation of your payment.</div>
                                <Box padding={1} />
                                <div>Please do not go back or refresh the page</div>
                            </Grid>
                            <Grid  justifyContent="center" alignItems="center" container>
                                <Box padding={2} style={{width:'30%'}}>
                                    <Button fullWidth size="large" onClick={() => handleCancelPayment()} variant="contained" >
                                        Cancel
                                    </Button> 
                                </Box>
                            </Grid>
                        </div>
                }
                {
                    // paymentStatus === PAYMENT_STATUS.PENDING && paymentMode === PAYMENT_MODE.OFFLINE &&

                    //     <div>
                    //         <Box padding={1} />

                    //         <Grid container justifyContent="center" alignItems="center" direction="column">
                                
                    //             <Grid container justifyContent="start" alignItems="start" direction="column">
                    //                 <Box padding={3}>
                    //                     <h3>Have you collected the final amount?</h3>
                    //                 </Box>
                    //             </Grid>
                    //             <Box padding={2} />
                    //             <Grid  justifyContent="center" alignItems="center" container direction="row">
                    //                 <Box padding={2} style={{width:'40%'}}>
                    //                     <Button fullWidth size="large" onClick={() => handleOfflinePaymentRejection()} variant="contained" loading={isSubmitting}>
                    //                         No, Cancel order
                    //                     </Button> 
                    //                 </Box>
                    //                 <Box padding={2} style={{width:'55%'}}>
                    //                     <Button fullWidth size="large" onClick={() => handleOfflinePaymentConfirmation()} variant="contained" loading={isSubmitting}>
                    //                         Yes, I have collected the amount
                    //                     </Button>
                    //                 </Box>
                    //             </Grid>
                    //         </Grid>
                    //     </div>
                }
                {
                    paymentStatus === PAYMENT_STATUS.CANCELLED && 
                    <div>
                        <Box padding={1} />
                        <Grid container justifyContent="center" alignItems="center" direction="column">
                            <img src={FailedIcon} alt="order-fail" height="100" width="100" />
                        </Grid>
                        <Box padding={1} />
                        <Grid container justifyContent="center" alignItems="center" direction="column">
                            <div>Your payment was cancelled.</div>
                        </Grid>
                        <Box padding={1} />
                        <Grid  justifyContent="center" alignItems="center" container>
                            <Box padding={2} style={{width:'30%'}}>
                                <Button fullWidth size="large" onClick={() => handleRetryPayment()} variant="contained" >
                                    Retry
                                </Button> 
                            </Box>
                            <Box padding={2} style={{width:'30%'}}>
                                <Button fullWidth size="large" onClick={() => navigate('/cart')} variant="contained" >
                                    Go Back
                                </Button> 
                            </Box>
                        </Grid>
                    </div>
                }
                {
                    paymentStatus === PAYMENT_STATUS.FAILED && 
                    <div>
                        <Box padding={1} />
                        <Grid container justifyContent="center" alignItems="center" direction="column">
                            <img src={FailedIcon} alt="order-fail" height="100" width="100" />
                        </Grid>
                        <Box padding={3} />
                        <Grid container justifyContent="center" alignItems="center" direction="column">
                            <div>Your payment was failed. Any amount deducted will be credited back to your source account within 3-5 days.</div>
                        </Grid>
                        <Box padding={1} />
                        <Grid  justifyContent="center" alignItems="center" container>
                            <Box padding={2} style={{width:'30%'}}>
                                <Button fullWidth size="large" onClick={() => handleRetryPayment()} variant="contained" >
                                    Retry
                                </Button> 
                            </Box>
                            <Box padding={2} style={{width:'30%'}}>
                                <Button fullWidth size="large" onClick={() => navigate('/cart')} variant="contained" >
                                    Go Back
                                </Button> 
                            </Box>
                        </Grid>
                    </div>
                }
                {
                    paymentStatus === PAYMENT_STATUS.CONFIRMED && 
                    <div>
                        <Box padding={1} />
                        <img src={SuccessIcon} height="100" width="100" alt="order-success" />
                        <Box padding={1} />
                        <Grid container justifyContent="center" alignItems="center" direction="column">
                            <div>Your payment was successful. Please wait while we create your order and navigate you to next step.</div>
                            <Box padding={1} />
                            <div>This might take a few seconds...</div>
                        </Grid>
                    </div>
                }
            </div>
        </Page>
    );
}
