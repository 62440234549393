import { setLoadingStatus } from '../../../store/layout/reducer';
import { setMenu } from '../../../store/menu/reducer';
import axios from '../../../utils/axios-configure';
import { API_FETCH_MENU } from "../endpoints";
import {getAccessTokenFromCookie} from '../session/api'

export const fetchMenu = (dispatch, cookies, data) => {
    dispatch(setLoadingStatus({status: true}));  

    const  accessToken = getAccessTokenFromCookie(dispatch, cookies ); 
    const {org, date, serviceId} = data;
    
    if(accessToken != null){
        axios({
            method: "get",
            url: `${API_FETCH_MENU}?org=${org}&date=${date}`,
            headers: { "Content-Type": "application/json",'Authorization': `Bearer ${ accessToken}` },
          })
          .then((response) => {
            // handle success
            if(response.status === 200){
              dispatch(setMenu({data: response.data.data}));
              dispatch(setLoadingStatus({status: false}));  
            }else{
              dispatch(setMenu({data: null}));
            } 
            dispatch(setLoadingStatus({status: false}));   
          })
          .catch((err) => {
            dispatch(setMenu({data: null}));
            dispatch(setLoadingStatus({status: false}));  
          });
    }else{
      dispatch(setMenu({data: null}));
      dispatch(setLoadingStatus({status: false}));  
    }
}
 