import * as Yup from 'yup';
import { useState } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import { useNavigate } from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import { useCookies } from 'react-cookie';
// material
import { Stack,Box, TextField, IconButton, InputAdornment, Grid } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// component
import Iconify from '../../components/Iconify';
import {getUser} from '../../store/user/reducer';
import { saveQuery } from '../../data/network/query/api';

// ----------------------------------------------------------------------

export default function ContactForm() {
  const [cookies, setCookie] = useCookies(['mobile','session', 'access_token']);
  const dispatch = useDispatch();
  
  const navigate = useNavigate(); 
  const user = useSelector(state => getUser(state));

  const ContactSchema = Yup.object().shape({
    firstName: Yup.string() ,
    lastName: Yup.string() ,
    email: Yup.string().email('Email must be a valid email address'),
    comment: Yup.string().required('Please enter your query')
  });

  const formik = useFormik({
    initialValues: {
      firstName: user.first_name ,
      lastName: user.last_name ,
      email: user.email,
      comment: '',
    },
    validationSchema: ContactSchema,
    onSubmit: () => {
    //   navigate('/dashboard', { replace: true });
    },
  }); 

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  const handleContactSave = () =>{
    if(formik.values.comment !== null && formik.values.firstName !== null && formik.values.lastName !== null && formik.values.comment !== null){ 
      saveQuery(dispatch, cookies, {
          first_name: formik.values.firstName,
          last_name: formik.values.lastName,
          email: formik.values.email,
          query: formik.values.comment ,
      });

      setTimeout( 
        () => formik.resetForm(), 
        500
      );
    }
  }

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Grid  justifyContent="center" direction="row">
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              label="First name"
              {...getFieldProps('firstName')}
              error={Boolean(touched.firstName && errors.firstName)}
              helperText={touched.firstName && errors.firstName}
            />

            <TextField
              fullWidth
              label="Last name"
              {...getFieldProps('lastName')}
              error={Boolean(touched.lastName && errors.lastName)}
              helperText={touched.lastName && errors.lastName}
            />
          </Stack>
            <Box margin={2} />
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
                fullWidth
                autoComplete="username"
                type="email"
                label="Email address"
                {...getFieldProps('email')}
                error={Boolean(touched.email && errors.email)}
                helperText={touched.email && errors.email}
            />
             

           </Stack>

           <Box margin={2} />
           <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
                fullWidth
                type="text"
                label="Comment"
                {...getFieldProps('comment')}
                error={Boolean(touched.comment && errors.comment)}
                helperText={touched.comment && errors.comment}
            />
            
           </Stack>
           <Box margin={2} />

           <Stack direction={{ xs: 'column', sm: 'row' }} spacing={3} justifyContent="center">
             
            <LoadingButton size="large" type="submit" variant="contained" onClick={handleContactSave}>
                Save
            </LoadingButton>
          </Stack>
        </Grid>
      </Form>
    </FormikProvider>
  );
}
