import { useEffect } from 'react';
import { useSearchParams, Link as RouterLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useCookies } from 'react-cookie';
// material
import { Grid, Button, Container, Stack, Typography } from '@mui/material';
// components
import Page from '../components/Page';
import Iconify from '../components/Iconify';
import { BlogPostCard, BlogPostsSort, BlogPostsSearch } from '../sections/@dashboard/blog';
// mock
import POSTS from '../_mock/blog';
import { fetchUserCorporateMeal } from '../data/network/user/api';
import { getUserCorporateMeal } from '../store/user/reducer';
import { formatDate } from '../utils/date';
import { CorporateMealDetail } from '../sections/corporate_meal_detail';

// ----------------------------------------------------------------------

const SORT_OPTIONS = [
  { value: 'latest', label: 'Latest' },
  { value: 'popular', label: 'Popular' },
  { value: 'oldest', label: 'Oldest' },
];

// ----------------------------------------------------------------------

export default function MyCorporateMealDetail() {

    const dispatch = useDispatch();
    const [cookies, setCookie, removeCookie] = useCookies(['mobile','session', 'access_token']);

    const userCorporateMeals = useSelector(state => getUserCorporateMeal(state));
    const [searchParams, setSearchParams] = useSearchParams();
    const startDate = searchParams.get("start_date")
    const endDate = searchParams.get("end_date")
    const serviceTypeId = searchParams.get("service_type_id")

    useEffect(() => {
        fetchUserCorporateMeal(dispatch, cookies, {
            "start_date" : formatDate(startDate),
            "end_date" : formatDate(endDate),
            "service_type_id" : serviceTypeId, 
        });
    },[]);

    return (
        <Page title="Corporate Meal Detail ">
        <Container>
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography variant="h4" gutterBottom>
                Meal Detail
            </Typography>
            {/* <Button variant="contained" component={RouterLink} to="#" startIcon={<Iconify icon="eva:arrow-down" />}>
                Download
            </Button> */}
            </Stack>

            <CorporateMealDetail />
        </Container>
        </Page>
    );
}
