import { Box, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {motion} from 'framer-motion';
import { useCookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";
import { useRef, useState } from "react";
import { BrowserView, MobileView } from "react-device-detect";


import { MenuList, OrderSummary } from "../sections/menu";
import Navbar from "../components/Navbar";
import Page from "../components/Page";
import Iconify from "../components/Iconify";
import { getCartItems, getGrandTotal, isCartValid, setDeliveryInstruction, setDeliveryPreference } from "../store/cart/reducer";
import { getUserCorporatePaymentType } from "../store/user/reducer";
import { getPaymentMode, resetPaymentState, setPaymentGateway, setPaymentMode, setPaymentStatus } from "../store/payment/reducer";
import { CART_ERROR } from "../constants/message_constants";
import { PAYMENT_GATEWAY, PAYMENT_MODE, PAYMENT_STATUS, USER_PAYMENT_TYPE } from "../constants/app_constants";
import { fetchCart } from "../data/network/cart/api";
import { setSnackbarMessage, setSnackbarVisibilityStatus } from "../store/layout/reducer";
import { getSelectedCorporate } from "../store/otp/reducer";

export default function Cart() {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const paymentRef = useRef();

    const [cookies, setCookie] = useCookies(['mobile','session', 'access_token']);
    const cartItems = useSelector(state => getCartItems(state));
    const grandTotal = useSelector(state => getGrandTotal(state));  
    const isValidCart = useSelector(state => isCartValid(state));
    const userPaymentType = useSelector(state => getUserCorporatePaymentType(state));
    const selectedPaymentMode = useSelector(state => getPaymentMode(state));
    const selectedCorporate = useSelector(state => getSelectedCorporate(state));


    // TODO : update the below 2 values
    const [selectedDeliveryPreference, setSelectedDeliveryPreference] = useState('desk');
    const [deliveryInstruction, setEnteredDeliveryInstruction] = useState('');

    const processOrder = () =>{
        dispatch(resetPaymentState());
        fetchCart(dispatch, cookies, {
            'corporateId': selectedCorporate?.id
        }); 
        if(isValidCart){
            try{
                dispatch(setPaymentStatus({'status' : PAYMENT_STATUS.PENDING}));

                // dispatch(setPaymentMode({'mode' : selectedPaymentMode}));
                // console.log(selectedPaymentMode);
                // if (selectedPaymentMode.toUpperCase() === PAYMENT_MODE.ONLINE.toUpperCase()){
                //     dispatch(setPaymentGateway({'gateway' : PAYMENT_GATEWAY.RAZORPAY.name}));        
                // } 

                
                dispatch(setDeliveryPreference({'preference' : selectedDeliveryPreference})); 
                // console.log(deliveryInstruction);
                dispatch(setDeliveryInstruction({'instruction' : deliveryInstruction})); 
                navigate('/payment/');
            }catch(e){
                console.log(e);
            }
        }else{
            // display Message
            displayMessage("error", CART_ERROR.NOT_SERVICABLE);
        }
    };

    const displayMessage = (type, message) => { 
        dispatch(setSnackbarMessage( {'message' : message, 'severity' : type})); 
        dispatch(setSnackbarVisibilityStatus( {'status' : true})); 
    }


    return (
        <Page title="Cart" >
            <BrowserView>
                <Grid container direction={'row'} justifyContent={'center'} maxWidth={'60%'} alignItems={'flex-start'} sx={{'width': '100%'}}>
                    <Grid container justifyContent={'space-evenly'} marginTop={4} paddingTop={4}  columnGap={0.5} flexDirection={'row'} alignItems={'flex-start'}>
                        <Grid className="flexbox-wrapper" item lg={12} sm={12} md={12} xl={12} sx={{overflowY:'scroll'}}>
                            {/* <OrderSummary /> */}

                            <Grid container className="header" flexDirection={'row'} justifyContent={'flex-start'}>
                                <Grid container item sm={3} md={3} xs={3} lg={3} xl={3}
                                    padding={1.5} 
                                    flexDirection={'row'} 
                                    alignItems={'center'} 
                                    justifyContent={'flex-start'} 
                                    onClick={() => {
                                        navigate('/menu');
                                    }}>
                                        <Iconify icon={'eva:chevron-left-outline'} sx={{width: 32, height: 32, color: '#fff'}} />
                                </Grid>

                                <Grid container item justifyContent={'center'} alignItems={'center'}  sm={6} md={6} xs={6} lg={6} xl={6}>
                                    <Typography variant={'h4'} sx={{color:'#fff'}}>
                                        {"Order Summary"}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid className='order-summary-box' columnGap={2} paddingBottom={10}>
                        
                                <Grid item container xs={12} sm={12} md={12} lg={9} xl={9}>
                                    <OrderSummary  />
                                </Grid> 

                                
                            </Grid>


                        </Grid>
                    </Grid>

                </Grid>
                
            </BrowserView>

            <MobileView>


                <Grid container justifyContent={'space-evenly'} marginTop={4} paddingTop={4}  columnGap={0.5} flexDirection={'row'} alignItems={'flex-start'}>
                    <Grid className="flexbox-wrapper" item lg={12} sm={12} md={12} xl={12} sx={{overflowY:'scroll'}}>
                        {/* <OrderSummary /> */}

                        <Grid container className="header" flexDirection={'row'} justifyContent={'flex-start'}>
                            <Grid container item sm={3} md={3} xs={3} lg={3} xl={3}
                                padding={1.5} 
                                flexDirection={'row'} 
                                alignItems={'center'} 
                                justifyContent={'flex-start'} 
                                onClick={() => {
                                    navigate('/menu');
                                }}>
                                    <Iconify icon={'eva:chevron-left-outline'} sx={{width: 32, height: 32, color: '#fff'}} />
                            </Grid>

                            <Grid container item justifyContent={'center'} alignItems={'center'}  sm={6} md={6} xs={6} lg={6} xl={6}>
                                <Typography variant={'h4'} sx={{color:'#fff'}}>
                                    {"Order Summary"}
                                </Typography>
                            </Grid>

                        </Grid>

                        <Grid className='order-summary-box' columnGap={2} paddingBottom={10}>
                    
                            <Grid item container xs={12} sm={12} md={12} lg={9} xl={9}>
                                <OrderSummary  />
                            </Grid> 

                            
                        </Grid>


                    </Grid>

                    {cartItems.length > 0 && <Grid className='fab-payment-container'>
                        <Grid 
                            container 
                            flexDirection='row' 
                            justifyContent='space-between' 
                            style={{color:'#fff',fontSize:'16px'}}
                            onClick={() => {
                                if(isValidCart){
                                    processOrder();
                                }else{
                                    displayMessage("error", CART_ERROR.NOT_SERVICABLE ); 
                                }
    //                            navigate('/payment');
                                // ref.current?.scrollIntoView({behavior: 'smooth', top:180});
                            }}
                            >
                                <p>
                                {cartItems.length} items | ₹{grandTotal}
                                </p>
                                <span>{userPaymentType === USER_PAYMENT_TYPE.CORPORATE_PAID ? 'Place Order 1' : 'Pay now'}</span>
                            </Grid>
                        </Grid>
                    }
                </Grid>
            </MobileView>

        </Page>
    )
}