import { BottomNavigation, BottomNavigationAction, Box, Grid, Paper, Tab, Tabs, Typography } from "@mui/material";
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom";

import RestoreIcon from '@mui/icons-material/Restore';
import FavoriteIcon from '@mui/icons-material/Favorite';
import LocationOnIcon from '@mui/icons-material/MoneyOutlined';
import { useSelector } from "react-redux";


import { ProfileForm } from "../profile";
import {MealPassHeaderMobile, MyOrdersHeaderMobile, MyProfileHeaderMobile} from "../../components/header";
import OrderHistory from "../order_history/OrderHistory";
import CorporateWallet from "../corporate_wallet/CorporateWallet";
import { getCorporateWalletDetails } from "../../store/corporate_wallet/reducer";


export default function MyAccountMobile() {

    const TAB_PROFILE = 0;
    const TAB_RECENTS = 1;
    const TAB_CREDITS = 2;

    const navigate = useNavigate();
    const [tabIndex, setTabIndex] = useState(TAB_PROFILE);

    const walletDetails = useSelector(state => getCorporateWalletDetails(state));


    useEffect(() => {

    },[]);

    return (
        <Grid container>
            {
                (tabIndex === TAB_PROFILE) && <MyProfileHeaderMobile />
            }
            {
                (tabIndex === TAB_RECENTS) && <MyOrdersHeaderMobile />
            }
            {
                (tabIndex === TAB_CREDITS) && <MealPassHeaderMobile />
            }

            <Grid container className={' glass-box'} flexDirection={'column'} justifyContent={'flex-start'} alignItems={'flex-start'} 
                sx={{width:'100%', marginTop: '64px',
                    background:'white', 
                    borderRadius:'0!important',
                    /* padding-top: 32px; */
                    paddingTop: '36px',
                    paddingBottom: '400px',
                    paddingLeft: '16px',
                    paddingRight: '16px',
                    height: '86vh',
                    overflow: 'auto'
                    
                    }}>

                    {
                        (tabIndex === TAB_PROFILE) && <ProfileForm />
                    }

                    {
                        (tabIndex === TAB_RECENTS) && <>
                            <OrderHistory />
                        </>
                    }

                    {
                        (tabIndex === TAB_CREDITS) && <CorporateWallet />
                    }
            </Grid>

            <Box sx={{position:'fixed', bottom:0, left:0, right:0, background:'transparent', height:'7vh'}} elevation={3}>
                <BottomNavigation
                    className={'glass-box bottom-nav-bar'}
                    showLabels
                    value={tabIndex}
                    onChange={(event, newValue) => {
                        console.log(tabIndex);
                        console.log(newValue);
                        setTabIndex(newValue);
                    }}
                    sx={{borderRadius: '0!important', height:'100%'}}
                    >
                    <BottomNavigationAction value={TAB_PROFILE} label="Profile" icon={<FavoriteIcon />} />
                    <BottomNavigationAction value={TAB_RECENTS} label="Recents" icon={<RestoreIcon />} sx={{color:'#000'}} />
                    <BottomNavigationAction value={TAB_CREDITS} label={`Credits`} sx={{color:'#000'}} icon={<Typography variant="h5">{`₹ ${walletDetails?.amount}`}</Typography>} />
                </BottomNavigation>
            </Box>
        </Grid>
    )

}