import { Box, Button, Grid, Snackbar, Typography } from "@mui/material";

import { BottomSheet } from "react-spring-bottom-sheet";
import 'react-spring-bottom-sheet/dist/style.css';

import { useCookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";
import Iconify from "../../components/Iconify";

import { getCartItems, getCartItemsById, getItemQty } from "../../store/cart/reducer";
import { decreaseCustomizeItemQty, getCustomizationItem, getCustomizationItemAddonQty, getCustomizationItemPrice, getCustomizationItemQty, increaseCustomizeItemQty, resetCustomizeItem, resetEditingCartItemId, setCustomizeAddon, setCustomizeCartItemId, setCustomizeItem } from "../../store/menu/reducer";
import { SNACKBAR_SEVERITY } from "../../constants/app_constants";
import { setSnackbarMessage, setSnackbarVisibilityStatus } from "../../store/layout/reducer";
import { updateCartApi } from "../../data/network/cart/api";
import { getSelectedCorporate } from "../../store/otp/reducer";

export default function CustomizeList({item, isOpen, handleClose, showCustomizeOption, closeCustomizeOption}) {
    const dispatch = useDispatch();
    const [cookies, setCookie] = useCookies(['mobile','session', 'access_token']);

    const addonQty = useSelector(state => getCustomizationItemAddonQty(state));
    const customizedQty = useSelector(state => getCustomizationItemQty(state));
    const customizedPrice = useSelector(state => getCustomizationItemPrice(state));
    const customizedItem = useSelector(state => getCustomizationItem(state));
    const itemQtyInCart = useSelector(state => getItemQty(state, item));

    const cartItems = useSelector(state => getCartItemsById(state,item));
    const selectedCorporate = useSelector(state => getSelectedCorporate(state));

    // TODO : change after receiving from backend
    const maxAllowedAddonQty = item.max_quantity;

    const CustomizeOptionHeader = ({item}) => {
        return (
            <Grid>
                <Box margin={1} />
                <div style={{
                    position: 'fixed',
                    right: 20,
                    top: 20,
                    borderRadius: 50,
                    backgroundColor: 'rgb(255, 255, 255)',
                    display: 'flex',
                    justifyContent: 'center',
                    padding:'4px',
                    boxShadow:'1px 4px 8px 1px #9e9e9e59'
                }}>
                    <Iconify sx={{fontSize:24}} icon="eva:close-outline" onClick={() => {
                        handleClose();
                    }} />
                </div>
                <Grid item container flexDirection={'column'} justifyContent={'flex-start'} alignItems={'flex-start'}>
                    <Typography variant="body1">{item.name}</Typography>
                    <Typography variant="h5">Your Customisations</Typography>
                </Grid>
                <Box margin={1} />
            </Grid>
        );
    }
    
    const CustomizeOptionFooter = () => {
        return (
            <Grid container flexDirection={'row'} justifyContent={'center'} alignItems={'center'}>
                <Button variant="contained" sx={{borderRadius:'10px'}} onClick={(e) => {
                    handleClose();
                }}>
                    <Typography variant="body1">Close</Typography>
                </Button>
            </Grid>
        );
    }

    const handleAddCustomizeToCart = ({item, addon, quantity}) => { 
        const addonList = []
        addon.forEach(item => {
            addonList.push({
                'addon' : item,
                'quantity' : item.quantity
            })
        })
        console.log(addonList)
        updateCartApi(dispatch, cookies, {
            'corporateId' : selectedCorporate?.id,
            'cart_item_id': item.cart_item_id,
            'item' : item,
            'quantity': quantity,
            'addon' : addonList
        });

        // handleClose();
    }

    return (
        <BottomSheet 
            className="customize-modal"
            open={isOpen} 
            onDismiss={() => {handleClose()}}
            header={<CustomizeOptionHeader item={item} />}
            // footer={<CustomizeOptionFooter item={item} />}
            style={{
                // @ts-ignore
                "--rsbs-bg": '#f2f6fc',
            }}
        >
            <Grid flexDirection="column" justifyContent="flex-start" alignItems="flex-start" container style={{minHeight:'300px',padding:10}}>
                <Box margin={1} />
                {
                    cartItems && cartItems.length > 0 &&
                    <Grid item container className='addon-container' rowGap={1} >
                        {
                            cartItems.map((cartItem, i) => 
                                <Grid key={i} item container flexDirection={'column'} justifyContent={'center'} alignItems={'stretch'} rowGap={1}>
                                    <Grid item container flexDirection={'row'} justifyContent={'space-between'} alignItems={'flex-start'}>
                                        <Grid item container flexDirection={'column'} justifyContent={'flex-start'} alignItems={'start'}  maxWidth={'60%'} columnGap={1}>
                                            {
                                                cartItem.is_non_veg
                                                ? <img height={24} width={24} src='/static/nonveg.png' alt='non veg' />
                                                : <img height={24} width={24} src='/static/veg.png' alt='veg' />
                                            }
                                            <Typography variant="body1">
                                                {cartItem.display_name}
                                            </Typography>

                                            <Grid item container flexDirection={'row'} justifyContent={'flex-start'} alignItems={'center'}>
                                                {
                                                    cartItem.addons.map((addon, j) => 
                                                        <Typography key={j} variant="body1">
                                                            {j>0 && ', '} {addon.display_name} {`(x${addon.quantity})`}
                                                        </Typography>
                                                    )
                                                }
                                            </Grid>
                                        </Grid>

                                        <Grid item container maxWidth={'40%'} justifyContent={'flex-end'} alignItems={'center'}  onClick={() => {
                                                dispatch(setCustomizeCartItemId({'cartItemId' : cartItem.cart_item_id}));
                                                const addonList = []
                                                cartItem.addons.forEach(item => {
                                                    addonList.push({
                                                        'addon' : item,
                                                        'quantity' : item.quantity
                                                    })
                                                })
                                                // dispatch(setCustomizeAddon({'addons': addonList, 'quantity': cartItem.quantity}));
                                                dispatch(setCustomizeItem({'item': item, 'addons': addonList, 'quantity': cartItem.quantity}));
                                                showCustomizeOption();
                                            }}>
                                            <Typography variant="body1">
                                                Edit
                                            </Typography>

                                            <Iconify icon={'eva:arrow-ios-forward-outline'} color='#000' sx={{fontSize:'16px'}} />
                                        </Grid>
                                    </Grid>

                                    <Grid item container flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} columnGap={1}>
                                        <Typography variant="body1">
                                            {`₹ ${cartItem.effective_price}`}
                                        </Typography>

                                        <Grid container spacing={0}
                                                direction="row"
                                                justifyContent="center"
                                                flexWrap={"nowrap"}
                                                alignItems="center"
                                                className="change_qty"
                                                maxWidth='30%'
                                                border={'1px solid #e0e0e0'}
                                                borderRadius={1}
                                                boxShadow={'1px 4px 8px 1px #9e9e9e59'}
                                            >
                                            <Grid item sx={{padding:0}}>
                                                <Button className="change-qty-customization-list" onClick={async () => {
                                                    if (cartItem.quantity > 0) {
                                                        handleAddCustomizeToCart({
                                                            'item' : cartItem,
                                                            'addon' : cartItem.addons,
                                                            'quantity' : cartItem.quantity-1
                                                        });
                                                    }else{
                                                        console.log("remove the row");
                                                    }

                                                    // dispatch(setCustomizeItem({
                                                    //     'item': cartItem, 
                                                    //     'addons': cartItem.addons,
                                                    //     'quantity': cartItem.quantity
                                                    // }));

                                                    // if (cartItem.quantity > 0) {
                                                    //     dispatch(decreaseCustomizeItemQty({}));
                                                    //     setTimeout(() => {
                                                    //         handleAddCustomizeToCart();
                                                    //         dispatch(resetCustomizeItem());
                                                    //     },1000);


                                                    // // }else{
                                                    // //     dispatch(resetCustomizeItem());
                                                    // //     handleClose();
                                                    // }
                                                }}>
                                                    <Iconify icon="fa:minus" color="#000" />
                                                </Button> 
                                            </Grid>
                                            <Grid item sx={{justifyContent:'center',paddingLeft:2, paddingRight: 2}}>
                                                <Typography variant="h5" color="text.primary" style={{color:'#000'}} >
                                                    {cartItem.quantity}
                                                </Typography>
                                            </Grid>
                                            <Grid item sx={{padding:0}}>
                                                <Button className="change-qty-customization-list" onClick={(e) => {

                                                    if (itemQtyInCart < item.max_quantity) {
                                                        handleAddCustomizeToCart({
                                                            'item' : cartItem,
                                                            'addon' : cartItem.addons,
                                                            'quantity' : cartItem.quantity+1
                                                        });
                                                    }else{
                                                        const message = `You can order a maximum of ${item.max_quantity} units at a time.`;
                                                        
                                                        dispatch(setSnackbarMessage({'message': message, 'severity': SNACKBAR_SEVERITY.INFO}));
                                                        dispatch(setSnackbarVisibilityStatus({'status': true }));
                                                    }

                                                    // dispatch(setCustomizeItem({
                                                    //     'item': cartItem, 
                                                    //     'addons': cartItem.addons,
                                                    //     'quantity': cartItem.quantity
                                                    // }));
                                                    // if (customizedQty + itemQtyInCart < item.max_quantity) {
                                                    //     dispatch(increaseCustomizeItemQty());
                                                    //     setTimeout(() => {
                                                    //         handleAddCustomizeToCart();
                                                    //         dispatch(resetCustomizeItem());
                                                    //     },1000);
                                                    // }else{
                                                    //     const message = `You can order a maximum of ${item.max_quantity} units at a time.`;
                                                        
                                                    //     dispatch(setSnackbarMessage({'message': message, 'severity': SNACKBAR_SEVERITY.INFO}));
                                                    //     dispatch(setSnackbarVisibilityStatus({'status': true }));
                                                    // }
                                                }}>
                                                    <Iconify icon="fa:plus" color="#000" /> 
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Box m={1} />

                                    <hr />
                                </Grid>
                            )
                        } 

                        <Box m={1} />

                        <Grid item container justifyContent={'center'} flexDirection={'row'} alignItems={'row'} onClick={() => {
                            dispatch(resetEditingCartItemId());
                            dispatch(setCustomizeItem({'item': item}));
                            showCustomizeOption();
                        }}>
                            <Button variant="outlined">
                                <Typography variant="h5" sx={{fontWeight:500}}>
                                    Add new customisation
                                </Typography>
                                            
                            </Button>

                        </Grid>
                    </Grid>
                }
            </Grid>
        </BottomSheet>
    );
}