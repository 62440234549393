import { useEffect } from "react"
import { BrowserView, MobileView } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useCookies } from "react-cookie";
import { MyAccountMobile, MyAccountWeb } from "../sections/my_account";
import Page from "../components/Page";
import { getLoginStatus } from "../store/user/reducer";
import { fetchCorporateWalletDetails } from "../data/network/corporate_wallet/api";
import { getSelectedCorporate } from "../store/otp/reducer";


export default function MyAccount() {

    const navigate = useNavigate();
    const isLoggedIn = useSelector(state => getLoginStatus(state));

    const dispatch = useDispatch();
    const [cookies, setCookie] = useCookies(['mobile','session', 'access_token']);

    const selectedCorporate = useSelector(state => getSelectedCorporate(state));
    
    useEffect(() => {
        if(!isLoggedIn){ 
          navigate('/');
        }else{
            fetchCorporateWalletDetails(dispatch, cookies, {
                'corporateId' : selectedCorporate?.id
            });
        }
    },[isLoggedIn])

    return (
        <Page title="My Account">
            <BrowserView>
                <MyAccountWeb />
            </BrowserView>

            <MobileView>
                <MyAccountMobile />
            </MobileView>
        </Page>
    )

}