import {Buffer} from 'buffer';
import { setLoadingStatus, setSnackbarMessage, setSnackbarVisibilityStatus } from '../../../store/layout/reducer';
import axios from '../../../utils/axios-configure';
import { API_GET_CORPORATE_FEEDBACK_LIST, API_UPDATE_CORPORATE_FEEDBACK} from '../endpoints';
import { getAccessTokenFromCookie } from "../session/api";
import { BASIC_AUTH_PASSWORD, BASIC_AUTH_USERNAME, SNACKBAR_SEVERITY } from '../../../constants/app_constants';
import { CORPORATE_FEEDBACK_LIST_FETCH, CORPORATE_FEEDBACK_UPDATE } from '../../../constants/message_constants';
import { setCorporateFeedbackList } from '../../../store/feedback/reducer';


export const fetchCorporateFeedbackList = (dispatch, cookies,corporateId, startDate, endDate, page = 1, count=10, filterBy = { key: '', value: '' }) => {
    dispatch(setLoadingStatus({ status: true }));
    // const accessToken = getAccessTokenFromCookie(dispatch, cookies);

    const url = `${API_GET_CORPORATE_FEEDBACK_LIST}?corporate_id=${corporateId}&start_date=${startDate}&end_date=${endDate}&page=${page}&count=${count}&filter=${filterBy?.key}&term=${filterBy?.value}`
    const token = `${BASIC_AUTH_USERNAME}:${BASIC_AUTH_PASSWORD}`
    const encodedToken = Buffer.from(token).toString('base64');

    axios({
        method: "get",
        url,
        headers: { "Content-Type": "application/json", 'Authorization': `Basic ${encodedToken} ` },
    })
    .then((response) => {
        // handle success
        if (response.status === 200) {
            dispatch(setCorporateFeedbackList({ data: response.data }));
        } else {
        //
        }
        dispatch(setLoadingStatus({ status: false }));
        return true;
    })
    .catch((err) => {
        dispatch(setLoadingStatus({ status: false }));
    });

  }
  
export const updateCorporateFeedback = (dispatch, cookies, data) => {
    const accessToken = getAccessTokenFromCookie(dispatch, cookies);
    dispatch(setLoadingStatus({ status: true }));
    if (accessToken != null) {
        //
    }
    const token = `${BASIC_AUTH_USERNAME}:${BASIC_AUTH_PASSWORD}`
    const encodedToken = Buffer.from(token).toString('base64');

    axios({
        method: "post",
        url: `${API_UPDATE_CORPORATE_FEEDBACK}`,
        data,
        headers: { "Content-Type": "application/json", 'Authorization': `Basic ${encodedToken}` },
    }).then((response) => {
        // handle success
        if (response.status === 200) {
            dispatch(setSnackbarMessage({ 'message': CORPORATE_FEEDBACK_UPDATE.SUCCESS, 'severity': SNACKBAR_SEVERITY.SUCCESS }));
            dispatch(setSnackbarVisibilityStatus({ 'status': true }));
            // fetchCorporateFeedbackList(dispatch, cookies, storeId)
        } else {
            dispatch(setSnackbarMessage({ 'message': CORPORATE_FEEDBACK_UPDATE.ERROR, 'severity': SNACKBAR_SEVERITY.ERROR }));
            dispatch(setSnackbarVisibilityStatus({ 'status': true }));
        }
        dispatch(setLoadingStatus({ status: false }));
    return true;
    })
    .catch((err) => {
        dispatch(setLoadingStatus({ status: false }));
        dispatch(setSnackbarMessage({ 'message': CORPORATE_FEEDBACK_UPDATE.ERROR, 'severity': SNACKBAR_SEVERITY.ERROR }));
        dispatch(setSnackbarVisibilityStatus({ 'status': true }));
    });

    dispatch(setLoadingStatus({ status: false }));
}
  