// @mui
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { alpha, styled } from '@mui/material/styles';
import { Card, Container, Grid, Typography } from '@mui/material'; 
import { useSelector } from 'react-redux';
// components
  
import {  getUserCorporateMeal } from '../../store/user/reducer';
import { formatDate } from '../../utils/date';

 // ----------------------------------------------------------------------
  
 
 export default function CorporateMealDetail( ) {

    const userCorporateMeals = useSelector(state => getUserCorporateMeal(state))


    useEffect(() => {
        console.log(userCorporateMeals);
    },[userCorporateMeals]);

    return (
        <Container
            // className="glass-box"
            sx={{
                px:2,
                py:2,
            }}
        >

        { 
            userCorporateMeals && userCorporateMeals.map((obj) =>  
                <Grid item key={`${obj.scheduled_date}_${obj.service_type.id}_${obj.corporate_menu_id}`} container  className='glass-box' padding={2} mt={2} mb={2}  
                    xs={12} sm={6} md={6} lg={4} xl={4} sx={{height:'200px' }} >

                    <Grid item container  direction="column" justifyContent="space-around" alignItems="flex-start">
                        
                            
                        

                        <Grid item container  direction="row" justifyContent="space-between" alignItems="flex-start"  >

                            <Grid item  sx={{padding:0 , justifyContent:'flex-end'}}>
                                <Typography color="#000" sx={{fontSize:14}}> {` ${formatDate(obj.scheduled_date, "ddd, DD MMM yyyy")}`}</Typography>
                            </Grid>

                            <Grid item  sx={{padding:0 , justifyContent:'flex-end'}}>
                                <Typography color="#000" sx={{fontSize:14}}> {` ${obj.service_type.display_name}`}</Typography>
                            </Grid>
                        </Grid>

                        <Grid item sx={{padding:0 }}>
                            <Typography color="#000" variant="h6" sx={{fontSize:14 }}>{` ${obj.menu_name}`} </Typography>
                        </Grid>

                         
                        <Grid item container sx={{padding:0 }}>
                            <Typography color="#000" sx={{fontSize:14 }}>{` Contains : ${obj.menu.ingredients}`} </Typography>
                        </Grid>

                        <Grid item container sx={{padding:0 }} mt={0}  direction="row" justifyContent="space-around" alignItems="flex-start">
                            <Grid item  container sx={{padding:0 , justifyContent:'flex-start'}} xs={12} sm={6} md={6} lg={6} xl={6} mt={2}>
                                <Typography color="#000" sx={{fontSize:14 }}>Quantity : {obj.quantity}</Typography>
                            </Grid>
                            <Grid item container sx={{padding:0 , justifyContent:'flex-start'}} xs={12} sm={6} md={6} lg={6} xl={6} mt={2}>
                                <Typography color="#000" sx={{fontSize:14}}>Amount : ₹ {obj.total_effective_price}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            ) 
        }
        </Container>
    );
 }
 