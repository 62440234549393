import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { motion, useMotionValue, useTransform, useAnimation } from 'framer';

// Card component with destructured props
export const SwipeableDeckCard = ({ image, color, i }) => {
    // To move the card as the user drags the cursor
    const x = useMotionValue(0);
    const background = useTransform(
        x,
        [-100, 0, 100],
        ["#ff008c", "#7700ff", "rgb(230, 255, 0)"]
    )
    // Framer animation hook
    const animControls = useAnimation();

     // To rotate the card as the card moves on drag
     const rotateValue = useTransform(x, [-200, 200], [-50, 50]);
 
     // To decrease opacity of the card when swiped
     // on dragging card to left(-200) or right(200)
     // opacity gradually changes to 0
     // and when the card is in center opacity = 1
     const opacityValue = useTransform(
         x,
         [-200, -150, 0, 150, 200],
         [0, 1, 1, 1, 0]
     );
  
 
    return (
        <motion.div style={{ backgroundSize:'contain' }}>
            <motion.div
                drag="x"
                dragConstraints={{ left: 0, right: 0 }}
                rotate={rotateValue}
                opacity={opacityValue}
                
                style={{  backgroundImage: `url(${image})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'contain',
                    backgroundColor: color,
                    boxShadow: '5px 10px 18px #888888',
                    borderRadius: 10,
                    height: 300
                }}
                onDragEnd={(event, info) => {
                    console.log(event);
                    console.log(info);
                    // If the card is dragged only upto 150 on x-axis
                    // bring it back to initial position
                    if (Math.abs(info.point.x) <= 80) {
                        animControls.start({ x: 0 });
                    } else {
 
                        // If card is dragged beyond 150
                        // make it disappear
 
                        // Making use of ternary operator
                        animControls.start({ x: info.point.x < 0 ? -200 : 200 });
                    }
                }}
            >
                {i}
            </motion.div>
        </motion.div>
    );
};