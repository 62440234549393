import { useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Card, Link, Container, Typography } from '@mui/material';

// react redux
import { useDispatch, useSelector } from 'react-redux';
import { useCookies } from 'react-cookie';
// hooks
import useResponsive from '../hooks/useResponsive';
// components
import Page from '../components/Page';
import Logo from '../components/Logo';
// sections
import { AccountTypeSelected, LoginForm, SelectAccountType, SelectCorporate } from '../sections/auth/login';
import AuthSocial from '../sections/auth/AuthSocial';
import { setSelectedAccountType, getSelectedAccountType, getSelectedCorporate, setSelectedCorporate } from '../store/otp/reducer';
import { ACCOUNT_TYPE } from '../constants/app_constants';

import { getSession } from '../data/network/session/api';
import { fetchUserDetails } from '../data/network/user/api';
import { getLoginStatus, getUser } from '../store/user/reducer';
import { getAfterLoginUrl } from '../store/navigation/reducer';


// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '95%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 450,
  margin: 'auto',
  minHeight: '75vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Login() { 

  const dispatch = useDispatch();
  const navigate = useNavigate(); 
  const [cookies, setCookie, removeCookie] = useCookies(['mobile','session', 'access_token']);

  const selectedAccountType = useSelector(state => getSelectedAccountType(state));
  const selectedCorporate = useSelector(state => getSelectedCorporate(state));
  const isLoggedIn = useSelector(state => getLoginStatus(state));
  const userDetails = useSelector(state => getUser(state)); 
  const afterLoginUrl = useSelector(state => getAfterLoginUrl(state)); 

  useEffect(() => {
    dispatch(setSelectedAccountType({type: ACCOUNT_TYPE.CORPORATE}));
    getSession(dispatch, cookies, removeCookie);
  },[]);
 
  useEffect(() => {
    if(isLoggedIn){
      fetchUserDetails(dispatch, cookies);
    }
  },[isLoggedIn]);

  useEffect(() => {
    if(isLoggedIn && userDetails){
      const corporateCount = userDetails?.corporate_clients?.length ?? 0;
      if (corporateCount === 0){
        navigate('/account-not-found/');
      }else{
        dispatch(setSelectedCorporate({'corporate': userDetails?.corporate_clients[0]}));
        navigate("/menu/");
        // if (afterLoginUrl === null) {
        //   navigate("/menu/");
        // }else{
        //   try{
        //     window.location.href = afterLoginUrl;
        //   }catch(e){
        //     navigate("/menu/");
        //     console.log(e);
        //   }
        // }
      }
    }
  },[userDetails]);

  return (
    <Page title="Login">
      {/* <RootStyle>
        { selectedAccountType == null  &&
          <SelectAccountType />
        }

        { selectedAccountType === ACCOUNT_TYPE.CORPORATE &&  (selectedCorporate  == null  || !selectedCorporate) &&
          <SelectCorporate />
        }

        { ((selectedAccountType === ACCOUNT_TYPE.CORPORATE && selectedCorporate && selectedCorporate  !== null) 
          || (selectedAccountType === ACCOUNT_TYPE.PERSONAL))
          &&
          <LoginForm />
        } 

      </RootStyle> */}

      <RootStyle>
        <LoginForm />
      </RootStyle>
    </Page>
  );
}
