import { createSlice } from '@reduxjs/toolkit'
import { ONBOARDING_FORM_STEPS, ONBOARDING_STATUS } from '../../constants/app_constants';

const onboardingSlice = createSlice({
  name: 'onboarding',
  initialState: {
    currentStep: ONBOARDING_FORM_STEPS.OTP_VERIFICATION,
    status: ONBOARDING_STATUS.INITIAL,
  },
  reducers: {
    setOnboardingCurrentStep(state, action) {
      state.currentStep = action.payload.step;
    },
    setOnboardingStatus(state, action) {
      state.status = action.payload.status;
    },
    resetOnboardingForm(state, action){
        state.currentStep = ONBOARDING_FORM_STEPS.OTP_VERIFICATION;
    },
  }
})

export const getOnboardingCurrentStep = (state) => {
  try {
    return state.onboarding.currentStep;    
  } catch (error) {
    return null;
  }
}

export const getOnboardingStatus = (state) => {
  return state.onboarding.status;    
  
}

export const { setOnboardingCurrentStep, resetOnboardingForm, setOnboardingStatus } = onboardingSlice.actions
export default onboardingSlice.reducer
