import { Box, Button, Grid, Snackbar, SwipeableDrawer, Typography } from "@mui/material";
import { useEffect, useState } from "react";

import { BottomSheet } from "react-spring-bottom-sheet";
import 'react-spring-bottom-sheet/dist/style.css';

import { CardSwiper } from "react-card-rotate-swiper";
import { useCookies } from "react-cookie";
import styled from "@emotion/styled";
import { useDispatch, useSelector } from "react-redux";
import Iconify from "../../components/Iconify";

import { SNACKBAR_SEVERITY } from "../../constants/app_constants";
import { setSnackbarMessage, setSnackbarVisibilityStatus } from "../../store/layout/reducer";
import SwipeableCard from "../../components/cards/swipeable_card";
// import SwipeableDeckCard from "../../components/cards/deck/index";
import { getUserCorporateNps, removeCorporateNpsItem } from "../../store/user/reducer";

import '../../assets/css/Feedback.css';
import FeedbackCard from "./feedback_card";
import { SwipeableCardStack } from "../../components/cards/swipeable_card/components/stack";
import { addBulkNpsPrompt, storeFeedbackMeal } from "../../data/network/user/api";
// import Deck from "../../components/cards/deck";
import Simple, { SwipeableDeckCard } from "../../components/cards/deck/index";

export default function FeedbackModal({item, isOpen, handleClose}) {
    
    const dispatch = useDispatch();
    const [cookies, setCookie] = useCookies(['mobile','session', 'access_token']);

    const corporateNps = useSelector(state => getUserCorporateNps(state));

    const [cardData, setCardData] = useState([]);

    const handleVote = (data, result) => {
        try{
            console.log(data.item);
            console.log(result);

            const nps = result ? 5 : 1;

            dispatch(removeCorporateNpsItem({data: data.item}));

            storeFeedbackMeal(dispatch, cookies, {
                'order_id' : data.item.order_id,
                'order_item_id' : data.item.order_item_id,
                'nps' : nps
            });
        }catch(e) {
            console.log(e);
        }
    }

    const FeedbackModalHeader = ({item}) => {
        return (
            <Grid>
                <Box margin={1} />
                <div style={{
                    position: 'fixed',
                    right: 20,
                    top: 20,
                    borderRadius: 50,
                    backgroundColor: 'rgb(255, 255, 255)',
                    display: 'flex',
                    justifyContent: 'center',
                    padding:'4px',
                    boxShadow:'1px 4px 8px 1px #9e9e9e59'
                }}>
                    <Iconify sx={{fontSize:24}} icon="eva:close-outline" onClick={() => {
                        handleClose();
                    }} />
                </div>
                <Grid item container flexDirection={'column'} justifyContent={'flex-start'} alignItems={'flex-start'}>
                    <Typography variant="h3">Feedback</Typography>
                </Grid>
                <Box margin={1} />
            </Grid>
        );
    }
    
    const FeedbackModalFooter = () => {
        return (
            <Grid container justifyContent={'space-between'} flexDirection={'row'} alignItems={'center'}>
                <Grid container spacing={0}
                    direction="row"
                    justifyContent="center"
                    flexWrap={"nowrap"}
                    alignItems="center"
                    className="change_qty"
                    maxWidth='40%'
                    border={'1px solid #e0e0e0'}
                    borderRadius={1}
                    boxShadow={'1px 4px 8px 1px #9e9e9e59'}
                    >
                    <></>
                </Grid>
            </Grid>
        );
    }

    useEffect(() => {
        try{
            if(corporateNps && corporateNps.length > 0){
                // do nothing
                console.log(corporateNps);
                const d = [];
                corporateNps.forEach((e) => { 
                    d.push(<FeedbackCard item={e} />)
                });
                setCardData(d);
            }else{
                handleClose();
            }
        }catch(e){
            console.log(e);
        }
    },[corporateNps]);

    const Wrapper = styled(SwipeableCardStack)`
        background: #1f2937;
    `;

    useEffect(() => {
        if(isOpen){
            try{
                const data = [];
                if (corporateNps && corporateNps.length > 0){
                    corporateNps.forEach(e => {
                        data.push({                        
                            'order_id' : e.order_id,
                            'order_item_id' : e.order_item_id,
                        })
                    });
                    // console.log(data);
                    if (data && data.length > 0){
                        addBulkNpsPrompt(dispatch, cookies, {'data' : data});
                    }
                }
            }catch(e){
                console.log(e);
            }
        }
    },[isOpen]);

    return (
        <BottomSheet 
            className="feedback-modal"
            open={isOpen} 
            onDismiss={() => {handleClose()}}
            // header={<FeedbackModalHeader item={item} />}
            // footer={<FeedbackModalFooter item={item} />}
            style={{
                // @ts-ignore
                // "--rsbs-bg": '#f2f6fc',
                "--rsbs-bg": '#0000009c',
                position:'relative',
                zIndex:9999,
            }}
        >
            <Grid className={'feedback-container'} flexDirection="column" justifyContent="center" alignItems="flex-start" container>   
                {/* <Grid className={'feedback-message-container-mobile'} container padding={1}>
                    <Typography className="feedback-message-container-mobile-text" variant="h6">We would love to hear your views about some of the dishes. This will help us continue serving you better.</Typography>
                </Grid>

                <Grid container className={'feedback-message-container-web'}>
                    <Typography className="feedback-message-container-web-text" variant="h6">We would love to hear your views about some of the dishes. This will help us continue serving you better.</Typography>
                </Grid> */}
                {
                    <Grid item container className={'feedback-card-container'}>
                        <Button onClick={() => {
                            // console.log("1111111");
                            handleClose();
                            }} style={{
                                position: 'absolute',
                                top:30,
                                right:30,
                                zIndex: 100
                            }}>
                            <Grid container flexDirection={'column'} justifyContent={'center'} alignItems={'center'} 
                            style={{borderRadius:100, cursor:'pointer', 
                                padding:4, height: '50px', width:'50px'}}
                            >
                                <Iconify icon={'eva:close-outline'} sx={{width:48, height: 48, color: 'white'}} />
                            </Grid>
                        </Button>

                        {/* <SwipeableCard data={corporateNps} onVote={(item, result) => handleVote(item, result)} /> */}
                        
                        {/* <Simple /> */}
                        {/* <div className="swipeable-card-container" style={{height: '100vh'}}> */}
                        {/* <div> */}
                            {/* { 
                                
                                corporateNps.map((e, i) => 
                                    <SwipeableDeckCard i={i} />
                                ) */}
                            {/* } */}
                            
                            {/* <Deck /> */}
                            {/* <Simple /> */}
                            {/* { 
                                
                                corporateNps.map((e, i) => 
                                    <SwipeableDeckCard key={i} no={i} />
                                )
                            } */}
                            {/* <FeedbackCard item={e} /> */}

                            {/* <SwipeableDeckCard /> */}
                            {/* {
                                cardData.map((e, i) => <CardSwiper
                                    onSwipe={(e) => {
                                        console.log(e);
                                    }}
                                    className={"swiper"}
                                    contents={e}
                                />)
                            } */}
                            {/* <CardSwiper
                                onSwipe={(e) => {
                                    console.log(e);
                                }}
                                className={"swiper"}
                                contents={
                                    cardData
                                }
                            /> */}
                        {/* </div> */}

                        <div className="swipeable-card-container">
                            <Wrapper onVote={(item, vote) => handleVote(item.props, vote)}>    
                                {
                                    corporateNps && corporateNps.map(e => <FeedbackCard item={e} />)
                                }
                            </Wrapper>
                        </div>
                    </Grid>
                }
            </Grid>
        </BottomSheet>
    );
}