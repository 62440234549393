import { useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink as RouterLink, matchPath, useLocation } from 'react-router-dom';
// material
import { alpha, useTheme, styled } from '@mui/material/styles';
import { Box, List, Collapse, ListItemText, ListItemIcon, ListItemButton } from '@mui/material';
//
import { useDispatch } from 'react-redux';
import { useCookies } from 'react-cookie';
import { logoutUser } from '../store/user/reducer';
import Iconify from './Iconify';

// ----------------------------------------------------------------------
const NavList = styled((props) => <List disableGutters {...props} />)(({ theme }) => ({
  ...theme.typography.body1,
  height: '50px',
  // position: 'relative',
  textTransform: 'capitalize',
  color: '#d7d7d7',
  width: '100vw',
  background:theme.palette.primary.main,
  display:'flex',
  flexDirection: 'row',
  justifyContent: 'space-evenly',
  alignItems: 'center',
}));

const ListItemStyle = styled((props) => <ListItemButton disableGutters {...props} />)(({ theme }) => ({
  ...theme.typography.body1,
  // height: '50px',
  // position: 'relative',
  textTransform: 'capitalize',
  color: '#d7d7d7',
  paddingBottom: '0 !important',
  // background:theme.palette.primary.main,
  width:'20vw',
  display:'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  // background: 'red',
  alignItems: 'center',
}));

const ListItemIconStyle = styled(ListItemIcon)({
  width: 12,
  height: 12,
  color: 'inherit',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
  active: PropTypes.func,
};

function NavItem({ item, active }) {
  const theme = useTheme();

  const isActiveRoot = active(item.path);

  const { title, path, icon,activeIcon, inActiveIcon, info, children } = item;

  const [open, setOpen] = useState(isActiveRoot);

  const handleOpen = () => {
    setOpen((prev) => !prev);
  };

  const activeRootStyle = {
    color: '#fff',
    fontWeight: 'fontWeightMedium',
    // bgColor: 'transparent',
    // bgcolor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
  };

  const activeSubStyle = {
    color: '#fff',
    fontWeight: 'fontWeightMedium',
  };

  const inActiveRootStyle = {
    color: '#f0f0f0',
    fontWeight: 'fontWeightRegular',
    // bgColor: 'transparent',
    // bgcolor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
  };

  const inActiveSubStyle = {
    color: '#f0f0f0',
    fontWeight: 'fontWeightRegular',
  };

  return (
    <ListItemStyle
      component={RouterLink}
      to={path}
      sx={{
        ...(isActiveRoot ? activeRootStyle : inActiveRootStyle),
      }}
    >
      <ListItemIconStyle>{isActiveRoot ? activeIcon : inActiveIcon }</ListItemIconStyle>
      <ListItemText disableTypography primary={title} sx={{fontSize:11}} />
      {info && info}
    </ListItemStyle>
  );
}

BottomNavSection.propTypes = {
  navConfig: PropTypes.array,
};

export default function BottomNavSection({ navConfig, ...other }) {
  const { pathname } = useLocation();

  const match = (path) => (path ? !!matchPath({ path, end: false }, pathname) : false);
  const dispatch = useDispatch();
  const [cookies, setCookie, removeCookie] = useCookies(['mobile','session', 'access_token']);

  const handleLogout = () => {
    removeCookie('mobile', { path: '/' });
    removeCookie('session', { path: '/' });
    removeCookie('access_token', { path: '/' });
    dispatch(logoutUser({data: {}})); 
  }
  
  return (
    <Box {...other} sx={{width:'100%'}}>
      <NavList>
        {navConfig.map((item) => (
          <NavItem key={item.key} item={item} active={match} />
        ))}
      </NavList>
    </Box>
  );
}
