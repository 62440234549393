import { useEffect, useState } from "react"
import {Box, Grid, Tab, Tabs, Typography} from '@mui/material'
import { useSelector } from "react-redux";


import { ProfileForm } from "../profile";
import OrderHistory from "../order_history/OrderHistory";
import CorporateWallet from "../corporate_wallet/CorporateWallet";
import { getCorporateWalletDetails } from "../../store/corporate_wallet/reducer";

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        style={{width:'100%', overflow:'auto'}}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
}

  

function a11yProps(index) {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    };
}

export default function MyAccountWeb() {

    const [tabIndex, setTabIndex] = useState(0);

    const walletDetails = useSelector(state => getCorporateWalletDetails(state));
    
    const handleTabChange = (e, newValue) => {
        console.log(newValue);
        setTabIndex(newValue);
    }
    
    useEffect(() => {

    },[]);

    return (
        <Grid container sx={{height:'100vh', overflow:'hidden'}}>
            <Grid container className="navbar-web glass-box" flexDirection={'row'} justifyContent={'flex-start'} sx={{height:'10vh'}}>
                <Grid container className="header-logo" lg={2} md={2} xl={2} xs={0} sm={0} item>
                  <a href='/'>
                    <img src='../../static/logo.png' alt='Bytes' width={96} />
                  </a>
                </Grid>
            </Grid>

            <Box
                className="glass-box"
                sx={{ flexGrow: 1, width:'100%', display: 'flex', height: '90vh', borderRadius: '0!important' }}
            >
                <Tabs
                    className="glass-box my-account-menu-container"
                    orientation="vertical"
                    // variant="scrollable"
                    value={tabIndex}
                    onChange={handleTabChange}
                    aria-label="My account menu"
                    sx={{ 
                            border: 1, 
                            // borderColor: 'divider', 
                            borderRadius: '10px!important',
                            width:'160px', 
                            height: '180px',
                            marginTop:'24px',
                            marginX: '10px',
                            display:'flex',
                            flexDirection:'column',
                            justifyContent: 'center'
                        }}
                >
                    <Tab label="Profile" {...a11yProps(0)} />
                    <Tab label="History" {...a11yProps(1)} />
                    <Tab label={`Credits (₹ ${walletDetails?.amount})`} {...a11yProps(2)} />

                </Tabs>
                <TabPanel value={tabIndex} index={0} >
                    <Grid className="glass-box" paddingX={4} paddingY={4} container >
                        <Typography variant="h4" >My Profile</Typography>
                        <ProfileForm />
                    </Grid>
                </TabPanel>
                <TabPanel value={tabIndex} index={1}>
                    <Grid className="glass-box" paddingX={4} paddingY={4} container>
                        <Typography variant="h4" >Order History</Typography>
                        <OrderHistory />
                    </Grid>
                </TabPanel>
                <TabPanel value={tabIndex} index={2}>
                    
                    <Grid className="glass-box" paddingX={4} paddingY={4} container>
                        <Typography variant="h4" >Meal Pass</Typography>
                        <CorporateWallet />
                    </Grid>

                </TabPanel>

            </Box>



        </Grid>
    )

}