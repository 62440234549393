/* eslint-disable camelcase */
import { useEffect, useState } from 'react';
import { filter } from 'lodash';
import { useTheme } from '@mui/material/styles';
import { Link as RouterLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useCookies } from 'react-cookie';
import { Box, Checkbox, Stack, Table, TableBody, TableCell,Typography, TableContainer, TablePagination, TableRow, Button, Grid, Container, IconButton, Divider } from "@mui/material";
import Scrollbar from "../../components/Scrollbar";
import Iconify from '../../components/Iconify';
import MyOrderListToolbar from './my_meal_list/MyMealListToolbar';
import MyOrderListHead from './my_meal_list/MyMealListHead';
import SearchNotFound from '../../components/SearchNotFound';
import { getUserId, getUserCorporateMeal } from '../../store/user/reducer';
import ExportCSV from '../../components/ExportCSV';
import '../../assets/css/MealList.css';
import { formatDate } from '../../utils/date';

export default function MyMealList(){

    const [page, setPage] = useState(0);
    const [order, setOrder] = useState('asc');
    const [selected, setSelected] = useState([]);
    const [orderBy, setOrderBy] = useState('name');
    const [filterName, setFilterName] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(5);
    
    const dataList = useSelector(state => getUserCorporateMeal(state));

    const dispatch = useDispatch(); 
    const [cookies, setCookie, removeCookie] = useCookies(['mobile','session', 'access_token']);
  
    useEffect(() => {  

    },[ ])
 
    const TABLE_HEAD = [
        { id: 'date', label: 'Date', alignRight: false },
        { id: 'service_type', label: 'Type', align:'center' },
        { id: 'menu_name', label: 'Menu Name', alignRight: false },
        { id: 'quantity', label: 'Quantity', alignRight: false },
        { id: 'amount', label: 'Amount', alignRight: false },
        { id: 'action', label: '', alignRight: false },
    ];

    function descendingComparator(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
          return -1;
        }
        if (b[orderBy] > a[orderBy]) {
          return 1;
        }
        return 0;
    }
      
    function getComparator(order, orderBy) {
        return order === 'desc'
          ? (a, b) => descendingComparator(a, b, orderBy)
          : (a, b) => -descendingComparator(a, b, orderBy);
    }
      
    function applySortFilter(array, comparator, query) {
        if(array !== undefined && array != null &&  array.length>0){
          const stabilizedThis = array.map((el, index) => [el, index]);
          stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
          });
          if (query) {
            return filter(array, (_user) => _user.order_id.toLowerCase().indexOf(query.toLowerCase()) !== -1);
          }
          return stabilizedThis.map((el) => el[0]);  
        }
      
        return [];
}  

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };
    
    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
          const newSelecteds = dataList.map((n) => n.name);
          setSelected(newSelecteds);
          return;
        }
        setSelected([]);
    };
    
    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];
        if (selectedIndex === -1) {
          newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
          newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
          newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
          newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }
        setSelected(newSelected);
    };
    
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    
    const handleFilterByName = (event) => {
        setFilterName(event.target.value);
    };
    
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - dataList.length) : 0;

    const filteredData = applySortFilter(dataList, getComparator(order, orderBy), filterName);
    const isDataNotFound = filteredData.length === 0;
 
    const headers = [ 
        { label: "Order Date", key: "order_date" },
        { label: "Order Amount", key: "order_amount" },
        { label: "Payment Mode", key: "payment_mode_name" },
        { label: "Order Items", key: "order_items" },
    ]; 

    function orderData() {
        const data = [];
        if(dataList){ 
            for (let i = 0; i <  dataList.length; i+=1) { 
                console.log(JSON.stringify(dataList[i].order_items));
                data[i] = {
                    'order_id': `${dataList[i].order_id } `,
                    'order_date': dataList[i].order_date,
                    'order_amount': dataList[i].order_amount,
                    'payment_mode_name': dataList[i].payment_mode_name,              
                    'order_items': JSON.stringify(dataList[i].order_items),              
                };
            }
        } 
        return data;
    }

    const ExpandableTableRow = ({ children, expandComponent, ...otherProps }) => {
        const [isExpanded, setIsExpanded] = useState(false);
      
        return (
          <>
            <TableRow {...otherProps} className="expander-row">

              {children}
              {/* <TableCell padding="checkbox">
                <IconButton onClick={() => setIsExpanded(!isExpanded)}>
                  {isExpanded 
                    ? <Iconify icon="eva:arrow-ios-upward-outline"/> 
                    : <Iconify icon="eva:arrow-ios-downward-outline"/>
                   }
                </IconButton>
              </TableCell> */}
            </TableRow>
            {isExpanded && (
              <TableRow className="expandable-content">
                <TableCell padding="checkbox" />
                {expandComponent}
              </TableRow>
            )}
          </>
        );
      };

    return (
        <Box>
            {/* <Grid container justifyContent="end">
                <ExportCSV csvHeaders={headers}
                    csvData={orderData()}
                    fileName="orders.csv" /> 
            </Grid> */}

            {/* <MyOrderListToolbar
                numSelected={selected.length}
                filterName={filterName}
                onFilterName={handleFilterByName} /> */}

            <Scrollbar className={'meal-list-card'}>
                {
                    dataList && dataList.map((obj, i) => {
                        return <Grid item key={`${obj.scheduled_date}_${obj.service_type.id}_${obj.corporate_menu_id}_${i}`} container  padding={2} mt={2} mb={2}  
                            xs={12} sm={12} md={12} lg={4} xl={4} sx={{height:'200px', borderBottom: '1px solid #a1a1a1' }} >

                            <Grid item container direction="column" justifyContent="space-around" alignItems="flex-start">
                              
                                <Grid item container  direction="row" justifyContent="space-between" alignItems="flex-start"  >

                                    <Grid item  sx={{padding:0 , justifyContent:'flex-end'}}>
                                        <Typography color="#000" sx={{fontSize:14}}> {` ${formatDate(obj.scheduled_date, "ddd, DD MMM yyyy")}`}</Typography>
                                    </Grid>

                                    <Grid item  sx={{padding:0 , justifyContent:'flex-end'}}>
                                        <Typography color="#000" sx={{fontSize:14}}> {` ${obj.service_type.display_name}`}</Typography>
                                    </Grid>
                                </Grid>

                                <Grid item sx={{padding:0 }}>
                                    <Typography color="#000" variant="h6" sx={{fontSize:16, fontFamily:500 }}>{` ${obj.menu_name}`} </Typography>
                                </Grid>

                                {
                                    obj.addons.length > 0 && <Grid item sx={{padding:0 }}>
                                        <Typography color="#000" sx={{fontSize:14 }}>{`Addons : ${obj.addons.map(item => {return  `${item.menu_name} (x${item.quantity})`}).join(',')}`} </Typography>
                                    </Grid>
                                }

                                <Grid item container sx={{padding:0 }}>
                                    <Typography color="#000" sx={{fontSize:14 }}>{` Contains : ${obj.menu.ingredients}`} </Typography>
                                </Grid>

                                <Grid item container sx={{padding:0 }} mt={0}  direction="row" justifyContent="space-around" alignItems="flex-start">
                                    <Grid item  container sx={{padding:0 , justifyContent:'flex-start'}} xs={12} sm={6} md={6} lg={6} xl={6} mt={2}>
                                        <Typography color="#000" sx={{fontSize:14 }}>Quantity : {obj.quantity}</Typography>
                                    </Grid>
                                    <Grid item container sx={{padding:0 , justifyContent:'flex-start'}} xs={12} sm={6} md={6} lg={6} xl={6} mt={2}>
                                        <Typography color="#000" sx={{fontSize:14}}>Amount : ₹ {obj.total_effective_price}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    })
                }
            </Scrollbar>

            <Scrollbar className={'meal-list-table'}>
                <TableContainer sx={{ minWidth: 800, padding:1 }}>
                    {
                    dataList !==undefined && dataList.length>0 
                    && 
                    <Table>
                        <MyOrderListHead
                            order={order}
                            orderBy={orderBy}
                            headLabel={TABLE_HEAD}
                            rowCount={dataList.length}
                            numSelected={selected.length}
                            onRequestSort={handleRequestSort}
                            // showSelectAllCheckbox = { false}
                            // show

                        // onSelectAllClick={handleSelectAllClick}
                        />
                        <TableBody>
                        {filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {

                            const { order_id , order_amount, menu, quantity,total_effective_price, service_type, menu_name, scheduled_date, payment_mode_name,price_breakup, internal_order_id, order_items } = row;
 
                            const isItemSelected = selected.indexOf(order_id) !== -1;
                            
                            return <ExpandableTableRow 
                                key={row.name}
                                expandComponent={
                                    <TableCell colSpan="5" className='expander-cell'>
                                        { 
                                           order_items && order_items.map(e => 
                                                   <Grid key={e.corporate_menu_id} className="price_breakup_single w-100">
                                                    <span>
                                                    {e.menu_name} x {e.quantity}
                                                    </span>

                                                    <span>
                                                    ₹ {e.total_effective_price}
                                                    </span>
                                                   </Grid>
                                                
                                             )
                                        }

                                        <Box margin={2} />
                                        <Divider spacing={1} sx={{ borderStyle: 'dashed' }}/>
                                        <Box margin={2} />
                                        {
                                            price_breakup && price_breakup.map((e) => 
                                                (e.name !== "grand_total")
                                                ? <Grid key={e.name} className="price_breakup_single w-100">
                                                    <span>
                                                    {e.display_name}
                                                    </span>

                                                    <span>
                                                    ₹ {e.value}
                                                    </span>
                                                </Grid>
                                                : null
                                            )
                                        }

                                        <Box margin={2} />
                                        {/* <Divider spacing={1} sx={{ borderStyle: 'dashed' }}/>
                                        
                                        <Box margin={2} />
                                        <Grid className="price_breakup_single w-100 grand_total">
                                            <h3 className="grand_total_label">
                                            Grand Total 
                                            </h3>

                                            <h3 className="grand_total_value">
                                            ₹ { }
                                            </h3 >
                                        </Grid> */}
                                    </TableCell> 
                                } > 
                                        <TableCell className='main-row-cell' component="th" scope="row" padding="none">
                                             {formatDate(scheduled_date, "DD MMM yyyy")} 
                                        </TableCell>
                                        <TableCell >{service_type.display_name}</TableCell>
                                        <TableCell >
                                            {menu.display_name}
                                        </TableCell>
                                        <TableCell >{`${quantity}` }</TableCell>
                                        <TableCell >{`₹ ${total_effective_price}`}</TableCell>
                                        <TableCell >{  
                                            <Grid container  direction="column">
                                                 <Button onClick={() => {
                                                    window.open( `meal/detail?start_date=${formatDate(scheduled_date)}&end_date=${formatDate(scheduled_date)}&service_type_id=${service_type.id}`  ,"_blank");
                                                }}>
                                                    View Details
                                                </Button>
                                            </Grid>
                                        }</TableCell>  
                                        {/* <TableCell align="right">
                                            <Button variant="contained" onClick={() => {
                                                setIsExpanded(!isExpanded) 
                                            }} >
                                                <Iconify icon="eva:download-outline" />
                                            </Button>
                                        </TableCell> */}
                            </ExpandableTableRow>;

                            // return (
                            //     < >

 
                            //         <TableRow
                            //             hover
                            //             key={order_id} 
                            //             tabIndex={-1}
                            //             role="checkbox"
                            //             selected={isItemSelected}
                            //             aria-checked={isItemSelected}
                            //         >
                            //             {/* <TableCell padding="checkbox">
                            //             <Checkbox checked={isItemSelected} onChange={(event) => handleClick(event, order_id)} />
                            //             </TableCell> */}
                            //             <TableCell component="th" scope="row" padding="none">
                            //             <Stack direction="row" alignItems="center" spacing={2}>
                            //                 {/* <Avatar alt={mobile} src={avatarUrl} /> */}
                            //                 <Typography variant="subtitle2" noWrap>
                            //                 {order_id}
                            //                 </Typography>
                            //             </Stack>
                            //             </TableCell>
                            //             <TableCell >{ order_date}</TableCell>
                            //             <TableCell >{`₹ ${order_amount}` }</TableCell>
                            //             <TableCell >{payment_mode_name}</TableCell>
                            //             <TableCell >{  
                            //                 <Grid container  direction="column">
                            //                     {/* <Button>
                            //                         View Order Details
                            //                     </Button> */}
                            //                     <Button onClick={() => {
                            //                         window.open( `order/receipt/${internal_order_id }`  ,"_blank");
                            //                     }}>
                            //                         Download Invoice
                            //                     </Button>
                            //                 </Grid>
                            //             }</TableCell>  
                            //             <TableCell align="right">
                            //                 <Button variant="contained" onClick={() => {
                            //                     setIsExpanded(!isExpanded) 
                            //                 }} >
                            //                     <Iconify icon="eva:download-outline" />
                            //                 </Button>
                            //             </TableCell>
                            //         </TableRow>
                            //         {isExpanded && (
                            //             <TableRow>
                            //             <TableCell padding="checkbox" />
                            //                 <Container>
                            //                     <p>123123123</p> 
                            //                 </Container>
                            //             </TableRow>
                            //         )}
                            //     </ >
                            // );
                        })}
                        {emptyRows > 0 && (
                            <TableRow style={{ height: 53 * emptyRows }}>
                            <TableCell colSpan={6} />
                            </TableRow>
                        )}
                        </TableBody>

                        {isDataNotFound && (
                        <TableBody>
                            <TableRow>
                            <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                <SearchNotFound searchQuery={filterName} />
                            </TableCell>
                            </TableRow>
                        </TableBody>
                        )}
                    </Table>
                    }
                </TableContainer>
            </Scrollbar>

            {
                    dataList !==undefined && dataList.length>0 
                    && <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={dataList !==undefined ? dataList.length : 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}/>
            }

            {
                (dataList === undefined || dataList.length === 0 )
                && <Grid container flexDirection={'row'} justifyContent={'center'} alignItems={'center'}>
                    <Typography variant="body1" sx={{fontSize:'18px'}}>No data found</Typography>
                </Grid>
            }
        </Box>
    );
}