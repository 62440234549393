import * as Yup from 'yup';
import { useState } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import { useNavigate } from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import { useCookies } from 'react-cookie';
// import { AdapterDateFns } from '@mui/lab/AdapterDateFns';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
// material
import { Stack,Box, TextField, IconButton, InputAdornment, Grid, Select, InputLabel, MenuItem } from '@mui/material';
import { LoadingButton, LocalizationProvider, MobileDatePicker } from '@mui/lab';
// component
import Iconify from '../../components/Iconify';
import {getUser} from '../../store/user/reducer';
import { updateUserDetails } from '../../data/network/user/api';

// ----------------------------------------------------------------------

export default function ProfileForm() {
  const navigate = useNavigate();

  const user = useSelector(state => getUser(state));
  const [cookies, setCookie] = useCookies(['mobile','session', 'access_token']);
  const dispatch = useDispatch();
  const [value, setValue] =  useState(
    new Date('2000-11-22') 
  );

  const handleChange = (newValue ) => {
    setValue(newValue);
  };
//   const [firstName, setFirstName] = useState(false);

  const ProfileSchema = Yup.object().shape({
    firstName: Yup.string() ,
    lastName: Yup.string() ,
    email: Yup.string().email('Email must be a valid email address'),
 
  });

  const formik = useFormik({
    initialValues: {
      firstName: user.first_name ,
      lastName: user.last_name ,
      email: user.email,
      height: user.height,
      weight: user.weight,
      physicalActivity: user.activity,
      mealPreference: user.meal_preference,
      gender: user.gender,
  },
    validationSchema: ProfileSchema,
    onSubmit: () => {

    },
  }); 

  const handleProfileSave = () =>{
    updateUserDetails(dispatch, cookies, {
      first_name: formik.values.firstName,
      last_name: formik.values.lastName,
      email: formik.values.email,
      mobile_no: user.mobile_no,
      height: formik.values.height,
      weight: formik.values.weight,
      physical_activity: formik.values.physicalActivity,
      meal_preference: formik.values.mealPreference,
      gender: formik.values.gender,
    });
  }

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit} style={{width:'100%'}}>
        <Grid  >
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              className={'input-box-contained'}
              fullWidth
              label="First name"
              {...getFieldProps('firstName')}
              error={Boolean(touched.firstName && errors.firstName)}
              helperText={touched.firstName && errors.firstName}
            />

            <TextField
              fullWidth
              className={'input-box-contained'}
              label="Last name"
              {...getFieldProps('lastName')}
              error={Boolean(touched.lastName && errors.lastName)}
              helperText={touched.lastName && errors.lastName}
            />
          </Stack>
            <Box margin={2} />
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField 
                fullWidth
                className={'input-box-contained'}
                autoComplete="username"
                type="email"
                label="Email address"
                {...getFieldProps('email')}
                error={Boolean(touched.email && errors.email)}
                helperText={touched.email && errors.email}
            />
            {/* <TextField
                fullWidth
                autoComplete="mobile"
                type="number"
                label="Mobile Number"
                {...getFieldProps('mobile')}
                error={Boolean(touched.mobile && errors.mobile)}
                helperText={touched.mobile && errors.mobile}
            /> */}
            
           </Stack>
           <Box margin={2} />
           <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              value={value}
              className={'input-box-contained'}
              {...getFieldProps('gender')}
              // onChange={(e) => setValue(e.target.value)}
              select // tell TextField to render select
              label="Gender"
              error={Boolean(touched.gender && errors.gender)}
              helperText={touched.gender && errors.gender}
            >
              <MenuItem value="MALE">Male</MenuItem>
              <MenuItem value="FEMALE">Female</MenuItem>
              <MenuItem value="OTHERS">Others</MenuItem>
            </TextField>

             <TextField
                fullWidth
                label="Meal Preference"
                select 
                className={'input-box-contained'}
                {...getFieldProps('mealPreference')}
                error={Boolean(touched.mealPreference && errors.mealPreference)}
                helperText={touched.mealPreference && errors.mealPreference}
              >
                <MenuItem value="Vegetarian">Vegetarian</MenuItem>
                <MenuItem value="Non-Vegetarian">Non Vegetarian</MenuItem>
                <MenuItem value="Eggetarian">Eggetarian</MenuItem>
              </TextField>
 
           </Stack>
           
           <Box margin={2} />
           <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
             <TextField
                fullWidth 
                 label="Physical Activity"
                 className={'input-box-contained'}
                 select 
                {...getFieldProps('physicalActivity')}
                error={Boolean(touched.physicalActivity && errors.physicalActivity)}
                helperText={touched.physicalActivity && errors.physicalActivity}
              >
                <MenuItem value="Strongly Active">Strongly Active</MenuItem>
                <MenuItem value="Moderately Active">Moderately Active</MenuItem>
                <MenuItem value="Slightly Active">Slightly Active</MenuItem>
                <MenuItem value="Sedentry">Sedentry</MenuItem>
              </TextField>

              <LocalizationProvider dateAdapter={AdapterDateFns}>
        
          
                <MobileDatePicker
                  label="Date of Birth"
                  inputFormat="dd MMM yyyy"
                  
                  value={value}
                  onChange={handleChange}
                  renderInput={(params) => <TextField className='input-box-contained' fullWidth {...params} />}
                />
              
              </LocalizationProvider>
{/* 
            <Select
                fullWidth
                label="Allergies"
                {...getFieldProps('allergy')}
                error={Boolean(touched.allergy && errors.allergy)}
                helperText={touched.allergy && errors.allergy}
              >
                <MenuItem value="MALE">Male</MenuItem>
                <MenuItem value="FEMALE">Female</MenuItem>
                <MenuItem value="OTHERS">Others</MenuItem>
              </Select> */}
 
           </Stack>

           <Box margin={2} />
           <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
                fullWidth
                className={'input-box-contained'}
                type="number"
                label="Height (in cms)"
                {...getFieldProps('height')}
                error={Boolean(touched.height && errors.height)}
                helperText={touched.height && errors.height}
            />
            <TextField
                fullWidth
                type="number"
                className={'input-box-contained'}
                label="Weight (in kg)"
                {...getFieldProps('weight')}
                error={Boolean(touched.weight && errors.weight)}
                helperText={touched.weight && errors.weight}
            />

           </Stack>
           <Box margin={2} />

           <Stack direction={{ xs: 'column', sm: 'row' }} spacing={3} justifyContent="center">
             
            <LoadingButton onClick={handleProfileSave} size="large" type="submit" variant="contained"  >
                Save
            </LoadingButton>
          </Stack>
        </Grid>
      </Form>
    </FormikProvider>
  );
}
