import { createSlice } from '@reduxjs/toolkit'
import { USER_PAYMENT_TYPE } from '../../constants/app_constants';

const corporateWalletSlice = createSlice({
  name: 'corporate_wallet',
  initialState: {
    walletDetails: null,
    walletTransactions: null,
  },
  reducers: {
    setCorporateWalletDetails(state,action) {
      state.walletDetails = action.payload.data; 
    },
    setCorporateWalletTransactions(state,action) {
      state.walletTransactions = action.payload.data; 
    },
    resetCorporateWalletState(state, action){
      state.walletDetails = null;
      state.walletTransactions = null;
    }
  }
})

export const getCorporateWalletDetails = (state) => {
  return state.corporate_wallet?.walletDetails ?? null;
}

export const getCorporateWalletTransactions = (state) => {
    return state.corporate_wallet?.walletTransactions ?? null;
}

export const { setCorporateWalletDetails, setCorporateWalletTransactions, resetCorporateWalletState, 
} = corporateWalletSlice.actions
export default corporateWalletSlice.reducer