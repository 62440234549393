import { faker } from '@faker-js/faker';
import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useCookies } from 'react-cookie';

// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, TextField, Container, Typography, Box, Button, Fab, FormGroup, FormControlLabel, Switch, Badge } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { LocalizationProvider, MobileDatePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import useIsInViewport from 'use-is-in-viewport'
import { BrowserView, isBrowser, isMobile, MobileView } from 'react-device-detect';
import {motion} from 'framer-motion';

// import TinderCard from 'react-tinder-card';
import styled from 'styled-components';
// components
import Page from '../components/Page';
import Iconify from '../components/Iconify';

import  '../assets/css/Menu.css';
// sections
import { CategoryList, MenuList, MyCalendar, OrderSummary } from '../sections/menu';
import { getSelectedAccountType, getSelectedCorporate } from '../store/otp/reducer';
import { fetchMenu } from '../data/network/menu/api';
import { fetchCart } from '../data/network/cart/api';
import { ACCOUNT_TYPE } from '../constants/app_constants';
import { formatDate } from '../utils/date';
import { filterAdded, filterRemoved, getSelectedFilter, getSelectedService, getVegPreferenceFilter, setSelectedDate } from '../store/menu/reducer';
import { getLoginStatus, getUser, logoutUser } from '../store/user/reducer';
import ServiceList from '../sections/menu/ServiceList';
import { getCartItems, getGrandTotal } from '../store/cart/reducer';
import SearchMenu from '../sections/menu/SearchMenu';
import FeedbackComponent from '../sections/feedback/feedback_component';
import { setFeedbackComponentVisibility } from '../store/feedback/reducer';
import Navbar from '../components/Navbar';
import ToggleCorporateList from '../sections/menu/ToggleCorporateList';
import SelectCorporateModal from '../components/modal/SelectCorporateModal/SelectCorporateModal';
import { fetchUserDetails } from '../data/network/user/api';
import SelectCorporateModalWeb from '../components/modal/SelectCorporateModal/SelectCorporateModalWeb';
import MenuHeaderMobile from '../sections/menu/MenuHeaderMobile';

// ----------------------------------------------------------------------

export default function Menu() {
  const theme = useTheme();

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const [cookies, setCookie] = useCookies(['mobile','session', 'access_token']);
  const selectedAccountType = useSelector(state => getSelectedAccountType(state));
  const selectedCorporate = useSelector(state => getSelectedCorporate(state));
  const selectedService = useSelector(state => getSelectedService(state));
  const isLoggedIn = useSelector(state => getLoginStatus(state));

  const cartItems = useSelector(state => getCartItems(state));
  const grandTotal = useSelector(state => getGrandTotal(state));  

  const [isCorporateListVisible, setIsCorporateListVisible] = useState(false);
  const [isSelectCorporateModalVisible, setIsSelectCorporateModalVisible] = useState(false);

  const ref = useRef(null);
  const menuRef = useRef(null);  

  const [parentViewport, parentViewportTargetRef, viewportRefToChain] = useIsInViewport({
    threshold: 50
  })
  const [inCartFabinViewport, cartFabTargetRef, viewportRef] = useIsInViewport({
    viewport: viewportRefToChain,
    threshold: 75
  })

  const isVegPreferred = useSelector(state => getVegPreferenceFilter(state));

  const userDetails = useSelector(state => getUser(state));
 
  const toggleVegPreference = () => {
      if(!isVegPreferred) {
          dispatch(filterAdded({type: 'preference', value: 'veg'}));
      }else{
          dispatch(filterRemoved({type: 'preference', value: 'veg'}))
      }
  }

  const loadData = () => {
    let serviceId = 1;
    try{
      if (selectedService != null) {
        serviceId = selectedService.id
      }
    }catch(e){
      console.log(e);
    }

    handleMenuDateChange(Date());

    fetchMenu(dispatch, cookies, {
        org: selectedCorporate?.name,
        date: formatDate(Date(), "DD-MM-yyyy"),
        serviceId
    });

    fetchCart(dispatch, cookies, {
      'corporateId': selectedCorporate?.id
    });
  }


  useEffect(() => { 
    if(!isLoggedIn){ 
      navigate('/');
    }else{
      fetchUserDetails(dispatch, cookies);
    }
  },[isLoggedIn])
  
  useEffect(() => {
    loadData();
  },[selectedService, selectedCorporate])
  
  useEffect(() => {
    // dispatch(setFeedbackComponentVisibility({isVisible : true}));
    loadData();
  },[])

  useEffect(() => {
    // setFilter([{key: 'category', value: "all"}])
    dispatch(filterAdded({type:'category', value:'all'}));
  }, []);

  
  const [menuDate, setMenuDate] =  useState(
    new Date() 
  );

  const handleMenuDateChange = (newValue ) => {
    try{
      setMenuDate(newValue); 
      dispatch(setSelectedDate(newValue));

      let serviceId = 1;
      try{
        if (selectedService != null) {
          serviceId = selectedService.id
        }
      }catch(e){
        console.log(e);
      }


      fetchMenu(dispatch, cookies, {
          org: selectedCorporate?.name,
          date: formatDate(newValue, "DD-MM-yyyy"),
          serviceId
      });
    }catch(e) {
      console.log(e);
    }
  };

  const handleDateChange = (type) => {
    const todayObj = new Date();
    const menuDateObj = new Date(menuDate);
    if(type === "prev"){
      if(todayObj < menuDateObj){
        menuDateObj.setDate(menuDateObj.getDate() - 1); 
      }
    }else{
        menuDateObj.setDate(menuDateObj.getDate() + 1);  
    }
    // var tomorrow = format(tomorrow_obj, "yyyy-MM-dd");
    // console.log(tomorrow_obj);
    handleMenuDateChange(menuDateObj);

    // setMenuDate(tomorrow);
  }

  const onSwipe = (direction) => {
    console.log( direction)
  }
  
  const onCardLeftScreen = (myIdentifier) => {
    console.log( ' left the screen')
  }

  const handleSelectCorporateClick = () => {
    if (userDetails?.corporate_clients.length > 1){
      setIsSelectCorporateModalVisible(true);
    }
  }

  const handleSelectCorporateModalClose = (e) => {
    setIsSelectCorporateModalVisible(false);
  }
  
  
  return (
    <Page title="Menu">
      <BrowserView>
        <Grid container justifyContent={'space-evenly'} columnGap={0.5} flexDirection={'row'} alignItems={'flex-start'}>          
          <Grid className="flexbox-wrapper" item lg={12} sm={12} md={12} xl={12} sx={{overflowY:'scroll'}}>              
              <Grid 
                className="navbar-web glass-box"
                justifyContent={'flex-start'}
                alignItems={'center'}
                sx={{
                  boxShadow: '0px -7px 20px 3px #0000006b',
                  // backgroundColor: '#e86826'
                }}
              >
                <Grid container className="header-logo" lg={2} md={2} xl={2} xs={0} sm={0} item>
                  <a href='/'>
                    <img src='../../static/logo.png' alt='Bytes' width={96} />
                  </a>
                </Grid>

                <Grid container className="menu-location-picker glass-box" onClick={handleSelectCorporateClick} padding={1} lg={3} md={4} xl={3} xs={12} sm={12} flexWrap={'nowrap'} alignItems={"center"} item marginBottom={0}>
                  <Iconify icon="eva:pin-fill" fontSize={24} sx={{color:'#000'}} mr={1} />
                  <Typography variant={'body1'} sx={{color: '#000', textOverflow:'ellipsis'}}>
                    {
                      selectedCorporate
                      ? selectedCorporate?.name
                      : "Select location"
                    }
                  </Typography>
                  <Iconify icon="eva:arrow-ios-downward-outline" fontSize={24} ml={1} sx={{color:'#000'}} />
                </Grid> 

                <Grid container lg={4} md={4} sm={12} xl={4} xs={12}>
                    {/*  */}
                </Grid> 

                <Grid container order={{lg:2, xl:2, sm:3, md:2, xs:3}} item lg={4} md={4} sm={12} xl={4} xs={12}>
                  <SearchMenu />
                </Grid>

                <Grid  order={{lg:3, xl:3, sm:2, xs:2, md:3}} justifyContent={'center'} alignItems={'center'}
                    container item lg={2} md={3} sm={3} xl={2} xs={3} rowGap={1}
                    onClick={() => {navigate('/my-account')}}
                  >
                  {/* <Grid className={'menu-icon menu-icon-notification'} sx={{}}>
                    <Iconify icon={'eva:bell-outline'} sx={{color:'#fff', fontSize:'24px'}} />
                  </Grid> */}
                  <Grid className={'menu-icon menu-icon-profile glass-box'} padding={2} sx={{}}>
                    <Iconify icon={'eva:people-outline'} sx={{color:'#000', fontSize:'24px'}} />
                  </Grid>
                </Grid>
              </Grid>

              <FeedbackComponent />
              {
                isSelectCorporateModalVisible && isBrowser && <SelectCorporateModalWeb open={isSelectCorporateModalVisible} onClose={handleSelectCorporateModalClose} />
              }

              <Grid className='menu-container' columnGap={2} paddingBottom={10}>
                
                <Grid item container xs={12} sm={12} md={12} lg={9} xl={9}>
                  <Grid container className="scrolling-wrapper-flexbox-web">
                    <Grid 
                        className={`scrolling-wrapper-card ${isVegPreferred ? 'active' : ''}`}
                        onClick={toggleVegPreference}
                    >
                        <Typography variant="body1">Show Veg only</Typography>
                        {isVegPreferred && <Iconify icon={'eva:close-outline'} sx={{color:'#fff', fontSize:'18px', marginLeft:'8px'}} />}
                    </Grid>
                  </Grid>

                  <MenuList  />

                </Grid> 

                <Grid className={'menu-order-summary'} item container xs={0} sm={0} md={0} lg={3} xl={3}>

                  <OrderSummary />
                </Grid>
              </Grid>
              
              {cartItems.length > 0 && <Grid className='fab-cart-container' style={{display: parentViewport ? 'none' : 'flex'}}>
                    <Grid 
                        container 
                        flexDirection='row' 
                        justifyContent='space-between' 
                        style={{color:'#fff',fontSize:'16px'}}
                        onClick={() => {
                          navigate('/cart');
                          // ref.current?.scrollIntoView({behavior: 'smooth', top:180});
                        }}
                        ref={cartFabTargetRef}
                    >
                      <p>
                        {cartItems.length} items | ₹{grandTotal}
                      </p>
                      <span>View Cart</span>
                    </Grid>
                  </Grid>
                }
          </Grid>
        </Grid>
      </BrowserView>
      <MobileView>
        
        <Grid container justifyContent={'space-evenly'} columnGap={0.5} flexDirection={'row'} alignItems={'flex-start'}>          
          <Grid className="flexbox-wrapper" item lg={12} sm={12} md={12} xl={12} sx={{overflowY:'scroll'}}>
              <MenuHeaderMobile />
              

              <Grid container className="scrolling-wrapper-flexbox glass-box"
                sx={{
                  borderRadius:'0!important',
                      // boxShadow: '0px 11px 10px 1px gray',
                      // backgroundColor: '#e86826'
                  }}
                >
                  <Grid 
                      className={`scrolling-wrapper-card ${isVegPreferred ? 'active' : ''}`}
                      onClick={toggleVegPreference}
                  >
                      <Typography variant="body1">Veg only</Typography>
                      {isVegPreferred && <Iconify icon={'eva:close-outline'} sx={{color:'#fff', fontSize:'24px', marginLeft:'8px'}} />}
                  </Grid>
                </Grid>

              <Grid className='menu-container' columnGap={2} paddingBottom={10}>
                
                <Grid item container xs={12} sm={12} md={12} lg={9} xl={9}>
                  <MenuList  />
                </Grid> 

                
              </Grid>
              
              
          </Grid>
          {cartItems.length > 0 && <Grid className='fab-cart-container' 
          // style={{display: parentViewport ? 'none' : 'flex'}}
          >
                    <Grid 
                        container 
                        flexDirection='row' 
                        justifyContent='space-between' 
                        style={{color:'#fff',fontSize:'16px'}}
                        onClick={() => {
                          navigate('/cart');
                          // ref.current?.scrollIntoView({behavior: 'smooth', top:180});
                        }}
                        ref={cartFabTargetRef}
                    >
                      <p>
                        {cartItems.length} items | ₹{grandTotal}
                      </p>
                      <span>View Cart</span>
                    </Grid>
                  </Grid>
                }
        </Grid>
        <FeedbackComponent />
        
      </MobileView>
    </Page>
  );
}
