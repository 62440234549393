import { useEffect } from "react";
import { useCookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";
import { Box, Card, Grid, Typography } from "@mui/material";
import { subDays } from "date-fns";
import { fetchUserCorporateOrders } from "../../data/network/user/api";
import { getUserCorporateOrderList } from "../../store/user/reducer";
import { formatDate } from "../../utils/date";
import OrderHistoryCard from "../../components/cards/OrderHistoryCard/OrderHistoryCard";

export default function OrderHistory(){

    const dispatch = useDispatch(); 
    const [cookies, setCookie, removeCookie] = useCookies(['mobile','session', 'access_token']);

    const dataList = useSelector(state => getUserCorporateOrderList(state));

    useEffect(() => {
        const startDate = subDays(new Date(), 365);
        fetchUserCorporateOrders(dispatch, cookies, {
            'end_date' : formatDate(Date()),
            'start_date' : formatDate(startDate)
        });
    }, []);


    return (
        <Grid container flexDirection={'row'} flexWrap={'wrap'} rowGap={2} columnGap={1.5} sx={{width:'100%'}}>
            {
                dataList?.length > 0 && dataList?.map((e) => {
                    return <OrderHistoryCard order={e} />
                })
            }
            {
                dataList?.length === 0 && 
                <Grid container justifyContent={'center'} alignItems={'center'} sx={{minHeight:'100px'}}>
                    <Typography variant="body1">
                        No orders found
                    </Typography>
                </Grid>
            }

        </Grid>
    );

}