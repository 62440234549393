import { Card, Grid, Typography } from "@mui/material";
import { useEffect } from "react";



export default function OrderItemRow ({orderItem}) {

    useEffect(() => {

    },[]);

    return (
        <Grid container flexDirection={'row'} justifyContent={'flex-start'} alignItems={'center'} columnGap={0.5}>
            {
                orderItem.is_non_veg 
                ? <img height={16} width={16} src='/static/nonveg.png' alt='non_veg' />
                : <img height={16} width={16} src='/static/veg.png' alt='veg' />
            }
            <Typography variant="subtitle1">{orderItem.quantity} x</Typography>
            {/* <Typography variant="subtitle1">{'x'}</Typography>             */}
            <Typography variant="h7">{orderItem.menu_name}</Typography>
        </Grid>
    );
}
