import { Grid, Typography } from "@mui/material";
import { useEffect } from "react";

export default function OrderSummaryItemCard(orderItem) {

    useEffect(() => {
        console.log(orderItem);
    },[]);

    return (
        <Grid className={'order-summary-item-container glass-box'} 
            container 
            flexDirection={'column'} 
            justifyContent={'flex-start'} 
            alignItems={'center'}
            rowGap={1}
        >
            <Grid container flexDirection={'row'} justifyContent={'flex-start'} alignItems={'center'}>
                {
                    orderItem.is_non_veg
                    ? <img height={16} width={16} src='/static/nonveg.png' alt='non_veg' />
                    : <img height={16} width={16} src='/static/veg.png' alt='veg' />
                }
                
                <Typography variant="body1">
                    {orderItem.menu_name}
                </Typography>
            </Grid>

            
            
        </Grid>
    );

}