import {Snackbar, Alert } from '@mui/material';
import LoadingOverlay from 'react-loading-overlay';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { AnimatePresence } from 'framer-motion';

// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import ScrollToTop from './components/ScrollToTop';
import { BaseOptionChartStyle } from './components/chart/BaseOptionChart';
import './assets/css/App.css';
import { getSnackbarVisibilityStatus, getSnackbarMessage,setLoadingStatus,
  getSnackbarSeverity, setSnackbarVisibilityStatus, getLoadingStatus
} from './store/layout/reducer';
// ----------------------------------------------------------------------

export default function App() {

  const dispatch = useDispatch();
  const isLoading = useSelector(state => getLoadingStatus(state));

  const showSnackbar = useSelector(state => getSnackbarVisibilityStatus(state));
  const snackbarMessage = useSelector(state => getSnackbarMessage(state));
  const snackbarSeverity = useSelector(state => getSnackbarSeverity(state));
  
  return ( 
    <LoadingOverlay
      active={isLoading}
      spinner
      text='Loading...'>
      <ThemeProvider>
        <ScrollToTop />
        <BaseOptionChartStyle />
        <AnimatePresence>
          <Router />
        </AnimatePresence>
      </ThemeProvider>
      <Snackbar open={showSnackbar} autoHideDuration={4000} onClose={(e) => {
          dispatch(setSnackbarVisibilityStatus({'status' : false}));
        }}>
        <Alert onClose={( ) => { }} 
          severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </LoadingOverlay>
  );
}
