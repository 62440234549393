import { useEffect } from "react"
import { BrowserView, MobileView } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { MyAccountMobile, MyAccountWeb } from "../sections/my_account";
import Page from "../components/Page";
import { getLoginStatus } from "../store/user/reducer";
import { OrderSummaryMobile, OrderSummaryWeb } from "../sections/order_summary";


export default function OrderSummary() {

    const navigate = useNavigate();
    const isLoggedIn = useSelector(state => getLoginStatus(state));
    
    useEffect(() => { 
        if(!isLoggedIn){ 
          navigate('/');
        }
    },[isLoggedIn])

    return (
        <Page title="Order Summary">
            <BrowserView>
                <OrderSummaryWeb />
            </BrowserView>

            <MobileView>
                <OrderSummaryMobile />
            </MobileView>
        </Page>
    )

}