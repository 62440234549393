import { setLoadingStatus, setSnackbarMessage, setSnackbarVisibilityStatus } from '../../../store/layout/reducer';
import { removeCorporateNpsItem, setUser, setUserCorporateMeal, setUserCorporateOrderList, setUserMealHistory } from '../../../store/user/reducer';
import axios from '../../../utils/axios-configure';
import { API_ADD_BULK_NPS_PROMPT, API_GET_CORPORATE_WALLET_DETAILS, API_GET_CORPORATE_WALLET_TRANSACTIONS, API_GET_USER_CORPORATE_MEAL, API_GET_USER_CORPORATE_ORDERS, API_GET_USER_DETAILS, API_GET_USER_MEAL_HISTORY, API_STORE_FEEDBACK_MEAL, API_UPDATE_USER_DETAILS } from "../endpoints";
import {getAccessTokenFromCookie} from '../session/api'
import { SNACKBAR_SEVERITY } from '../../../constants/app_constants';
import { PROFILE_UPDATE } from '../../../constants/message_constants';
import { setCorporateWalletDetails, setCorporateWalletTransactions } from '../../../store/corporate_wallet/reducer';

export const fetchCorporateWalletDetails = (dispatch, cookies, data) => {
    dispatch(setLoadingStatus({status: true}));  
    const  accessToken = getAccessTokenFromCookie(dispatch, cookies); 
    if(accessToken != null){

      const { corporateId } = data;

        axios({
            method: "get",
            url: `${API_GET_CORPORATE_WALLET_DETAILS}?corporate_id=${corporateId}`,
            headers: { "Content-Type": "application/json",'Authorization': `Bearer ${ accessToken}` },
          })
          .then((response) => {
            // handle success
            if(response.status === 200){
              dispatch(setCorporateWalletDetails({data: response.data.data}));
            }else{
              dispatch(setCorporateWalletDetails({data: {}}));
            } 
            dispatch(setLoadingStatus({status: false}));  
            return true;
          })
          .catch((err) => {
            dispatch(setCorporateWalletDetails({data: {}}));
            dispatch(setLoadingStatus({status: false}));  
            // console.log(response);
          });
    }else{
      dispatch(setCorporateWalletDetails({data: {}}));
      dispatch(setLoadingStatus({status: false}));  
    }
}

export const fetchCorporateWalletTransactions = (dispatch, cookies, data) => {
  dispatch(setLoadingStatus({status: true}));  
  const  accessToken = getAccessTokenFromCookie(dispatch, cookies); 
  if(accessToken != null){

    const {corporateId , page, count} = data;

      axios({
          method: "get",
          url: `${API_GET_CORPORATE_WALLET_TRANSACTIONS}?corporate_id=${corporateId}&page=${page}&count=${count}`,
          headers: { "Content-Type": "application/json",'Authorization': `Bearer ${ accessToken}` },
        })
        .then((response) => {
          // handle success
          if(response.status === 200){
            if (page === 1){
              dispatch(setCorporateWalletTransactions({data: response.data.data}));
            }else{
              // dispatch(setCorporateWalletTransactions({data: response.data.data}));
            }
          }else{
            dispatch(setCorporateWalletTransactions({data: {}}));
          } 
          dispatch(setLoadingStatus({status: false}));  
          return true;
        })
        .catch((err) => {
          dispatch(setCorporateWalletTransactions({data: {}}));
          dispatch(setLoadingStatus({status: false}));  
          // console.log(response);
        });
  }else{
    dispatch(setCorporateWalletTransactions({data: {}}));
    dispatch(setLoadingStatus({status: false}));  
  }
}
