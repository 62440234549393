import { Outlet } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Grid } from '@mui/material';
// components
import Logo from '../../components/Logo';
import Footer from '../../components/Footer';
import Navbar from '../../components/Navbar';
import BottomNavbar from '../../components/BottomNavbar';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100vh',
  height: '100%',
  overflow: 'hidden', 
  flexDirection:'column' ,
  // backgroundColor: 'orange'
  // background: 'hsla(298, 68%, 90%, 1)',
  // background: 'linear-gradient(90deg, #ebf4f5 0%, #b5c6e0 100%)',
  // background: 'linear-gradient(180deg, #e3ffe7 0%, #d9e7ff 100%)',
  // background: 'hsla(234, 80%, 88%, 1)',

  // TODO : check this
  // background: 'linear-gradient(108deg, rgba(238, 238, 201, 0.00) 0%, #E8F9D2 100%)',



  // background: 'linear-gradient(180deg, hsla(234, 80%, 88%, 1) 0%, hsla(340, 68%, 88%, 1) 50%, hsla(342, 72%, 85%, 1) 100%)',

  // background: '-moz-linear-gradient(90deg, hsla(234, 80%, 88%, 1) 0%, hsla(340, 68%, 88%, 1) 50%, hsla(342, 72%, 85%, 1) 100%)',

  // background: '-webkit-linear-gradient(90deg, hsla(234, 80%, 88%, 1) 0%, hsla(340, 68%, 88%, 1) 50%, hsla(342, 72%, 85%, 1) 100%)',

  // filter: 'progid: DXImageTransform.Microsoft.gradient( startColorstr="#CACFF9", endColorstr="#F5CBD9", GradientType=1 )',
  // background: 'linear-gradient(180deg, hsla(298, 68%, 90%, 1) 0%, hsla(30, 82%, 91%, 1) 100%)',
  // background: '-webkit-linear-gradient(90deg, hsla(298, 68%, 90%, 1) 0%, hsla(30, 82%, 91%, 1) 100%)',
  // filter: 'progid:DXImageTransform.Microsoft.gradient( startColorstr="#F6D5F7", endColorstr="#FBE9D7", GradientType=1 )'
});

const HeaderStyle = styled('header')(({ theme }) => ({
//   top: 10,
//   left: 40,
//   lineHeight: 0,
// //   width: '100%',
//   position: 'absolute',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: '10vh',
    padding: theme.spacing(3, 3, 0),
    zIndex: 3,
  [theme.breakpoints.down('md')]: {
    // padding: theme.spacing(5, 5, 0),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '75vh',
  width:'100vw',
//   paddingTop: 96,
  // paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    // paddingTop: APP_BAR_DESKTOP + 24,
    // paddingLeft: theme.spacing(2),
    // paddingRight: theme.spacing(2)
  }
})); 

const FooterStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  // overflow: 'auto',
  width:'100%',
  flexShrink: 0,
  height: '10vh',
  textAlign: 'center',
  backgroundColor: 'red',
  color: 'white',
  zIndex: 3,
  // minHeight: '100%',
  // paddingTop: APP_BAR_MOBILE + 24,
  // paddingBottom: theme.spacing(10),
  // [theme.breakpoints.up('lg')]: {
  //   paddingTop: APP_BAR_DESKTOP + 24,
  //   paddingLeft: theme.spacing(2),
  //   paddingRight: theme.spacing(2)
  // }
})); 

const BottomNavStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  // overflow: 'auto',
  width:'100%',
  position:'fixed',
  bottom:0,
  left:0,
  right:0,
  flexShrink: 0,
  textAlign: 'center',
  height:'60px',
  backgroundColor: '#e86826',
  color: 'white',
  display:'flex',
  // minHeight: '100%',
  // paddingTop: APP_BAR_MOBILE + 24,
  // paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('md')]: {
    display: 'none!important',
    //   paddingTop: APP_BAR_DESKTOP + 24,
  //   paddingLeft: theme.spacing(2),
  //   paddingRight: theme.spacing(2)
  }
})); 

const SideBox = styled('div')(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  height: '100%',
  width: '30vw',
  backgroundColor: '#e86826',
  [theme.breakpoints.down('md')]: {
    display: 'none'
  }
}));

const ImageStyle = styled('div')(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  height: '100%',
  width: '60vw',
  backgroundColor: 'transparent',
  [theme.breakpoints.down('md')]: {
    display: 'none'
  }
}));
// ----------------------------------------------------------------------
export default function AuthLayout() {
  return (
    <RootStyle>
      {/* <SideBox /> */}
      <HeaderStyle>
        <a href='/'>
          <img src='../../static/logo.png' alt='Bytes' width={128} />
        </a>
      </HeaderStyle>
      {/* <ImageStyle>
        <Grid sx={{height:'100%'}} container justifyContent={'center'} alignItems={'center'}>
          <a href='/'  style={{borderRadius:512}}>
            <img src='https://images.unsplash.com/photo-1555939594-58d7cb561ad1?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxleHBsb3JlLWZlZWR8M3x8fGVufDB8fHx8fA%3D%3D'
             alt='Bytes' width={512} />
          </a>
        </Grid>
      </ImageStyle> */}
      <MainStyle>
        <Outlet />
      </MainStyle>
      <Footer/>
    </RootStyle>
  );
}
