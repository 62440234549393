import { useEffect } from "react";

export default function OrderSummaryWeb () {

    useEffect(() => {

    },[])

    return (
        <>
        </>
    );

}
