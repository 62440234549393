import { Box, Card, Grid, Rating, Typography } from "@mui/material";
import { useEffect } from "react";
import RightChevronIcon from "@mui/icons-material/ChevronRightOutlined";
import { useNavigate } from "react-router-dom";
import { formatDate } from "../../../utils/date";
import OrderItemRow from "./OrderItemRow";

import './OrderHistoryCard.css';


export default function OrderHistoryCard ({order}) {

    const navigate = useNavigate();

    const handleOrderCardClick = () => {
        navigate(`/order/receipt/${order.internal_order_id}`);
    }

    useEffect(() => {
        console.log(order);
    },[]);

    return (
        <Card className={'order-history-card-container glass-box'} onClick={handleOrderCardClick}> 
            <Grid container justifyContent={'flex-start'} rowGap={1} alignItems={'flex-start'}>
                <Grid container flexDirection={'column'} padding={1}>
                    <Typography variant="subtitle2">{order.order_id}</Typography>
                    <Typography variant="body2">
                            Order placed on {formatDate(`${order.order_date}`, 'DD MMM, HH:mm A')}
                    </Typography>
                </Grid>

                <Box sx={{borderTop: '1px solid gray', width:'100%'}} />

                <Grid container rowGap={0.5} padding={1}>
                    {
                        order?.order_items?.map(e => {
                            return <OrderItemRow orderItem={e} />
                        })
                    }
                </Grid>

                <Box sx={{borderTop: '1px dotted gray', width:'100%'}} paddingX={1} />

                <Grid container justifyContent={'space-between'} alignItems={'center'} padding={1}>
                    <Grid container item justifyContent={'flex-start'} alignItems={'center'} columnGap={1}  sx={{width:'75%'}}>
                        {/* <Typography variant="subtitle1">
                            Order placed on {formatDate(`${order.order_date}`, 'DD MMM, HH:mm A')}
                        </Typography> */}
                        <Typography variant="body2">
                            {order.feedback ? 'You rated' : 'Rate'}
                        </Typography>
                        <Rating
                            name="order-item-rating"
                            value={order.feedback}
                            onChange={(event, newValue) => {
                                console.log(newValue);
                            }}
                        />
                    </Grid>
                    <Grid container item flexDirection={'row'} justifyContent={'flex-end'} alignItems={'center'}  sx={{width:'25%'}}>
                        <Typography variant="h6" >
                            {`₹ ${order.order_amount}`}
                        </Typography>
                        <RightChevronIcon />
                    </Grid>
                </Grid>
            </Grid>
        </Card>
    );
}
