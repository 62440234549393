import { Box, Button, Grid, Snackbar, Typography } from "@mui/material";
import { useEffect, useState } from "react";

import { BottomSheet } from "react-spring-bottom-sheet";
import 'react-spring-bottom-sheet/dist/style.css';

import { useCookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";
import Iconify from "../../components/Iconify";

import { SNACKBAR_SEVERITY } from "../../constants/app_constants";
import { setSnackbarMessage, setSnackbarVisibilityStatus } from "../../store/layout/reducer";
import SwipeableCard from "../../components/cards/swipeable_card";
import { getUserCorporateNps } from "../../store/user/reducer";

import '../../assets/css/Feedback.css';

export default function FeedbackCard({item}) {
    
    const dispatch = useDispatch();

    return (
        <Grid container flexDirection={'column'}>
            {
                    item.img == null &&
                    <img src='/static/meal.png' alt={item.name} width={300} style={{borderRadius:10}}/> 
            }
            {
                    item.img != null &&
                    <img src={item.img[0]} alt={item.name}  width={300} style={{borderRadius:10}}/> 
            } 
            <Box margin={2}/> 
            <Grid container  paddingLeft={2} flexDirection={'column'}>
                <Typography variant="h4"> {item.name} </Typography>

                <Typography variant="body1"> Order placed on : {item.order_date} </Typography>
            </Grid>
        </Grid>
    );
}