import { useState } from "react";
import { Grid, IconButton, InputAdornment, OutlinedInput, TextField, Typography } from "@mui/material";
import { ArrowLeftOutlined, ChevronLeftOutlined, CloseOutlined, SearchOutlined } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";

import Iconify from "../../components/Iconify";
import { getSelectedCorporate } from "../../store/otp/reducer";
import { filterAdded, getSelectedFilterKeyword } from "../../store/menu/reducer";
import SelectCorporateModal from "../../components/modal/SelectCorporateModal/SelectCorporateModal";
import { getUser } from "../../store/user/reducer";

export default function MenuHeaderMobile() {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [isSearchVisible, setIsSearchVisible] = useState(false);
    const selectedCorporate = useSelector(state => getSelectedCorporate(state));
    const searchTerm = useSelector(state => getSelectedFilterKeyword(state));

    const [isSelectCorporateModalVisible, setIsSelectCorporateModalVisible] = useState(false);

    const userDetails = useSelector(state => getUser(state));

    const handleSelectCorporateClick = () => {
        if (userDetails?.corporate_clients?.length > 1){
            setIsSelectCorporateModalVisible(true);
        }
    }

    const handleSelectCorporateModalClose = (e) => {
        setIsSelectCorporateModalVisible(false);
    }

    return (
        <>
            <Grid
                className="navbar-mobile"
                justifyContent={'flex-start'}
                alignItems={'center'}
                sx={{
                    // boxShadow: '0px -7px 20px 3px #0000006b',
                    backgroundColor: '#e86826',
                    paddingTop: '10px',
                    paddingBottom: '10px',
                }}
            >
                {
                    !isSearchVisible && <Grid container justifyContent={'flex-start'} alignItems={'center'}>
                    <Grid container item flexDirection={'row'} justifyContent={'flex-start'} alignItems={'center'}
                    xs={8} sm={8}>
                        
                        <Grid container paddingLeft={1} justifyContent={'center'} alignItems={'flex-start'}>
                        <Grid container className="menu-location-picker" onClick={handleSelectCorporateClick} alignItems={"center"} item marginBottom={1} overflow={'none'} flexWrap={'nowrap'}>
                            <Iconify icon="eva:pin-fill" fontSize={24} sx={{color:'#fff'}} mr={1} />
                            <Typography variant={'h5'} sx={{color: '#fff', textOverflow: 'ellipsis', maxWidth:'140px', overflowX:'hidden'}}>
                            {
                                selectedCorporate
                                ? selectedCorporate?.name
                                : "Select location"
                            }
                            </Typography>
                            <Iconify icon="eva:arrow-ios-downward-outline" fontSize={24} ml={1} sx={{color:'#fff'}} />
                        </Grid> 
                        </Grid>

                    </Grid>
                
                    <Grid container direction={'row'} sm={4} xs={4} justifyContent={'flex-end'} alignItems={'center'}>

                        <Grid sm={4} xs={4} justifyContent={'center'} alignItems={'center'} padding={1} container item>

                            <Grid className={'menu-icon menu-icon-profile'} sx={{}} onClick={() => {setIsSearchVisible(true);}}>
                                <SearchOutlined sx={{color:'#fff', fontSize:'24px'}} />
                            </Grid>
                        </Grid>

                        <Grid sm={4} xs={4} justifyContent={'center'} alignItems={'center'} padding={1} container item
                            onClick={() => navigate('/my-account')}>

                            <Grid className={'menu-icon menu-icon-profile'} sx={{}}>
                                <Iconify icon={'eva:people-outline'} sx={{color:'#fff', fontSize:'24px'}} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            }
            {
                isSearchVisible && <Grid container className={'search-menu-container'} flexWrap={'nowrap'} overflow={'scroll'} spacing={0} sm={12} xs={12} lg={12} md={12} xl={12}> 
                <form className="search-menu-form "> 
                    {/* <input type="search" className='input-transparent glass-box' placeholder='Search for dish' defaultValue={searchTerm} onChange={(e) => {
                        dispatch(filterAdded({type: 'keyword', value: e.target.value}));
                    }} style={{background:'#fff!important'}} /> */}

                    <OutlinedInput 
                        type="search"
                        placeholder="Search for dish"
                        className={' glass-box'}
                        onChange={(e) => {
                            dispatch(filterAdded({type: 'keyword', value: e.target.value}));
                        }} 
                        sx={{padding: '0!important', width: '100%', backgroundColor: '#fff!important'}}
                        startAdornment={
                            <InputAdornment position="start">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => {setIsSearchVisible(false);}}
                                // onMouseDown={handleMouseDownPassword}
                                // onMouseUp={handleMouseUpPassword}
                                edge="end"
                            >
                                {/* {showPassword ? <VisibilityOff /> : <Visibility />} */}
                                <ChevronLeftOutlined sx={{color:'#000'}} />
                            </IconButton>
                            </InputAdornment>
                        }
                    />
                </form>
            </Grid>
            }   
            
            </Grid>
            {
                isSelectCorporateModalVisible && isMobile && <SelectCorporateModal open={isSelectCorporateModalVisible} onClose={handleSelectCorporateModalClose} />
            }
        </>

    );
}