export const ENV='production'; // dev or production

// export const API_BASE_URL="https://staging.the-bytes.com/";
export const API_BASE_URL="https://api.the-bytes.com/";
// export const API_BASE_URL="http://35.154.122.166/api/";
// export const API_BASE_URL="https://kitchen.greckle.io/";
// export const API_BASE_URL="https://staging.greckle.io/";
// export const API_BASE_URL="http://127.0.0.1:5000/";

// export const SSE_URL = "https://staging.the-bytes.com/stream"
export const SSE_URL = "https://api.the-bytes.com/stream"

// authentication
export const API_SEND_OTP="v1/otp";
export const API_VERIFY_OTP="v1/otp";
export const API_GENERATE_ACCESS_TOKEN="v1/oauth/token";
export const API_CREATE_SESSION = "v1/sessions/add";
export const API_GET_SESSION="v1/sessions"; 

// User
export const API_GET_USER_DETAILS="v1/users/";
export const API_UPDATE_USER_DETAILS="v1/users/";

// Corporates
export const API_GET_CORPORATE_LIST="v1/corporates"; 

// Corporate Menu
export const API_FETCH_MENU="v1/menu/new";

// Corporate Cart
export const API_GET_CART_CORPORATE = "v1/corporate_cart";
export const API_UPDATE_CART_CORPORATE = "v1/corporate_cart";

// Corporate Order
export const API_GENERATE_ORDER_ID_CORPORATE = "v1/orders/fetch_corporate_order_id";
export const API_ADD_TRANSACTION_CORPORATE = "v1/transactions/add_transaction";
export const API_CONFIRM_ORDER_CORPORATE="v1/orders/confirm_corporate_order";


export const API_GET_USER_CORPORATE_ORDERS="v1/orders/";
export const API_GET_CORPORATE_ORDER_DETAILS="v1/corporate/orders/";


export const API_GET_USER_MEAL_HISTORY="v1/meals/";
export const API_GET_USER_CORPORATE_MEAL ="v1/orders/corporate_meals";

// Cart
export const API_GET_CART = "v1/cart";
export const API_UPDATE_CART = "v1/cart";

// Order
export const API_GENERATE_ORDER_ID = "v1/generate_order_id";
export const API_ADD_TRANSACTION = "v1/add_transaction";

// Wallet
export const API_GET_CORPORATE_WALLET_DETAILS = "v1/corporate/wallet";
export const API_GET_CORPORATE_WALLET_TRANSACTIONS = "v1/corporate/wallet/transactions";

// Query
export const API_SAVE_QUERY = "v1/query";

// Terms and Conditions
export const API_TERMS_AND_CONDITIONS = "v1/help/terms-and-conditions";

// export const API_GET_CLIENT_LIST = "v1/partner/client/list";
// export const API_FETCH_USER_DETAILS = "v1/partner/client/get_details";
// export const API_UPDATE_CLIENT_DETAILS = "v1/partner/client/update_details";
// export const API_GET_CLIENT_SUBSCRIPTION = "v1/partner/client/get_subscription";

// // Client Subscription
// export const API_CLIENT_SUBSCRIBE_PLAN = "v1/partner/client/subscribe";
// export const API_SUBSCRIBE_CLIENT = "v1/partner/client/subscribe";
// export const API_CONFIRM_CLIENT_SUBSCRIPTION = "v1/partner/client/confirm_subscription";


// // export const API_GET_SUBSCRIPTION_PLAN_LIST = "v1/partner/subscription_plan/get_list";
// export const API_GET_SUBSCRIPTION_PLAN_LIST = "v1/partner/client/get_subscription_plans";

// // Meal Schedule
// export const API_GET_CLIENT_MEAL_OPTIONS="v1/partner/client/meal_plan_dish_options";
// export const API_GET_CLIENT_MEAL_SCHEDULE="v1/partner/client/meal_plan_schedule";
// export const API_POST_CLIENT_MEAL_SCHEDULE="v1/partner/client/meal_plan_schedule";


// // Corporate Users
// export const API_GET_CORPORATE_USERS ="v1/partner/corporate/users";
// export const API_GET_CORPORATE_INVOICE ="v1/partner/corporate/invoice";
// export const API_GET_CORPORATE_TRANSACTION ="v1/partner/corporate/transaction";
// export const API_GET_CORPORATE_REPORT ="v1/partner/corporate/report";
// export const API_GET_CORPORATE_FEEDBACK ="v1/partner/corporate/feedback";

// Corporate User Feedback

export const API_STORE_FEEDBACK_MEAL = "v1/feedback/nps";
export const API_ADD_BULK_NPS_PROMPT = "v1/feedback/bulk_nps_prompted";

export const API_GET_CORPORATE_FEEDBACK_LIST ="v1/partner/corporate/feedback/schedule";
export const API_UPDATE_CORPORATE_FEEDBACK ="v1/partner/corporate/anonymous/feedback";
// export const API_GET_CORPORATE_FEEDBACK ="v1/partner/corporate/feedback";
// export const API_GET_CORPORATE_FEEDBACK ="v1/partner/corporate/feedback";
// export const API_GET_CORPORATE_FEEDBACK ="v1/partner/corporate/feedback";

