import { Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

import Iconify from "../Iconify";
import { HOME } from "../../constants/screen_constants";

export default function MealPassHeaderMobile() {

    const navigate = useNavigate();

    return (
        <Grid container className="header" flexDirection={'row'} justifyContent={'flex-start'} sx={{height:'8vh'}}>
            <Grid container item sm={3} md={3} xs={3} lg={3} xl={3}
                padding={1.5} 
                flexDirection={'row'} 
                alignItems={'center'} 
                justifyContent={'flex-start'} 
                onClick={() => {
                    navigate(HOME);
                }}>
                    <Iconify icon={'eva:chevron-left-outline'} sx={{width: 32, height: 32, color: '#fff'}} />
            </Grid>

            <Grid container item justifyContent={'center'} alignItems={'center'}  sm={6} md={6} xs={6} lg={6} xl={6}>
                <Typography variant="h4" sx={{color:'#fff'}}>
                    {'Meal Pass'}
                </Typography>
            </Grid>
        </Grid>
    );
}