import { createSlice } from '@reduxjs/toolkit';
import { formatDate } from '../../utils/date';
import {SNACKBAR_SEVERITY } from '../../constants/app_constants';

const navigationSlice = createSlice({
  name: 'navigation',
  initialState: {
    afterLoginUrl: null,
  },
  reducers: {
    setAfterLoginUrl(state, action) {
        state.afterLoginUrl = action.payload.url;
    },
    resetNavigationState(state, action){
      state.afterLoginUrl = null;
    }, 
  }
})

export const getAfterLoginUrl = (state) => {

    try{
        return state.navigation.afterLoginUrl;
    }catch(e){
        return false;
    } 
}
 
export const { setAfterLoginUrl, resetNavigationState } = navigationSlice.actions

export default navigationSlice.reducer