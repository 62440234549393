import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useCookies } from "react-cookie";
import { BrowserView, MobileView } from "react-device-detect";
import { Card, Divider, Grid, Typography } from "@mui/material";


import { fetchCorporateWalletTransactions } from "../../data/network/corporate_wallet/api";
import { getCorporateWalletDetails, getCorporateWalletTransactions } from "../../store/corporate_wallet/reducer";
import { getSelectedCorporate } from "../../store/otp/reducer";
import { formatDate } from "../../utils/date";


export default function CorporateWallet() {
    

    const dispatch = useDispatch();
    const [cookies, setCookie] = useCookies(['mobile','session', 'access_token']);

    const walletDetails = useSelector(state => getCorporateWalletDetails(state));
    const walletTransactions = useSelector(state => getCorporateWalletTransactions(state));
    const selectedCorporate = useSelector(state => getSelectedCorporate(state));

    useEffect(() => {
        fetchCorporateWalletTransactions(dispatch, cookies, {
            'corporateId' : selectedCorporate?.id,
            'page': 1,
            'count': 20,
        });
    }, []);

    return (
        <>
            <BrowserView>
{/*  */}
            </BrowserView>

            <MobileView>
                
                <Card className="glass-box" sx={{width:'100%', padding:1, marginTop: 1, marginBottom: 4}} >  
                    <Grid container flexDirection={'row'} padding={1} justifyContent={'space-between'} alignItems={'center'}>
                        <Typography variant="body1">Available Credits</Typography>
                        <Typography variant="h4">₹ {walletDetails?.amount ?? 0}</Typography>
                    </Grid>
                </Card>

                <Divider spacing={1} sx={{ borderStyle: 'dashed' }}/>

                <Grid container padding={1}>
                    <Typography variant="h5">Recent Transactions</Typography>
                </Grid>

                <Grid container flexDirection={'column'} marginY={1} justifyContent={'flex-start'} alignItems={'center'}>
                    {
                        walletTransactions && walletTransactions.length > 0
                        ? <Grid container>
                            {
                                walletTransactions.map((e, i) => {
                                    return <Card className="glass-box" sx={{width:'100%', padding:1, marginY:1}}>
                                        <Grid container flexDirection={'column'} justifyContent={'flex-start'} alignItems={'flex-start'}>

                                            <Grid container flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} paddingX={2}>
                                                
                                                <Typography variant="body1">{formatDate(e.transaction_datetime, "DD MMM YY HH:mm A")}</Typography>
                                                
                                                {
                                                    e.transaction_type === 'TransactionTypes.DEBIT'
                                                    ? <Typography variant="h5" sx={{color:'red'}}>
                                                        - ₹ {e.amount}
                                                    </Typography>
                                                    : <Typography variant="h5" sx={{color: 'green'}}>
                                                        ₹ {e.amount}
                                                    </Typography>
                                                }
                                            </Grid>

                                            <Grid container flexDirection={'row'} justifyContent={'flex-start'} alignItems={'center'} paddingX={2}>
                                                <Typography variant="body1">
                                                    {e.remarks}
                                                </Typography>
                                            </Grid>
                                                                                        
                                        </Grid>
                                    </Card>
                                })
                            }
                        </Grid>
                        : <Grid container justifyContent={'center'} alignItems={'center'}>
                            <Typography variant="body1">No Transaction found</Typography>
                        </Grid>
                    }
                </Grid>

            </MobileView>
        </>
    );
}