import { Sheet } from 'react-modal-sheet';
import { styled } from 'styled-components';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Typography, Grid, Box, Button, Card } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/CloseOutlined';
import { getUser } from '../../../store/user/reducer';
import { getSelectedCorporate, setSelectedCorporate } from '../../../store/otp/reducer';

import './SelectCorporateModal.css';

// import TicketListingForm from '../../../pages/AddEventTicket/components/TicketListingForm';

const CustomSheet = styled(Sheet)`
  .react-modal-sheet-backdrop {
    /* custom styles */
    display: { base: 'flex', sm: 'none !important' },
    backdropFilter: 'blur(4px)',
    background: 'rgba(0,0,0,0.4) !important',
  }
  .react-modal-sheet-container {
    /* custom styles */
    display: { base: 'flex', sm: 'none !important' },
    background: 'white !important',
  }
  .react-modal-sheet-header {
    /* custom styles */
  }
  .react-modal-sheet-drag-indicator {
    /* custom styles */
  }
  .react-modal-sheet-content {
    /* custom styles */
    px: 4,
    pb: 12,
  }
`;

export default function SelectCorporateModal({open, onClose}) {

  const ref = useRef();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [corporateClients, setCorporateClients] = useState([]);

  const userDetails = useSelector(state => getUser(state));
  const selectedCorporate = useSelector(state => getSelectedCorporate(state));

  const springConfig = {stiffness: 150, damping: 20, mass: 0.7 };

  const handleCorporateSelect = (corporate) => {
    console.log(corporate);
    dispatch(setSelectedCorporate({'corporate': corporate}));
    onClose();
  }

  useEffect(() => {
    setCorporateClients(userDetails?.corporate_clients ?? []);
  },[userDetails]);

  return (
    <>
      <CustomSheet isOpen={open} 
        snapPoints={[700]} 
        springConfig={springConfig}
        ref={ref}
        onClose={onClose}
        style={{zIndex: 10}}
      >
        <Sheet.Container style={{padding:10}}>
            <Sheet.Header style={{borderBottom: '1px solid gray'}}>
                <Grid container padding={2} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
                    <Typography variant="h4">Select Corporate</Typography>
                    <Box sx={{borderRadius:10, background:'#e0e0e0', padding:1}}>
                        <CloseIcon onClick={onClose} />
                    </Box>
                </Grid>
            </Sheet.Header>
            <Sheet.Content disableDrag style={{ paddingBottom: ref.current?.y }}>
                {
                    corporateClients?.map(e => {
                        const isSelected = (selectedCorporate?.id === e.id);
                        return <Card className={isSelected ? 'corporate-selected glass-box' : 'glass-box'} sx={{marginTop: '10px', borderRadius:'4px', paddingX:'4px', paddingY:'10px', minHeight:'50px'}} onClick={(event) => handleCorporateSelect(e)}>
                            <Grid container justifyContent={'space-between'} alignItems={'center'}>
                                <Typography variant="h4">{e.display_name}</Typography>

                                {
                                    isSelected && <CheckCircleIcon sx={{color: 'green'}} />
                                }
                            </Grid>
                        </Card>;
                    })
                }
            </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop onTap={onClose} style={{backdropFilter: 'blur(10px)'}}/>
      </CustomSheet>
    </>
  );
}